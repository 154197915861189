import React from "react";
// Customizable Area Start
import {
    Typography,
    Box,
    styled,
    Link,
    Divider,
    Tab,
    OutlinedInput,
    Button,
    InputAdornment,
    IconButton,
    Dialog,
    DialogContent,
} from "@material-ui/core";
import FooterWeb from "./Components/Footer.web";
import Tabs from "@material-ui/core/Tabs";
import FreelancerNavBar from "./FreelancerNavBar.web";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

const CustomTabs = styled(Tabs)({
    '& .MuiTab-textColorPrimary-25.Mui-selected': {
        fontWeight: "600",
        fontSize: "22px",
        textTransform: "inherit"
    },
    '& .MuiTab-textColorPrimary-25': {
        fontWeight: "600",
        fontSize: "22px",
        textTransform: "inherit"
    },
    '& .PrivateTabIndicator-root-31': {
        height: "4px"
    }

})
import FreelancerActiveContractController, {
    Props,
    webConfigJSON,
} from "./FreelancerActiveContractController.web";
import { image_Success } from "../../email-account-registration/src/assets";

// Customizable Area End

export default class FreelancerActiveContract extends FreelancerActiveContractController {
    constructor(props:Props){
        super(props) ;
        // Customizable Area Start

        // Customizable Area End
    }
    // Customizable Area Start

    // Customizable Area End
    renderSelect=()=>{
        return(
            <Box  style={{ display: "flex" }} >
                <CurUpperText>
                    {webConfigJSON.myJobs}
                </CurUpperText>
                <ArrowIcon>
                    {">"}
                </ArrowIcon>
                <CurUpperText>
                    {webConfigJSON.allcontract}
                </CurUpperText>
                <ArrowIcon>
                    {">"}
                </ArrowIcon>
                <FontActiveText>
                    {webConfigJSON.ArchivedContract}
                </FontActiveText>
            </Box>
        )};

        renderSuccessModal = () =>{
            return(
              <>
                 <CustomDialogBox
              open={this.state.isOpenModal}
              onClose={this.handleCloseModal}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
          >
               
              <DialogContent style={{
                 textAlign: 'center',padding:"24px" }}>
                  {this.state.milestoneupdate && 
                  <>
                  <img 
                  src={image_Success}
                   alt="Success" 
                   style={{ width: "35%",
                   borderRadius: "50%",
                   marginTop:"20px" ,marginBottom:"10px"}} />
                  <Typography 
                  gutterBottom style={webStyle.congratsText}>
                      Congratulations!  
                      </Typography>
                      </>
                      }
                  <Typography style={{...webStyle.profileCreatedText, 
                  textTransform: 'capitalize'
                  }}>
                 {this.state.succesMessage}</Typography>
                  <Box style={{ display: "flex",
                   flexDirection: "column" }}>
                      <Button
                          variant="outlined"
                          style={webStyle.blueButton}
                          onClick={this.handleCloseModal}
                      >
                         OK
                      </Button>
                  </Box>
              </DialogContent>
          </CustomDialogBox>
              </>
            )
          }

    renderContract=()=> {
        const {activeTab} = this.state
        return(
            <>
            <Box>
                <HeaderTxt>
                   Active Contracts
                </HeaderTxt>
                <TitleTxt>Stay focused and informed with a detailed view of your active contract. Monitor progress, manage tasks, and keep all important information at your fingertips for seamless project execution.

                </TitleTxt>
                <CustomTabs value={activeTab} indicatorColor="primary"
                data-test-id='tabs'
                    onChange={this.handleTabChange} textColor="primary">
                    <Tab 
                    label="Project Details" 
                    style={{ textTransform: "none", 
                    fontFamily: "Rubik", 
                    fontSize: "16px", 
                    color: this.state.activeTab === 0 ? '#364BA0' : '#3F3F3F',
                    fontWeight: 500 }} />
                    <Tab 
                    label="Project Tracking" 
                    style={{ textTransform: "none", 
                    fontFamily: "Rubik", 
                    fontSize: "16px",
                    color: this.state.activeTab === 1 ? '#364BA0' : '#3F3F3F',
                    fontWeight: 500 }} />
                </CustomTabs>
                <Divider />
              
            </Box>
                            {activeTab === 0 ? 
                                <>
                                {this.renderProject()}
                                {this.renderButtons()}
                            </>
                             : 
                               <>
                               {this.renderProjectTracking()}
                                   {this.renderButtons()}
                               </>
                           }
                           </>
        );
    };


renderProjectTracking = () => {
    return (
        <ProjectOuterMainBox style={webStyle.boxStyle}>
            <Box>
                <Typography style={{
                   color: '0A0A0A',
                   fontSize: '16px',
                   fontWeight: 400,
                   fontFamily: 'Rubik',
                   padding: '24px 24px 0px 24px'
                }}>
                Efficiently track your project's progress with real-time updates and detailed overviews. Stay on top of deadlines, manage tasks, and ensure every aspect of your project is moving forward smoothly.
                </Typography>
            </Box>
            <Box style={{
            borderBottom: '1px solid #ECECEC',
            height: '1px',
            width: '100%'
         }}> </Box>
                 {this.renderMilestoneTracking()}
        </ProjectOuterMainBox>
    );
}

renderProject = () => {
    return (
        <ProjectOuterMainBox style={webStyle.boxStyle}>
          <Box className="projectDes">
            <Typography style={{
                color: '#303030',
                fontSize: '24px',
                fontFamily: 'Rubik',
                fontWeight: 700,
                marginBottom: '41px',
                textTransform: 'capitalize'
            }}>{this.state.activeDetailsData?.attributes?.post_attributes?.data?.attributes?.name}</Typography>
            <Box >
                <Box className="projectContent">
            <Typography style={webStyle.clientLeftAtrribute} >IP Client:</Typography>
            <Typography style={{...webStyle.clientRightAtrribute, textTransform: 'capitalize'}}>{this.state.activeDetailsData?.attributes?.client_details?.client_name}</Typography>
                </Box>
                <Box className="projectContent">
            <Typography style={webStyle.clientLeftAtrribute} >Duration:</Typography>
            <Typography style={{...webStyle.clientRightAtrribute, textTransform: 'capitalize'}}>{this.state.activeDetailsData?.attributes?.post_attributes?.data?.attributes?.project_length}</Typography>
                </Box> <Box className="projectContent">
            <Typography style={webStyle.clientLeftAtrribute} >Charge:</Typography>
            <Typography style={{...webStyle.clientRightAtrribute, textTransform: 'capitalize'}}>${this.state.activeDetailsData?.attributes?.post_attributes?.data?.attributes?.est_budget}.00</Typography>
                </Box> 
                <Box className="projectContent">
            <Typography style={webStyle.clientLeftAtrribute} >Payment:</Typography>
            <Typography style={{...webStyle.clientRightAtrribute, textTransform: 'capitalize'}}>
                {this.state.activeDetailsData?.attributes?.payment_type === 'by_milestone' ? 'By Milestones': 'By Project'}</Typography>
                </Box>
            </Box>
          </Box>
         <Box style={{
            border: '1px solid #ECECEC',
            height: '20px',
            width: '100%'
         }}> </Box>
          <Box style={{paddingLeft: '30px'}}>
            <Typography
            style={{
                color: '#3F3F3F',
                fontSize: '24px',
                fontFamily: 'Rubik',
                fontWeight: 700
            }}
            >Project Details</Typography>
            <Typography style={{
                color: '#303030',
                fontSize: '16px',
                fontFamily: 'Rubik',
            }}>{this.state.activeDetailsData?.attributes?.post_attributes?.data?.attributes?.job_description}</Typography>
          </Box>
          <Box style={{
            borderBottom: '1px solid #ECECEC',
            height: '1px',
            width: '100%'
         }}> </Box>
          {this.renderMilestone()}
        </ProjectOuterMainBox>
    );
}

renderButtons = () => {
    const paramId = this.props.navigation.getParam("id")
    return(
        <CustomButton >
        <Box style={{marginTop: '48px'}}>
                   <Button
                      data-testid="dailog-cancel-btn"
                      onClick={() => this.props.navigation.navigate('FreelancerRaiseDisputes', {id: paramId })}
                    className="raisedisputeBtn">
                      Raise Dispute
                    </Button>
                    <Button
                      variant="contained"
                      className="alldisputeBtn"
                      color="primary"
                      data-testid="dailog-withdraw-proposal-btn"
                      onClick={() => this.props.navigation.navigate('FreelancerAllDisputes', {id: paramId })}
                    >
                      All Disputes
                    </Button>
                    </Box>
                    <Box style={{marginTop: '48px'}}>
                    <Button
                      variant="contained"
                      className="endisputeBtn"
                      data-testid="dailog-withdraw-proposal-btn"
                    //   onClick={this.handleWithdrawProposals}
                    >
                      End Contract
                    </Button>
                    </Box>
        </CustomButton>
    )
}

renderMilestoneTracking = () => {
    const getStatusColor = (status: string) => {
         switch (status) {
           case "Approved":
             return "green";
           case "ongoing":
             return "#364BA0";
           case "pending":
             return "red";
           default:
             return "black";
         }
       };
       console.log(this.state.milestoneDetailsData,"milestoneDetailsData")
     return (
         <MilestoneBox style={{padding: '0px 30px 30px 30px', width: '100%'}}>
             <Typography style={{
                    color: '#3F3F3F',
                    fontSize: '24px',
                    fontFamily: 'Rubik',
                    fontWeight: 700,
                    marginBottom: '62px'
             }}
             >Milestone Tracking</Typography>

             <Box>
             <Box style={{ margin: "20px", width: "100%" }}>
       <Box>
         <Box >
         {this.state.milestoneDetailsData?.map((milestone: any, index: number) => {
          const { id, description, date, status, upload_works } = milestone?.attributes;
          return (
            <Box className="milestoneBox" key={id}>
              {/* Milestone Description */}
              <Box className="inputBoxDes">
                <Typography style={webStyle.tableHeading}>Milestone Description</Typography>
                <Box style={{ display: 'flex' }}>
                  <Typography
                    style={{
                      ...webStyle.tableHeading,
                      marginTop: '17px',
                      color: '#515354',
                      marginRight: '10px',
                    }}
                  >
                    {index + 1}
                  </Typography>
                  <OutlinedInput
                    fullWidth
                    value={description}
                    onChange={(e) =>
                      this.handleInputChange(index, 'description', e.target.value)
                    }
                    inputProps={{
                      style: {
                        fontWeight: 500,
                        color: '#515354',
                        fontSize: '16px',
                        fontFamily: 'Rubik',
                      },
                    }}
                  />
                </Box>
              </Box>

              {/* Submission Date */}
              <Box className="inputBoxField">
                <Typography style={webStyle.tableHeading}>Submission Date</Typography>
                <OutlinedInput
                  fullWidth
                  value={date}
                  onChange={(e) =>
                    this.handleInputChange(index, 'date', e.target.value)
                  }
                  inputProps={{
                    style: {
                      fontWeight: 500,
                      color: '#515354',
                      fontSize: '16px',
                      fontFamily: 'Rubik',
                    },
                  }}
                />
              </Box>

              <Box className="inputBoxDes">
            <Typography style={webStyle.tableHeading}>Upload Work</Typography>
            <Box style={{ display: 'flex' }}>
                <OutlinedInput
                fullWidth
                value={upload_works[0]?.name || ''}
                inputProps={{
                    style: {
                    fontWeight: 500,
                    color: '#515354',
                    fontSize: '16px',
                    fontFamily: 'Rubik',
                    },
                }}
                endAdornment={
                    <InputAdornment position="end">
                    <IconButton component="label">
                        <CloudUploadIcon />
                        <input
                        type="file"
                        accept=".pdf,.doc,.docx,.png,.jpg,.jpeg"
                        hidden
                        onChange={(e) => this.handleFileUpload(e,id)}
                        />
                    </IconButton>
                    </InputAdornment>
                }
                />
            </Box>
            </Box>


              {/* Status */}
              <Box className="inputBoxField">
                <Typography style={webStyle.tableHeading}>Status</Typography>
                <OutlinedInput
                  fullWidth
                  value={status}
                  disabled
                  onChange={(e) =>
                    this.handleInputChange(index, 'status', e.target.value)
                  }
                  inputProps={{
                    style: {
                      fontWeight: 500,
                      color: getStatusColor(status),
                      fontSize: '16px',
                      fontFamily: 'Rubik',
                    },
                  }}
                />
              </Box>

              {/* Action */}
              <Box className="inputBoxField">
                <Typography style={webStyle.tableHeading}>Action</Typography>
                <CustomButton>
                  <Button
                    data-testid="dailog-cancel-btn"
                    onClick={() => this.handleSubmitMilestone(id, milestone?.attributes)}
                    className="raisedisputeBtn"
                  >
                    Submit
                  </Button>
                </CustomButton>
              </Box>
            </Box>
          );
        })}

         </Box>
       </Box>
     </Box>
             </Box>
           
         </MilestoneBox>
     );
 };

renderMilestone = () => {
   const getStatusColor = (status: string) => {
        switch (status) {
          case "Approved":
            return "green";
          case "ongoing":
            return "#364BA0";
          case "pending":
            return "red";
          default:
            return "black";
        }
      };
    return (
        <MilestoneBox style={{padding: '30px'}}>
            <Typography style={{
                   color: '#3F3F3F',
                   fontSize: '24px',
                   fontFamily: 'Rubik',
                   fontWeight: 700,
                   marginBottom: '62px'
            }}
            >Milestone details</Typography>
            <Box>
            <Box style={{ margin: "20px", width: "100%" }}>
      <Box>
        <Box >
          {this.state.activeDetailsData?.attributes?.milestone_details?.map((milestone: any, index: number) => {
           return(
           <Box className={'milestoneBox'} key={milestone.id}>
                <Box className="inputBoxDes">
                  <Typography style={webStyle.tableHeading}>Milestone Description</Typography>
                  <Box style={{display: 'flex'}}>
                  <Typography style={{...webStyle.tableHeading, marginTop: '17px', color:'#515354',
                    marginRight: '10px'
                  }}>{index+1}</Typography>
                 <OutlinedInput fullWidth
                  data-testid="project-btn"
                  value={milestone.milestone_description}
                  /* onChange={this.handleProjectBudget} */
                  name="total_price_of_project"
                  inputProps = {{
                    style :{
                      fontWeight: 500,
                      color: '#515354',
                      fontSize: '16px',
                      fontFamily: 'Rubik',
                    }}}
                  />
                    </Box>
                  </Box>
                  <Box className="inputBoxField">
                 <Typography style={webStyle.tableHeading}>Budget</Typography>
                  <OutlinedInput fullWidth
                  data-testid="project-btn"
                  value={milestone.milestone_amount}
                  /* onChange={this.handleProjectBudget} */
                  name="budget"
                  inputProps = {{
                    style :{
                      fontWeight: 500,
                      color: '#515354',
                      fontSize: '16px',
                      fontFamily: 'Rubik',
                    }}}
                  />
                  </Box>
                  <Box className="inputBoxField">
                   <Typography style={webStyle.tableHeading}>Date</Typography>
                    <OutlinedInput fullWidth
                  data-testid="project-btn"
                  value={milestone.milestone_date}
                  inputProps = {{
                    style :{
                      fontWeight: 500,
                      color: '#515354',
                      fontSize: '16px',
                      fontFamily: 'Rubik',
                    }}}
                  />
                  </Box>
                  <Box className="inputBoxField">
                   <Typography style={webStyle.tableHeading}>Status</Typography>
                    <OutlinedInput fullWidth
                  data-testid="project-btn"
                  value={milestone.milestone_status}
                  /* onChange={this.handleProjectBudget} */
                  name="status"
                  inputProps = {{
                    style :{
                      fontWeight: 500,
                      color: getStatusColor(milestone.milestone_status),
                      fontSize: '16px',              
                      fontFamily: 'Rubik',
                    }}}
                  />
                  </Box>
                  <Box className="inputBoxField">
                   <Typography style={webStyle.tableHeading}>Active Disputes</Typography>
                   <OutlinedInput fullWidth
                  data-testid="project-btn"
                  value={this.state.activeDetailsData?.attributes?.active_disputes || 0}
                  /* onChange={this.handleProjectBudget} */
                  name="status"
                  inputProps = {{
                    style :{
                      fontWeight: 400,
                      color: '#515354',
                      fontSize: '16px',
                      fontFamily: 'Rubik',
                    }}}
                  />
                  </Box>
                  <Box className="inputBoxField">
                    <Typography style={webStyle.tableHeading}>Total Disputes</Typography>
                     <OutlinedInput fullWidth
                  data-testid="project-btn"
                  value={this.state.activeDetailsData?.attributes?.total_disputes || 0}
                  /* onChange={this.handleProjectBudget} */
                  name="status"
                  inputProps = {{
                    style :{
                      fontWeight: 400,
                      color: '#515354',
                      fontSize: '16px',
                      fontFamily: 'Rubik',
                    }}}
                  />
                  </Box>
            </Box>)
})}
        </Box>
      </Box>
    </Box>
            </Box>
            <Divider />
          
        </MilestoneBox>
    );
};


render() {
    //Customizable Area Start
  
    return (
        <>
            <FreelancerNavBar navigation={this.props.navigation} />
            {this.renderSuccessModal()}
            <BoxMain>
                {this.renderContract()}
            </BoxMain>
            <FooterWeb />
        </>
    );
    //Customizable Area End
}

}

// Customizable Area Start
const webStyle = {
    clientLeftAtrribute:{
        color: '#3F3F3F',
        fontSize: '18px',
        fontWeight: 700,
        width: '30%',
        fontFamily:'Rubik',
        marginBottom: '6px'
    },
    congratsText: {
      marginTop:"10px",
      fontWeight: 600,
      fontFamily: "Rubik",
      fontSize: "22px",
      color: "#059669",
    },
    profileCreatedText:{
        marginBottom: '20px',
        marginTop: "18px",
        fontFamily: "Rubik",
     fontSize: "14px",
     fontWeight: 500,
     },
     blueButton:{
        backgroundColor: '#364BA0',
        height:"40px",
        borderRadius:"10px",
        borderColor: '#364BA0',
        padding:"22px",
        color: '#fff',
        fontSize:"18px",
        fontFamily:"Rubik",
        marginBottom: '10px',
        fontWeight:500,
        width: '50%',
        margin: 'auto'
      },
    clientRightAtrribute:{
        color: '#545454',
        fontSize: '18px',
        fontFamily:'Rubik',
        width: '70%',
        marginBottom: '6px'
    },
    arrowIcone: {
        color: 'white'
    },
fixedfiled: {
    margin: "22px 0px"
},
tableHeading:{
    fontSize: '16px',
    fontFamily: 'Rubik',
    color:'#000000',
    fontWeight: 600,
    marginBottom: '16px'
},
inputBox: {
    width: '60%'
},
calenderStyle: {
    width: "14px",
    height: "15px",
    marginRight: "5px"
},
boxStyle: {
    borderRadius: '16px',
    marginTop: '32px',
    border: '1px solid #CDCDCD',
}  

};

const BoxMain = styled(Box)({
    margin: "60px",
});

const ArrowIcon = styled(Typography)({
    fontWeight: "bold",
    margin: "0px 8px"
});

const FontActiveText = styled(Typography)({
    cursor: "pointer",
    fontFamily: "Inter",
    fontSize: "17px",
    textAlign: "left",
    fontWeight: 500,
    lineHeight: "24px",
    letterSpacing: "0px",
    color: "#206FC4",
});

const CurUpperText = styled(Typography)({
    fontFamily: "Inter",
    fontSize: "17px",
    fontWeight: 500,
    lineHeight: "24px",
    letterSpacing: "0px",
    textAlign: "left",
    cursor: "pointer"
});

const CustomButton = styled(Box)({
   display: 'flex', 
   justifyContent: 'space-between',
   "@media(max-width:600px)": {
    display: 'block', 
},
'& .endisputeBtn':{
    backgroundColor: '#D60000',
    color: 'white',
    fontSize: '18px',
    fontFamily: 'Rubik',
    padding: '10px 45px',
    borderRadius: '12px',
    border: '1px solid #D60000',
    "@media(max-width:600px)": {
        margin: 'auto' ,
    display: 'flex',

    },
},
'& .raisedisputeBtn' : {
    backgroundColor: '#364BA0',
    marginRight: '20px',
    color: 'white',
    fontSize: '18px',
    fontFamily: 'Rubik',
    padding: '10px 45px',
    borderRadius: '12px',
    border: '1px solid #364BA0',
    "@media(max-width:600px)": {
        margin: 'auto',
        display: 'flex',
        marginBottom: '40px'
    },
},
"& .alldisputeBtn":{
    backgroundColor: 'white',
    color: '#364BA0',
    fontSize: '18px',
    fontFamily: 'Rubik',
    padding: '10px 45px',
    borderRadius: '12px',
    border: '1px solid #364BA0',
    "@media(max-width:600px)": {
        margin: 'auto' ,
        display: 'flex'
    },
}
});

const HeaderTxt = styled(Typography)({
    color: "3F3F3F",
    fontWeight: "bold",
    fontSize: "36px",
    fontFamily: "Rubik",
});

const ProjectOuterMainBox = styled(Box)({
    flexWrap: "wrap",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "30px",
    '& .projectDes':{
        width: '50%', 
        paddingLeft: '30px',
        paddingTop: '30px',
        "@media(max-width:600px)": {
         width: '100%'
        }, 
    '& .projectContent' :{
        display:'flex', 
        gap:'100px',
        "@media(max-width:600px)": {
            display:'block', 
           },
    } 
    }
});

const TitleTxt = styled(Typography)({
    margin: "20px 0px",
    display: "flex",
    fontSize: "14px",
    fontFamily: "Rubik, sans-serif",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "16px",
    width: "91%",
    color: "#595959",
    flexWrap: 'wrap'
});

const MilestoneBox = styled(Box)({
    '& .milestoneBox': {
        display: 'flex',
        gap: '10px',
         "@media(max-width:1080px)": {
            display: 'block',
        },       
    },
    '& .inputBoxDes': {
        width: '80%',
        marginBottom: '20px',
        "@media(max-width:1080px)": {
            width: '100%',
        }, 
    },
    '& .inputBoxField':{
        width: '60%',
        marginBottom: '20px',
        "@media(max-width:1080px)": {
            width: '100%',
        },
    }
  });

  const CustomDialogBox = styled(Dialog)({
    '& .MuiPaper-rounded':{
      width:"480px",
      borderRadius: "18px",
    }
  })

// Customizable Area End