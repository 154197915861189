import React from "react";
//Customizable Area Start
import { Box, Grid, Typography,styled,Button,Accordion, AccordionDetails, AccordionSummary } from "@material-ui/core";
import { monitor } from "../assets";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import Footer from "./Footer.web";
import DashboardController, { Props, webConfigJSON } from "../DashboardController.web";
import ClientNavBar from "./ClientNavBar.web";
const theme = createTheme({
  palette: {
      primary: {
          main: "#206FC4",
      },
      secondary: {
          main: "rgb(96, 96, 96)",
      }
  },
  typography: {
      fontFamily: 'Rubik',
  }
});
const CustomButton = styled(Button)({
  background: "#364BA0",
  border: "1px solid #364BA0",
  fontSize: "16px",
  lineHeight: "19px",
  letterSpacing: "0px",
  color: "white",
  borderRadius: "10px",
  width: " 173px",
  height: " 56px",
  marginRight:"1rem",
  fontFamily:"Rubik",
  fontWeight:"bold",
  cursor:"pointer",
  marginTop: "20px",
  '&:hover': {
    width: " 173px",
    height: " 56px",
    borderRadius: "10px",
    color:"#206FC4",
    border:"1px solid #206FC4",
    backgroundColor: "#fff",
  }
})
//Customizable Area End
export default class PostJob extends DashboardController {
  //Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  //Customizable Area End
  render() {
    //Customizable Area Start
    return (
      <ThemeProvider theme={theme}>
        <ClientNavBar/>
        <Grid container style={styles.gridMainContainer}>
            <Grid item xs={12} sm={7} md={7}>
                <Box>
                    <Typography style={styles.dashboardText}>{webConfigJSON.yourDashboard}</Typography>
                    <Typography style={styles.firstNameStyle}>{this.state.firstName}</Typography>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12}>
                        <div style={styles.leftBox}>
                          <Grid
                            container
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Grid item>
                              <Typography
                                component={"span"}
                                style={styles.leftHead as React.CSSProperties}
                              >
                                Your Posting
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography
                                component={"span"}
                                style={styles.rightHead as React.CSSProperties}
                              >
                                See all posting
                              </Typography>
                            </Grid>
                          </Grid>
                          <div style={styles.imgBox as React.CSSProperties}>
                            <Typography component={"div"} style={styles.imageWr}>
                              <img src={monitor} alt="" style={styles.image} />
                            </Typography>

                            <Typography style={styles.rightHead}>
                              No active job posts
                            </Typography>

                            <Typography
                              style={styles.jobPostDescription as React.CSSProperties}
                            >
                              Lorem Ipsum is simply dummy text of the printing and
                              typesetting industry.
                            </Typography>
                            <CustomButton
                              onClick={this.handleAddBillingMethod}
                            >    
                                Post a Job
                            </CustomButton>
                          </div>
                        </div>
                            <Box style={styles.postingContainer}>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <Box style={styles.workContainer}>
                                            <Typography style={styles.howToWork}>{webConfigJSON.howToWork}</Typography>
                                            <Typography style={styles.details} color="secondary">
                                            Best practices for collaborating with IP Professionals to protect and manage your innovations effectively.</Typography>
                                        </Box>
                                        <Box style={styles.workContainer}>
                                            <Typography style={styles.lists}>{webConfigJSON.postAJob}</Typography>
                                            <Typography style={styles.details} color="secondary">
                                            Provide enough detail for great IP Professionals to figure out if the work is right for them.(You can always edit your
                                            post, or send an invite to reach out to people directly.)
                                            </Typography>
                                        </Box>
                                        <Box style={styles.workContainer}>
                                            <Typography style={styles.lists}>{webConfigJSON.getProposals}</Typography>
                                            <Typography style={styles.details} color="secondary">
                                            A strong working relationship starts with open communications. Here’s your chance to ask about experience
                                            set expectations for what you need, and discuss terms of the work.
                                            </Typography>
                                        </Box>
                                        <Box style={styles.workContainer}>
                                            <Typography style={styles.lists}>{webConfigJSON.startWork}</Typography>
                                            <Typography style={styles.details} color="secondary">
                                            Once you both agree on terms, collaborate with simple and secure tools like chat, file sharing.
                                            </Typography>
                                        </Box>
                                        <Box style={styles.workContainer}>
                                            <Typography style={styles.lists}>{webConfigJSON.payForWork}</Typography>
                                            <Typography style={styles.details} color="secondary">
                                            Active Contracts are useful for keeping track of payments and reviewing work. As you complete jobs, you can build
                                            trusting relationships with IP Professionals in a way that helps you both grow.
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
                <Box>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12} style={styles.btnContainer}>
                            <CustomButton variant="contained" onClick={this.handleAddBillingMethod} data-test-id="handlePostJob">{webConfigJSON.postJobBtn}</CustomButton>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <Box style={styles.accordionContainer}>
                                <Accordion style={styles.accordion}>
                                    <AccordionSummary
                                        expandIcon={<ArrowForwardIosRoundedIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Box>
                                            <Typography style={styles.exploreMoreStyle}>{webConfigJSON.exploreMore}</Typography>
                                            <Typography style={styles.descriptionStyleGrey}>{webConfigJSON.descriptionExploreMore}</Typography>
                                        </Box>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>{webConfigJSON.dummyText}</Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <Box style={styles.accordionContainer}>
                                <Accordion style={styles.accordion}>
                                    <AccordionSummary
                                        expandIcon={<ArrowForwardIosRoundedIcon />}
                                        aria-controls="panel2a-content"
                                        id="panel2a-header"
                                    >
                                        <Box>
                                            <Typography style={styles.exploreMoreStyle}>{webConfigJSON.AddBilling}</Typography>
                                        </Box>

                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>{webConfigJSON.dummyText}</Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        </Grid>
        <Footer />
      </ThemeProvider>
      //Customizable Area End
    );
  }
}

//Customizable Area Start


const styles = {
    gridMainContainer: {
        padding: '52px',
        justifyContent: 'space-between'
    },
    dashboardText: {
        fontFamily:"Rubik",
        fontSize: '32px',
        fontWeight: 400,
    },
    firstNameStyle:{
        fontFamily:"Rubik",
        fontSize: '20px',
        fontWeight: 400,
    },
    postingContainer: {
        background: '#FAFBFC',
        padding: '15px',
        borderRadius: '18px',
        marginTop: '3%'
    },
    accordionContainer: {
        background: '#FAFBFC',
        borderRadius: '5px',
        marginBottom:"2%"
    },
    seePost: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '2%'
    },
    seeDraft: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '1%'
    },
    yourPostText: {
        fontWeight: 400,
        fontSize:"18px"
    },
    seeAllPostText: {
        fontSize: '16px',
        cursor:"pointer"
    },
    createdAtStyle:{
        fontSize: '16px',
    },
    countBox: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    DescriptionStyle: {
        fontSize: '22px',
        width: '65%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap' as 'nowrap',
        marginBottom: '2%'
    },
    howToWork: {
        fontSize: '22px',
        fontFamily:"Rubik"
    },
    workContainer: {
        marginBottom: '2.5%'
    },
    details: {
        fontSize: '14px',
        fontFamily:'Rubik',
        marginTop: '1%'
    },
    lists: {
        fontSize: '22px',
        fontFamily:'Rubik',
    },
    btnContainer: {
        textAlign: 'end' as 'end',
        marginBottom: '20px'
    },
    accordion: {
        background: 'none',
        boxShadow: 'none'
    },
    subText: {
        fontSize: '0.8rem',
        marginTop: '2%'
    },
    iconButton: {
        background: '#DBE7F4',
        borderRadius: '50%'
    },
    exploreMoreStyle:{
        fontSize:"16px",
        fontFamily:"Rubik"
    },
    descriptionStyleGrey:{
        fontSize:"14px",
        fontFamily:"Rubik",
        color:"#767677",
        marginTop:"11px",
        maxWidth:"400px"

    },
  leftBox: {
    margin: "16px 30px 30px 51px",
    background: "#FAFBFC",
    padding:"29px 19px",
    borderRadius:"10px"
  },
  leftHead: {
    fontFamily: "Rubik",
    fontSize: "22px",
    fontWeight: 400,
  },
  rightHead: {
    fontFamily: "Rubik",
    fontSize: "16px",
    fontWeight: 400,
  },
  imageWr: {
    marginBottom: "10px",
  },
  image: {
    maxWidth: "100%",
  },
  imgBox: {
    textAlign: "center",
  },
  heading: {
    fontFamily: "Rubik",
    fontSize: "22px",
    lineHeight: "27px",
    letterSpacing: "0px",
  },
  para: {
    fontFamily: "Rubik",
    fontSize: 14,
    fontWeight: 400,
    color: "#767677",
    marginTop:"15px"
  },
  postBtn: {
    width: "173px",
    height: "56px",
    background: "#206FC4",
    color: "#FFFFFF",
    marginTop: "20px",
    fontSize: "16px",
    fontFamily: "Rubik",
    fontWeight: 700,
    lineHeight: "18.75px",
    borderRadius: "10px",
    cursor: "pointer",
  },
  exploreHead: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "19px",
    letterSpacing: "0px",
  },
  exploreSubH: {
    fontFamily: "Rubik",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "20px",
    letterSpacing: "0px",
    textAlign: "left",
  },

  thirdBox: {
    background: "#FAFBFC",
   
  },
  jobPostDescription: {
    marginTop: "13px",
    color: "#767677",
    fontSize: "14px",
    lineHeight: "17px",
    fontFamily: "Rubik",
  },
  lastBox: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    background:"#FAFBFC",
    borderRadius:"10px",
    marginTop:"30px",
    marginLeft:"50px",
    padding:"29px 19px"
  },
  exploMoreGrid: {
    height: " 101px",
  },
  gridBoxone: {
    display: "flex",
    justifyContent: "space-between",
  },
  gridBoxTwo: {
    display: "flex",
    justifyContent: "space-between",
  },
  exploreMoreHeading: {
    fontFamily: "Rubik",
    fontSize: "16px",
    color: "#000000",
  },
  exploreMoreTxt: {
    fontFamily: "Rubik",
    fontSize: "14px",
    color: "#767677",
    maxWidth:"400px"
  },
  rightGrid: {
    display: "flex",
    marginTop: "20px",
    justifyContent: "flex-end",
    flexDirection: "column",
    marginRight:"50px"
  },
  imgStyle: {
    Width: "1rem",
  },
  leftTyp: {
    fontFamily:"Rubik",
    fontSize: "20px",
    fontWeight: 400,
    marginTop:"17px"
  },

  btn: {
    background: "#206FC4",
    fontSize: "16px",
    lineHeight: "19px",
    letterSpacing: "0px",
    color: "white",
    borderRadius: "10px",
    width: " 173px",
    height: " 56px",
    marginRight:"1rem",
    fontFamily:"Rubik",
    fontWeight:"bold",
    cursor:"pointer"
  },
  mainContainer1: {
    margin: "0 auto",
    padding:"52px 52px 0px 52px"
  },
  dashBoard: {
    fontFamily: "Rubik",
    fontSize: "32px",
    fontWeight: 400,
    lineHeight: "27px",
    letterSpacing: "0px",
  },
  rightGrid1: {
    display: "flex",
    justifyContent: "flex-end",
  },
  //Customizable Area End
};
