import React from "react";
//Customizable Area Start
import { Box, Button, Chip, CircularProgress, Dialog, DialogContent, Grid, styled, Typography } from "@material-ui/core";
import FreelancerOffersController, {
  Props,
} from "./FreelancerOffersController.web";
import FreelanceActive from "./FreelancerActive.web";
import FreelanceSubmitted from "./FreelanceSubmitted.web";
import FreelancerInvitationToInterviews from "./FreelancerInvitationToInterviews.web";
import Pagination from "@material-ui/lab/Pagination";
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Rating from "@material-ui/lab/Rating";

// Customizable Area End

export default class Freelance extends FreelancerOffersController {
  constructor(props: Props) {
    super(props);
    //Customizable Area Start

    
    // Customizable Area End
  }

  render() {
    //Customizable Area Start
    const { activeButton } = this.state;

    const Loader = () => (
     <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <CircularProgress />
      </div>
     );
     const { loadingOffers, loadingActiveProposals, loadingSubmittedProposals, loadingInvitationToInterview, loadingViewInvitation, loadingArchiveInterview } = this.state;

     if (loadingOffers || loadingActiveProposals || loadingSubmittedProposals || loadingInvitationToInterview || loadingViewInvitation || loadingArchiveInterview) {
       return <Loader />;
    }


    const  renderSuccessModal = () =>{
      return(
        <>
           <CustomDialogBox
        open={this.state.isDialogOpen}
        onClose={this.handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
         
        <DialogContent style={{
           padding:"25px" }}>
            <Typography gutterBottom style={webStyle.congratsText}>
                </Typography>
            <Typography style={webStyle.profileCreatedText}>
            You have successfully reject the proposal.
            </Typography>
            <Box style={{ display: "flex",
             flexDirection: "column" }}>
                <Button
                    variant="outlined"
                    style={webStyle.blueButton}
                    onClick={this.handleHome}
                >
                   OK
                </Button>
            </Box>
        </DialogContent>
    </CustomDialogBox>
        </>
      )
    }

    const hasOffers = (this.state.offerData?.data?.attributes?.offers?.offers?.data?.length ?? 0) > 0;
    //Customizable Area End
    return (
      //Customizable Area Start
      <>
      {renderSuccessModal()}
      <Box style={webStyle.mainBox}>
        <Grid container>
          <Grid container spacing={4} style={{ marginTop: "10px" }}>
            <Grid item style={{ flex: 1 }}>
              <CustomBox>
            <Box width={'70%'}
        >
                    {this.state.offerData?.offers?.data?.length>0 ? this.state.offerData?.offers?.data?.map((item: any, index: number) => {

            return(
                <CustomCard
                   >
                     <Card>
                     <CardContent>
                       <Typography
                         style={{
                           ...webStyle.text,
                           fontSize: '20px',
                           fontWeight: 700,
                           color: '#303030'
                         }}
                       >{item?.attributes?.post?.data?.attributes?.name}</Typography>
                       <Box display={'flex'} 
                       alignItems={'center'}>
                   <LocationOnIcon style={{
                      marginLeft: '-5px', 
                      marginRight: '3px' 
                      }} />
                   <Typography style={{ 
                     ...webStyle.text, 
                     marginTop: '10px', 
                     display: 'contents',
                      }}>
                  {item?.attributes?.post?.data?.attributes?.country?.name}
                     </Typography> 
                   <Rating name="simple-controlled" value={3} disabled
                     style={{
                       marginRight: '15px',
                       marginLeft: '7px',
                       width: '90px',
                       padding: '8px 0px',
                     }}/>
                   <Typography style={{ ...webStyle.text, marginLeft: '20px' }}>
                     2.91/5
                   </Typography>
                 </Box>
                         <Box display={'flex'} style={{ 
                           alignItems: 'center', 
                           marginTop: '12px' 
                           }}>
                         <Typography 
                         style={{fontSize: '14px', 
                         fontFamily: 'Rubik', 
                         marginRight: '5px'
                         }}>Est. Time:{''}
                         </Typography>
                         <Typography style={{
                           fontSize: '14px' , 
                           fontFamily: 'Rubik'
                           }}>
                             {item?.attributes?.post?.data?.attributes?.project_length}
                           </Typography>
                         </Box><Box style={{ marginTop: '10px', marginBottom: '24px' }}>
                         <Typography 
                         style={{fontSize: '14px', 
                         fontFamily: 'Rubik', 
                         color: '#6b6969'}}>{item?.attributes?.post?.data?.attributes?.job_description}</Typography>
                       </Box>
                       <Box>{item?.attributes?.post?.data?.attributes?.skills.map(
                           (item:
                               | boolean
                               | React.ReactPortal
                               | null
                               | undefined
                               | React.ReactChild
                               | React.ReactFragment) =>(
                             <Chip label={item} className='chiplabel'
                             />))}</Box>
                             </CardContent>
                     <Box>
                      <Box display={'flex'}>
                       <Typography 
                       style={{fontSize: '16px', 
                       fontFamily: 'Rubik', 
                       whiteSpace: 'nowrap', 
                       margin: '0px 5px' 
                       }}>Project Budget: {''}</Typography>
                       <Typography style={{fontSize: '16px', fontFamily: 'Rubik'}}>
                        ${item?.attributes?.post?.data?.attributes?.per_day_charge}
                       </Typography>
                     </Box>
                     <ProposalCount >
                       <Typography style={{fontSize: '14px', 
                                           fontFamily: 'Rubik',  
                                           whiteSpace: 'nowrap', 
                                           margin: '0px 5px' ,
                                           color: '#6b6969',
                                         }}>
                         Proposals: {''}
                       </Typography>
                       <Typography style={{fontSize: '16px', fontFamily: 'Rubik,',
                                           color: '#6b6969',
                       }}>
                        ${item?.attributes?.post?.data?.attributes?.proposals_count}
                       </Typography>
                     </ProposalCount>
                     </Box>  
                     </Card>
     
                       <ProposalBox className="proposalButton">
          <Box style={{alignContent: 'end',marginLeft: '5px'}} className="proposalDate">
            <Typography
              style={{
                ...webStyle.text,
                fontWeight: 400,
                fontFamily: 'Rubik',
                alignContent: 'end'
              }}
            >
             {item?.attributes?.post?.data?.attributes?.created_at}
            </Typography>
          </Box>
                 
                         <CustomButton>
                       <Button
                        onClick={() => this.rejectProposal(item.id)} 
                        data-testid="reject-0" 
                         style={{ cursor: 'pointer' , 
                                  border: '1px solid #364BA0', 
                                  color:'364BA0',
                                  padding: '7px 30px',
                                 marginRight: '10px',
                                 borderRadius: '12px',
                                 fontWeight: 700,
                                 height: '44px',
                                 width: '200px'
                                 }}
                       >
                        REJECT
                       </Button>
     
                         <Button
                           variant="contained"
                           style={{ backgroundColor: '#364BA0', color: 'FFF' ,
                             height: '44px',
                             width: '200px',
                             borderRadius: '12px', fontWeight: 700, whiteSpace: 'nowrap'
                           }}
                           onClick={() => { 
                             this.acceptProposal(item.id); 
                           }} 
                           data-testid="accept-0" 
                         >
                          ACCEPT
                         </Button>
     
                       </CustomButton>
                       </ProposalBox>
                   </CustomCard>
             )
        }) :  <Typography style={{
          fontSize: '19px',
            justifyContent: 'center',
            display: 'flex',
            color: '#878484',
            fontFamily: 'Rubik',
            fontWeight: 700,
            marginTop: '100px',
            marginBottom: '20pc'
        }}>No proposals found</Typography>}
        </Box>
        </CustomBox>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
      //Customizable Area End
    );
  }
}

//Customizable Area Start
const webStyle = {
  btnStyle: {
    padding: '10px 30px',
    fontWeight: 600,
    borderRadius: '8px',
    backgroundColor: '#364BA0',
    fontSize: '18px',
    fontFamily:"Rubik",
    border: '1px solid #364BA0',
    '&:hover': {
      backgroundColor: '#364BA0'
    }
  },
  blueButton:{
    backgroundColor: '#364BA0',
    height:"40px",
    borderRadius:"10px",
    borderColor: '#364BA0',
    padding:"22px",
    color: '#fff',
    fontSize:"18px",
    fontFamily:"Rubik",
    marginBottom: '10px',
    fontWeight:500,
    width: '50%',
    margin: '15px auto 15px'
  },
  congratsText:
  {
    marginTop:"10px",
    fontWeight: 600,
    fontFamily: "Rubik",
    fontSize: "24px",
  color: "#515354",
  },
  profileCreatedText:{
   marginBottom: '20px',
   marginTop: "18px",
   fontFamily: "Rubik",
    fontSize: "24px",
    fontWeight: 500,
    color:'#515354'
},
  mainBox: {
    // padding: "30px",
  },
  leftBox: {
    display: "flex",
    justifyContent: "center",
    
  },
  findWork: {
    color: "#0A0A0A",
    fontFamily: "Inter",
    fontSize: "20px",
    fontWeight: 500,
    lineHeight: "24px",
  },
  proposal: {
    color: "#206FC4",
    fontFamily: "Inter",
    fontSize: "20px",
    fontWeight: 500,
    lineHeight: "24px",
  },

  activeBtn: {
    background: "#206FC4",
    width: "300px",
    height: "50px",
    borderRadius: "25px",
    color: "#FFFFFF",
    fontFamily: "Inter,Rubik" as "Inter,Rubik",
    fontSize: "20px",
    fontWeight: 500,
    lineHeight: "24px",
    textTransform: "none" as "none",
  },
  inactivbt: {
    fontFamily: "Inter,Rubik" as "Inter,Rubik",
    fontSize: "20px",
    fontWeight: 500,
    lineHeight: "24px",
    textTransform: "none" as "none",
    color: "#434343",
    marginTop: "10px",
  },
  rightBox: {
    border: "1px solid #EEE0E0",
    borderRadius: "10px",
  },
  tableContent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  date: {
    color: "#434343",
    fontFamily: "Inter",
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "28px",
  },
  rightGrid: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  tableContainer: {
    padding: "20px",
    width: "1006px",
    borderBottom: "1px solid #EEE0E0",
  },
  jobname: {
    color: "#206FC4",
    fontFamily: "Inter",
    fontSize: "18px",
    fontWeight: 500,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  amount: {
    color: "#414141",
    fontFamily: "Roboto",
    fontSize: "18px",
    fontWeight: 500,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  startDateText: {
    color: "#434343",
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "18px",
  },
  rejectBtn: {
    background: "#DBE7F6",
    color: "#206FC4",
    fontFamily: "Inter",
    fontWeight: 500,
    fontSize: "14px",
  },
  accepBtn: {
    background: "#206FC4",
    color: "#FFFFFF",
    fontWeight: 500,
    fontSize: "14px",
    fontFamily: "Inter",
  },
  paginationContainer: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    marginTop:"14px"
},
noProfile:{
  fontSize:'1.5rem',
  paddingTop:'5%',
  textAlign:'center' as 'center',
  color:'grey'
},
text: {
  color: '#0A0A0A',
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontSize: '14px',
  fontWeight: 300
},
};
const ProposalBox = styled(Box)({
  display:'flex',
  marginTop:'10px',
  justifyContent:'space-between',
  "@media (max-width: 600px)": {
  display: 'block'
  },
  "& .proposalDate": {
    alignContent: 'end', 
    marginLeft: '12px' ,
    display: 'flex',
    "@media (max-width: 870px)": {
      marginBottom: '12px'
      },
  }
});
const ProposalCount = styled(Box)(({ theme }) => ({
  marginTop: '10px',
  justifyContent: 'end',
  display: 'flex',
  '@media (max-width: 700px)': {
    justifyContent: 'start'
  },
}));

const CustomBox = styled(Box)(({ theme }) => ({
  marginRight: '50px',
  marginTop: '52px',
  display: 'grid',
  gridAutoFlow: 'row',
  gridRowGap: '1rem',
  '@media (max-width: 700px)': {
    marginLeft: '45px',
  },
  '@media (max-width: 500px)': {
    marginLeft: '30px',
  },
}));

const CardContent = styled(Box)(({ theme }) => ({
  width: '70%',
 '@media (max-width: 450px)': {
   width: '100%',
   marginBottom: '30px'
 },
 '& .chiplabel':{
  marginRight: '10px',
  color: '#364BA0',
  backgroundColor: '#E4F5FF',
  fontSize: '16px', 
  fontWeight: 700,
 padding: '12px',
 '& .MuiChip-label' : {
   fontWeight: 700,
   fontSize: '16px', 
   textTransform: 'uppercase',
 } ,
 '@media (max-width: 800px)' : {
   marginBottom: '10px'
 },
},
}));

const CustomCard = styled(Box)(({ theme }) => ({
  borderRadius: '12px',
  backgroundColor: 'rgb(230, 235, 239)',
  padding: '24px',
  width: '100%',
  marginBottom: '24px'
}));

const CustomButton = styled(Box)(({ theme }) => ({
  display: 'flex',
  "@media (max-width: 400px)": {
    margin: '10px',
    display: 'grid',
  },
 }));
 
 const Card = styled(Box)(({ theme }) => ({
   display: 'flex',
   justifyContent: 'space-between',
   '@media (max-width: 700px)': {
     display: 'block'
   },
 }));
 
 const CustomDialogBox = styled(Dialog)({
  '& .MuiPaper-rounded':{
    width:"760px",
    borderRadius: "18px",
  }
})

 const CardFooter = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '10px',
  '@media (max-width: 700px)': {
    display: 'block',
  },
}));
//Customizable Area End
