import React from "react";
// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  Grid,
  FormControl,
  OutlinedInput,
  Select,
  MenuItem,
  IconButton,
  Avatar,
  styled,
} from "@material-ui/core";

import FreelancerNavBar from "../../dashboard/src/FreelancerNavBar.web";
import FooterWeb from "../../dashboard/src/Components/Footer.web";
import CloseIcon from '@material-ui/icons/Close';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import  RemindMeDialogbox  from "./RemindMeDialogbox.web";
import { step_2,step_1_active,step_3,step_4,step_5, dateIcon, viewBarInActive, } from "./assets";

export const CustomDiv = styled('div')((props: any) => ({
  '& .react-tel-input .flag-dropdown': {
    borderColor: props.phoneError ? 'red' : undefined,
  },
}));

const CustomSelect =styled(Select)({
  '& .MuiSelect-outlined':{
      borderRadius: "16px",

  }
})
// Customizable Area End

import CustomFormController, {
  Props,
  Touched,
  Error,
  Dropdown,
  Data
} from "./CustomFormController.web";

export default class CustomForm extends CustomFormController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  dateOfBirthFreelancerError = () => {
    return (
      this.state.dateOfBirthFreelancerError && (
        <div style={webStyle.formError}>
          {this.state.dateOfBirthFreelancerError}
        </div>
      )
    )
  }

  streetAddressError = () => {
    return (
      this.state.streetAddressError && (
        <div style={webStyle.formError}>
          {this.state.streetAddressError}
        </div>
      )
    );
  };

  countryFreelancerError = () => {
    return (
      this.state.countryFreelancerErr && (
        <div style={webStyle.formError}>
          {this.state.countryFreelancerErr}
        </div>
      )
    );
  };

  cityError = () => {
    return (
      this.state.cityError && (
        <div style={webStyle.formError}>
          {this.state.cityError}
        </div>
      )
    );
  };

  phoneError = () => {
    return (
      this.state.phoneError && (
        <div style={webStyle.formError}>
          {this.state.phoneError}
        </div>
      )
    );
  };

  jobTitleError = () => {
    return (
      this.state.jobTitleError && (
        <div style={webStyle.formError}>
          {this.state.jobTitleError}
        </div>
      )
    );
  };

  skillsError = () => {
    return (
      this.state.skillsError && (
        <div style={webStyle.formError}>
          {this.state.skillsError}
        </div>
      )
    );
  };

  descriptionError = () => {
    return (
      this.state.descriptionError && (
        <div style={webStyle.formError}>
          {this.state.descriptionError}
        </div>
      )
    );
  };

  stateError = () => {
    return (
      this.state.stateError && (
        <div style={webStyle.formError}>
          {this.state.stateError}
        </div>
      )
    );
  };

  zipCodeError = () => {
    return (
      this.state.zipCodeError && (
        <div style={webStyle.formError}>
          {this.state.zipCodeError}
        </div>
      )
    );
  };
  suiteError = () => {
    return (
      this.state.suiteError && (
        <div style={webStyle.formError}>
          {this.state.suiteError}
        </div>
      )
    );
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    const basicDetailsData = this.state.basicDetailsData.data.attributes
    // Customizable Area End
    return (
      // Customizable Area Start

      <>
        <FreelancerNavBar navigation={this.props.navigation} isFromHomePage={true}/>
        <Styled_wrapper>

      
        <Stepper_custom_design>
          <Box className="box_1">  <img src={step_1_active}style={{height:"40px"}}/></Box>
          <img src={viewBarInActive} className="view_bar"/>
          <Box className="box_1 img_second"> <img src={step_2}/></Box>
          <img src={viewBarInActive} className="view_bar"/>
          <Box className="box_1"> <img src={step_3}/></Box>
          <img src={viewBarInActive} className="view_bar"/>
          <Box className="box_1"> <img src={step_4}/></Box>
          <img src={viewBarInActive} className="view_bar"/>
          <Box className="box_1"> <img src={step_5} style={{height:"33px"}}/></Box>
        </Stepper_custom_design>
          <Typography style={webStyle.basicDetailsHead}>Add your Basic details</Typography>
          <Box style={webStyle.BoxTwoStyle}>
            <ImageUpload_Grid item xs={12} sm={12} >
              <div>
                <Avatar src={this.state.profileImage}  className="Avatar_upload"/>
              </div>
              <div style={{ marginTop: "10px" }}>
                <Typography style={webStyle.nameStyle}>
                  {this.state.freelancerName}
                </Typography>
                <Typography style={webStyle.emailStyle} >
                  {this.state.freelancerEmail}
                </Typography>
                <button
                  style={webStyle.updateProfileBtn}
                  onClick={this.handleEditClick}
                  data-testid="profile-pic-edit"
                >
                  Upload profile picture
                </button>
                <input
                  type="file"
                  data-testid="profileImageInput"
                  ref={this.state.fileInputRef}
                  style={{ display: "none" }}
                  onChange={this.handleFileChange}
                  accept="image/*"
                />
                {this.state.imageUploadError && (
                  <Typography style={webStyle.formError}>
                    {this.state.imageUploadError}
                  </Typography>
                )}
              </div>
            </ImageUpload_Grid>

            <Grid container >
              <Grid item xs={12} sm={12}>
                <Grid container spacing={3}>

                  <Grid item xs={12}>
                    <Grid item xs={12} sm={4} style={{ marginTop: "24px" }}>
                      <Typography style={webStyle.labelStyle}>Date of birth*</Typography>
                      <InputField style={webStyle.textField} variant="outlined">
                        <OutlinedInput
                          type="date"  
                          name="date"
                          value={this.state.dateOfBirth || ''} 
                          onChange={this.handleDateChange} 
                          error={!!this.state.dateOfBirthFreelancerError} 
                          style={{ borderRadius: "12px" }}
                          inputProps={{
                            placeholder: "dd-mm-yyyy",
                            style: { color: this.state.dateOfBirth ? 'black' : '#979797' }
                          }}
                          
                        />

                      </InputField>
                      {this.dateOfBirthFreelancerError()}
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm={8}>
                    <Typography style={webStyle.labelStyle}>Street address*</Typography>
                    <InputField style={webStyle.textField} variant="outlined">
                      <OutlinedInput
                        name="streetAddress"
                        placeholder="Enter street address"
                        value={this.state.streetAddress}
                        onChange={this.handleStreetAdressChange}
                        error={!!this.state.streetAddressError}
                        style={webStyle.borderRadius}
                      />
                    </InputField>
                    {this.streetAddressError()}
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <Typography style={webStyle.labelStyle}>Apt/Suite*</Typography>
                    <InputField style={webStyle.textField} variant="outlined">
                      <OutlinedInput
                        placeholder="Enter Apt/Suite"
                        name="aptSuite"
                        value={this.state.suite}
                        onChange={this.handleSuiteChange}
                        error={!!this.state.suiteError}
                        style={webStyle.borderRadius}
                      />
                    </InputField>
                    {this.suiteError()}
                  </Grid>

                  <Grid item xs={12} sm={8}>
                    {console.log(this.state.countryList, "oiuytretyui")}
                    <Typography style={webStyle.labelStyle}>Additional address 2</Typography>
                    <InputField style={webStyle.textField} variant="outlined">
                      <OutlinedInput
                        name="additionalAddress2"
                        placeholder="Enter street address"
                        value={this.state.additionalAddress}
                        onChange={this.handleAddressChange}
                        style={webStyle.borderRadius}
                      />
                    </InputField>
                  </Grid>


                  <Grid item xs={12} sm={4}>
                    <Typography style={webStyle.labelStyle}>Country*</Typography>
                    <InputField style={webStyle.textField} variant="outlined">
                      <Select
                        defaultValue=""
                        displayEmpty
                        name="country"
                        value={this.state.country}
                        error={!!this.state.countryFreelancerErr}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                          },
                          transformOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                          },
                          getContentAnchorEl: null
                        }}
                        input={
                          <OutlinedInput
                            style={{
                              borderRadius: "12px", 
                              color: this.state.country ? 'black' : '#979797' 
                            }}
                            placeholder="Select Country" 
                          />
                        }
                        
                        onChange={(event) => this.handleCountryChange(event)}   
                        data-test-id="counter">
                        <MenuItem value="" disabled style={{ color: '#979797' }}>
                          Select Country
                        </MenuItem>
                        {this.state.countryList && this.state.countryList.map((country) => (
                          <SelectManu_style key={country.id} value={country.name} >
                            {country.name}
                          </SelectManu_style>
                        ))}
                      </Select>
                    </InputField>
                    {this.countryFreelancerError()}
                  </Grid>


                  <Grid item xs={12} sm={4}>
                    <Typography style={webStyle.labelStyle}>City*</Typography>
                    <InputField style={webStyle.textField} variant="outlined">
                      <OutlinedInput
                        placeholder="Enter city"
                        error={!!this.state.cityError}
                        name="city"
                        value={this.state.city}
                        onChange={this.handleCityChange}
                        style={webStyle.borderRadius}
                      />
                    </InputField>
                    {this.cityError()}
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <Typography style={webStyle.labelStyle}>State</Typography>
                    <InputField style={webStyle.textField} variant="outlined">
                      <Select
                        name="state"
                        error={!!this.state.stateError}
                        value={this.state.state}
                        onChange={(event) => this.handleStateChange(event)}
                        displayEmpty
                        data-test-id="counterstate"
                        style={webStyle.borderRadius}
                      >
                        <MenuItem value="" disabled>
                          <span style={{ color: "#979797" }}>Select State</span>
                        </MenuItem>
                        {this.state.stateList.map((state) => (
                          <SelectManu_style key={state.id} value={state.id}>
                            {state.name}
                          </SelectManu_style>
                        ))}
                      </Select>
                    </InputField>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <Typography style={webStyle.labelStyle}>ZIP/Postal code*</Typography>
                    <InputField style={webStyle.textField} variant="outlined">
                      <OutlinedInput
                        placeholder="Enter ZIP/Postal code"
                        name="zipCode"
                        value={this.state.zipCode}
                        onChange={this.handleZipCodeChange}
                        error={!!this.state.zipCodeError}
                        style={webStyle.borderRadius}
                      />
                    </InputField>
                    {this.zipCodeError()}
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <Typography style={webStyle.labelStyle}>Phone*</Typography>
                    <CustomDiv phoneError={this.state.phoneError} style={{
                    borderRadius: "12px",
                     position: 'relative', 
                    border: this.state.phoneError ? "1px solid red" : "1px solid #ccc",
                    }}>
                      
                    <PhoneInput
                      inputStyle={{ width: "100%", height: 55,
                      borderColor: this.state.phoneError ? 'red' : undefined,
                      borderRadius:"12px"
                      }}
                      country={'in'}
                      value={this.state.phone}
                      data-test-id="phone"
                      onChange={this.handlePhoneChange}
                      placeholder= "Enter number"
                    />
                    </CustomDiv>
                    {this.phoneError()}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>

          <Box style={webStyle.mainSubBox}>
            <Typography className="basicDetailsHead">Add your Job Title and Skills</Typography>
            <Box style={webStyle.BoxTwoStyle}>
              <Grid container spacing={3}>

                <Grid item xs={12}>
                  <Typography style={webStyle.labelStyle}>Job Title*</Typography>
                  <InputField style={webStyle.textField} variant="outlined">
                    <Select
                      name="jobTitle"
                      value={this.state.jobTitle || ""}
                      error={!!this.state.jobTitleError}
                      displayEmpty
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left"
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left"
                        },
                        getContentAnchorEl: null,
                        PaperProps: {
                          style: {
                            maxHeight: 200,
                            overflowY: "auto",
                            scrollbarColor: "#364BA0 #f0f0f0",
                            scrollbarWidth: "thin"
                          }
                        }
                      }}
                      style={webStyle.borderRadius}
                      onChange={this.handleJobTitleChange}
                    >
                      <MenuItem value="" disabled>
                        <span style={{ color: '#979797' }}>Select Job</span> 
                      </MenuItem>
                      {this.state.jobsArray.map((job) => (
                      <MenuItem key={job.id} value={job.job_title}>
                        {job.job_title}
                      </MenuItem>
                    ))}                 
                    </Select>
                  </InputField>
                  {this.jobTitleError()}
                </Grid>

                <Grid item xs={12}>
                <Typography style={webStyle.labelStyle}>Your Skills*</Typography>
                <FormControl fullWidth variant="outlined">
                <CustomSelect
                  value="" 
                  displayEmpty
                  data-test-id="handleSkillsChange"
                  onChange={(e) => this.handleSkillsChange(e.target.value as string)}
                  style={webStyle.textField}
                >
                  <MenuItem value="" disabled>
                    Enter your skills
                  </MenuItem>
                  {this.state.skillsOption.map((skill, index) => (
                    <MenuItem key={index} value={skill}>
                      {skill}
                    </MenuItem>
                  ))}
                </CustomSelect>
                </FormControl>
                {this.skillsError()}
              </Grid>
                {this.state.skillsArray.map((skill, index) => (
                  <Box key={index} style={{ margin: '12px 10px 10px 10px' }}>
                    <Box style={webStyle.chipStyle}>
                      <Typography style={webStyle.skillStyle}>{skill}</Typography>
                      <IconButton size="small" onClick={() => this.handleDeleteSkill(skill)}
                        data-test-id="deletedata">
                      
                      
                        <CloseIcon style={webStyle.iconStyle} />
                      </IconButton>
                    </Box>
                  </Box>
                ))}


                <Grid item xs={12}>
                  <Typography style={webStyle.labelStyle}>Description*</Typography>
                  <InputField style={webStyle.textField} variant="outlined">
                    <OutlinedInput placeholder="Tell us about yourself"
                      fullWidth
                      multiline
                      minRows={4}
                      name="description"
                      value={this.state.description}
                      onChange={this.handleDescriptionChange}
                      error={!!this.state.descriptionError}
                      style={webStyle.borderRadius}
                    />
                  </InputField>
                  {this.descriptionError()}
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Typography style={{fontFamily:"Rubik",fontSize:"16px",fontWeight:400,marginTop:"10px"}}>* indicates required</Typography>

          <Grid container justifyContent="space-between" alignItems="center" style={{ marginTop: "26px" }}>
              <Grid container spacing={2} style={{display:"flex", justifyContent:"end"}}>
                <Grid item>
                  <Button
                    variant="text"
                    onClick={()=>{this.doItLater()}}
                    className="laterText"
                  >
                    I'll do it later
                  </Button>
                </Grid>
                <Grid item>
                  <Next_button_Custom variant="contained"  onClick={this.handleNext}>
                    Next
                  </Next_button_Custom>
                </Grid>
              </Grid>
          </Grid>


        {this.state.do_It_Later?<>
              <RemindMeDialogbox  navigation={this.props.navigation} id={""}/>
            </>:""}
            </Styled_wrapper>
        <FooterWeb />
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {

  mainBox: {
    padding: "18px 21px",
    maxWidth: "1500px",
    margin:"auto"

  },
  stepperStyle: {
    width: "100%",
    height: "auto",
    maxWidth: "1500px",
    margin: "0 auto",
    display: "block",
  },
  mainSubBox: {
    marginTop: "28px"
  },
  basicDetailsHead: {
    fontFamily: "Rubik",
    fontSize: "32px",
    fontWeight: 600,
  },
  BoxTwoStyle: {
    border: "1px solid #B1B1B1",
    padding: "28px",
    borderRadius: "12px",
    marginTop: "20px"
  },
 
  nameStyle: {
    fontFamily: "Rubik",
    fontSize: "28px",
    fontweight: 500,
    color: "#0A0A0A"
  },
  emailStyle: {
    fontFamily: "Rubik",
    fontSize: "18px",
    color: "#0A0A0A"
  },
  updateProfileBtn: {
    color: "#364BA0",
    border: "1px solid #364BA0",
    borderRadius: "26px",
    marginTop: "16px",
    display: "flex",
    justifyContent: "center",
    padding: "10px",
    fontSize: "20px",
    fontFamily: "Rubik",
    fontWeight: 500
  },
  labelStyle: {
    fontFamily: "Rubik",
    fontSize: "16px",
    color: "#0A0A0A",
    fontWeight: 500,
    marginBottom: "8px"
  },
  textField: {
    borderRadius:"12px",
    width: "100%",
    "&.MuiFormLabel-root.Mui-focused ": {
      color: "#6F6E6E !important",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline ": {
      borderColor: "#6F6E6E !important",
      borderRadius: "12px !important"
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "12px !important"
    }
  },
  borderRadius:{
    borderRadius:"12px"
  },

  chipStyle: {
    background: "#E4F5FF",
    borderRadius: "26px",
    display: "flex",
    padding: "8px",
    gap: "6px",
    justifyContent: "center",
  },
  nextButton: {
    backgroundColor: '364BA0',
    color: '#fff',
    height: "40px",
    padding: "22px",
    fontFamily: "Rubik",
    fontSize: "18px",
    fontWeight: 500
  },
  buttonStyle: {
    margin: '10px 0',
    fontWeight: 500,
  },
  backButton: {
    borderColor: '#364BA0',
    color: '#364BA0',
    padding: "22px",
    fontFamily: "Rubik",
    fontSize: "18px",
    fontWeight: 500,
    height: "40px",
  },

  laterText: {
    color: '#364BA0',
    fontFamily: "Rubik",
    fontSize: "18px",
    textTransform: 'uppercase' as 'uppercase',
    textDecoration: 'underline' as 'underline',
    fontWeight: 500
  },
  formError: {
    color: "red",
    fontFamily: "'Inter', sans-serif",
    marginTop: "4px",
    fontSize: 14,
  },
  skillStyle: {
    color: '#0A0A0A',
    padding: "2px 8px"
  },
  iconStyle: {
    color: '#0A0A0A',
    fontSize: '20px',
  },
};

const ImageUpload_Grid = styled(Grid)({
    display: "flex",
    gap: "48px",
    "@media(max-width:500px)": {
      display: "block",
    },
    "& .Avatar_upload":{
     width: "150px", 
     height: "150px",
     "@media(max-width:500px)": {
      width: "100px", 
      height: "100px",
    },
    }
});

const Styled_wrapper = styled(Box)({
  padding: "18px 48px",
  maxWidth: "1500px",
  margin:"auto",
  "@media(max-width:700px)": {
    padding: "12px 25px",
    },
  '& .basicDetailsHead': {
    fontFamily: "Rubik",
    fontSize: "32px",
    fontWeight: 600,
    "@media(max-width:700px)": {
    fontSize: "28px",
       
    },
    "@media(max-width:500px)": {
      fontSize: "24px",
      },
    },
  "& .laterText":{
    color: '#364BA0',  
    textTransform: 'uppercase' as 'uppercase',
    textDecoration: 'underline' as 'underline',
    fontFamily:"Rubik",
    fontSize:"18px",
    fontWeight:500,
    "@media(max-width:500px)": {
      fontSize:"15px",

    },
  },
});

const InputField = styled(FormControl)({
  "@media(max-width:500px)": {
    "&  .MuiOutlinedInput-input" :{
        padding: "11.5px 12px",
      }
    },

    "& .MuiListItem-button": {
      "& :hover":{
        textDecoration: "none",
        backgroundolor: "red",

      }
    }
});
const Next_button_Custom = styled(Button)({
  backgroundColor: '364BA0',
    color: '#fff',
    height:"40px",
    fontFamily:"Rubik",
    fontSize:"18px",
    padding:"22px",
    fontWeight:500,
    borderRadius:"10px",
    "@media(max-width:500px)": {
     padding:"14px"
    },
    "& .MuiButton-label":{
      fontSize:"14px",
      borderRadius:"10px"
    }

});
const Back_button_Custom = styled(Button)({
  color: '#364BA0',
  borderColor: '#364BA0',
    height:"40px",
    fontFamily:"Rubik",
    padding:"22px",
    fontWeight:500,
    fontSize:"18px",
    borderRadius:"10px",
    "@media(max-width:500px)": {
     padding:"14px"

    },
    "& .MuiButton-label":{
      fontSize:"14px"
    }

});
const SelectManu_style = styled(MenuItem)({

  "&:hover": {
    backgroundColor: "364BA0",
    color:"white",
  },
});


const Stepper_custom_design = styled(Box)(
  {
  alignItems:"center",
  display:"flex",
  margin:"30px 0 ",
  height:"40px",
  justifyContent:"space-between",

  "@media (max-width: 1160px)": {
    flexWrap:"wrap",
    justifyContent:"flex-start",
    paddingBottom: '50px'
  },
  "& .img_stepper":{
    height:"50px",
    width:"100%"
  },
  "& .img_stepper_2":{
    width:"100%",
    height:"50px",
  "@media (max-width: 1050px)": {
    width:"100%",

  }

  },

  "& .img_second":{
    "@media (max-width: 1050px)": {
      width:"auto",
 
  
    },
  

  },
  "& .box_1":{
    width:"auto",
  },

 
 });
// Customizable Area End
