import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import storage from "../../../framework/src/StorageProvider";
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
    navigation: any;
    // Customizable Area Start
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    openBillingModal: boolean
    openPaymentCardModal:boolean
    selectedValue: string,
    showPayPalSection: boolean
    showAddPaymentCard: boolean
    isDialogOpen: boolean
    cardDetailsData: any
    userDetails: any
    successMessage: string
    errorMessage: string
    isCardDialogOpen: boolean
    authToken:any;
    paymentSuccessPopup: boolean
    showDetails: boolean
    cardNumber : string
    cardHolderName: string
    validDate: string
    validYear: string
    cvvNumber: string
    deletePaypalDetails: string
    isDeleteModal: boolean
    paypalData: any
    errors: any,
    // Customizable Area End
}
interface SS { }

export default class ClientBillingInformationController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    paypalApiCallDetailsId: any
    redirectPaypalApiCallDetailsId: any
    cardFetailsApiCallId: any;
    userCallID: any
    cardPaymentId: any
    paypalDeleteCallId: any

    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        console.disableYellowBox = true;
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
        ];
        this.paypalApiCallDetailsId = "",
        this.redirectPaypalApiCallDetailsId = "",
        this.cardFetailsApiCallId = "",
        this.userCallID = "",
        this.cardPaymentId = "",
        this.paypalDeleteCallId = "",
        this.state = {
            openBillingModal: false,
            openPaymentCardModal:false,
            selectedValue: 'PaymentCard',
            showPayPalSection: false,
            showAddPaymentCard:false,
            isDialogOpen: false,
            cardDetailsData: [],
            userDetails: [],
            successMessage: '',
            paypalData: [],
            errorMessage: '',
            isCardDialogOpen: false,
            authToken: '',
            paymentSuccessPopup: false,
            showDetails: false,
            cardNumber : '',
            cardHolderName: '',
            validDate: '',
            validYear: '',
            cvvNumber: '',
            deletePaypalDetails: '',
            isDeleteModal: false,
            errors: {
                cardNumber: "",
                cardHolderName: "",
                validDate: "",
                cvvNumber: ''
              },
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();
        this.getDashboardData();
        // Customizable Area Start
        this.setState({
            authToken: await storage.get("authToken"),
          })
        this.getCardDetailsCall()
          this.getUserData()
        // Customizable Area End
    }

    getDashboardData(): boolean {
        // Customizable Area Start

        // Customizable Area End
        return true;
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const webApiRequestCallId = message.getData(
              getName(MessageEnum.RestAPIResponceDataMessage)
            );
        
            let webResponseJson = message.getData(
              getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
        
        this.handleCardDetails(webResponseJson,webApiRequestCallId)
        this.handlePaypalApiDetailsRes(webResponseJson,webApiRequestCallId)
        this.handleCardDetailsRes(webResponseJson,webApiRequestCallId)
        if (webResponseJson) {
            if (webApiRequestCallId === this.userCallID) {
                this.setState({
                    userDetails: webResponseJson?.current_user
                })
            } 
        }
        this.handleDeleteResponse(webResponseJson, webApiRequestCallId)

          }
        // Customizable Area End
    }
    // Customizable Area Start 
    handleCloseBillingModal =()=>{
        this.setState({ openBillingModal: false });
    }

    handleCardDetails = (webResponseJson: any,webApiRequestCallId: string ) => {
        if (webResponseJson) {
            if (webApiRequestCallId === this.cardFetailsApiCallId) {
          this.setState({
            cardDetailsData: webResponseJson?.data?.attributes
          })
          this.cardDetailsData()
            } 
        }
    }

    handlePaypalApiDetailsRes = (webResponseJson: any,webApiRequestCallId: string ) => {
        if (webResponseJson) {
            if (webApiRequestCallId === this.paypalApiCallDetailsId) {
            if (webResponseJson?.error) {
                this.setState({
                    isDialogOpen: true,
                    errorMessage: webResponseJson?.error
                })
                }
            if (webResponseJson?.data) {
            this.setState({
                isDialogOpen: true,
                successMessage: 'Payment method added successfully',
                paypalData : webResponseJson?.data
            })
            }
            this.setState({isDialogOpen: true})
            } 
        }
    }

    handleCardDetailsRes = (webResponseJson: any,webApiRequestCallId: string ) => {
        if (webResponseJson) {
            if (webApiRequestCallId === this.cardPaymentId) {
            if (webResponseJson?.error) {
                this.setState({
                    isCardDialogOpen: true,
                    errorMessage: webResponseJson?.error
                })
                }
            if (webResponseJson?.data) {
            this.setState({
                isCardDialogOpen: true,
                successMessage: 'Succesfully Created'
            })
            }
            } 
        }
    }



    handleDeleteResponse=(webResponseJson: any, webApiRequestCallId: string)=>{
        if (webResponseJson) {
            if (webApiRequestCallId === this.paypalDeleteCallId) {
                if (webResponseJson?.message) {
                this.setState({
                    deletePaypalDetails: webResponseJson?.message,
                    isDeleteModal: true
                })
            }
            if (webResponseJson?.error) {
                this.setState({
                    deletePaypalDetails: webResponseJson?.error,
                    isDeleteModal: true
                })}
            } 
        }
    }
    handleCloseDeleteDialog = () => {
        this.setState({
            isDeleteModal: false
        })
    }

    handleCardToken = (e: any) => {
        const value = e.target.value;
        const { errors } = this.state;
    
        if (/^\d*$/.test(value)) {
            this.setState({ cardNumber: value });
    
            // Validation for card number
            if (!/^\d{16}$/.test(value)) {
                this.setState({
                    errors: {
                        ...errors,
                        cardNumber: "Card number must be 16 digits."
                    }
                });
            } else {
                this.setState({
                    errors: {
                        ...errors,
                        cardNumber: ""
                    }
                });
            }
        }
    };
    
    handleHolderName = (e: any) => {
        const value = e.target.value;
        const { errors } = this.state;
    
        this.setState({ cardHolderName: value });
    
        // Validation for card holder name
        if (!value.trim()) {
            this.setState({
                errors: {
                    ...errors,
                    cardHolderName: "Please enter card holder name."
                }
            });
        } else if (!/^[a-zA-Z\s]+$/.test(value)) {
            this.setState({
                errors: {
                    ...errors,
                    cardHolderName: "Card holder name must only contain letters and spaces."
                }
            });
        } else {
            this.setState({
                errors: {
                    ...errors,
                    cardHolderName: ""
                }
            });
        }
    };
    
    handlevalidDate = (e: any) => {
        const value = e.target.value;
        const { errors } = this.state;
    
        if (/^\d{0,2}\/?\d{0,2}$/.test(value)) {
            this.setState({ validDate: value });
    
            // Validation for valid date
            if (!/^\d{2}\/\d{2}$/.test(value)) {
                this.setState({
                    errors: {
                        ...errors,
                        validDate: "Valid date must be in MM/YY format."
                    }
                });
            } else {
                const [month, year] = value.split('/').map(Number);
                const currentYear = new Date().getFullYear() % 100;
                const currentMonth = new Date().getMonth() + 1;
    
                if (
                    month < 1 ||
                    month > 12 ||
                    year < currentYear ||
                    (year === currentYear && month < currentMonth)
                ) {
                    this.setState({
                        errors: {
                            ...errors,
                            validDate: "Valid date is invalid or expired."
                        }
                    });
                } else {
                    this.setState({
                        errors: {
                            ...errors,
                            validDate: ""
                        }
                    });
                }
            }
        }
    };
    
    handleCVV = (e: any) => {
        const value = e.target.value;
        const { errors } = this.state;
    
        if (/^\d*$/.test(value)) {
            this.setState({ cvvNumber: value });
    
            // Validation for CVV
            if (!value.trim()) {
                this.setState({
                    errors: {
                        ...errors,
                        cvvNumber: "Please enter CVV."
                    }
                });
            } else if (!/^\d{3,4}$/.test(value)) {
                this.setState({
                    errors: {
                        ...errors,
                        cvvNumber: "CVV must be 3 or 4 digits."
                    }
                });
            } else {
                this.setState({
                    errors: {
                        ...errors,
                        cvvNumber: ""
                    }
                });
            }
        }
    };
    
    
    
    handlePaymentChange = (event: { target: { value: any; }; }) => {
        const { value } = event.target;
        this.setState({
            selectedValue: value,
            showPayPalSection: value === 'PayPal' || this.state.showPayPalSection,
            showAddPaymentCard: value === 'PaymentCard' || this.state.showAddPaymentCard,
        });
    };
    
    handleClosePaymentCardModal = ()=>{
        this.setState({ openPaymentCardModal: false });
    }

    cardDetailsData = () => {
        const { last4, exp_month, exp_year,cardholder_name, card_token  } = this.state.cardDetailsData
        this.setState({
            cvvNumber : last4,
            validDate: exp_year,
            validYear : exp_month,
            cardHolderName : cardholder_name,
            cardNumber: card_token
        })
    }

    handlePaymentCardSaveBtn = async () => {
        const { cardNumber, cardHolderName, cvvNumber,validDate, errors } = this.state;
        let hasErrors = false;
    
        // Reset error messages
        const newErrors = { cardNumber: "", cardHolderName: "", validDate: "", cvvNumber };
    
        // Validations
        if (!/^\d{16}$/.test(cardNumber)) {
          newErrors.cardNumber = "Card number must be 16 digits.";
          hasErrors = true;
        }
    
        if (!cardHolderName.trim()) {
          newErrors.cardHolderName = "Please enter card holder name.";
          hasErrors = true;
        } else if (!/^[a-zA-Z\s]+$/.test(cardHolderName)) {
          newErrors.cardHolderName = "Card holder name must only contain letters and spaces.";
          hasErrors = true;
        }
    
        if (!/^\d{2}\/\d{2}$/.test(validDate)) {
          newErrors.validDate = "Valid date must be in MM/YY format.";
          hasErrors = true;
        } else {
          const [month, year] = validDate.split('/').map(Number);
          const currentYear = new Date().getFullYear() % 100; 
          const currentMonth = new Date().getMonth() + 1;
    
          if (
            month < 1 ||
            month > 12 ||
            year < currentYear ||
            (year === currentYear && month < currentMonth)
          ) {
            newErrors.validDate = "Valid date is invalid or expired.";
            hasErrors = true;
          }
        }
        if (!cvvNumber.trim()) {
            newErrors.cvvNumber = "Please enter cvv name.";
            hasErrors = true;
          }
    
        // Update error state and exit if errors exist
        if (hasErrors) {
          this.setState({ errors: newErrors });
          return;
        }
        const updateProfileImage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        const header = {
          token: this.state.authToken
        };
        
        const formdata = new FormData();
        formdata.append("card_token", 'tok_mastercard');
        formdata.append("payment_type", 'card');

        
        this.cardPaymentId = updateProfileImage.messageId;
        updateProfileImage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `bx_block_stripe_integration/add_payment_method`
        );
        updateProfileImage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        updateProfileImage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          formdata
        )
        updateProfileImage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          'POST'
        );
        runEngine.sendMessage(updateProfileImage.id, updateProfileImage);
      }

      getCardDetailsCall = () => {
        const currentProposalsDetails = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
      
        const header = {
          token: this.state.authToken,
          "Content-Type": "application/json", 
        };
      
      
        this.cardFetailsApiCallId = currentProposalsDetails.messageId;
      
        currentProposalsDetails.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `bx_block_stripe_integration/get_payment_method?payment_type=card`
        );
      
        currentProposalsDetails.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );

        currentProposalsDetails.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          "GET"
        );
      
        runEngine.sendMessage(currentProposalsDetails.id, currentProposalsDetails);
      };
      handleNextButton = () => {
        if (this.state.selectedValue === 'PayPal') {
            this.paypalApiCall()
            this.setState({ showPayPalSection: true });
        } else if (this.state.selectedValue === 'PaymentCard') {
            this.setState({ openPaymentCardModal: true });
        }
        this.handleCloseBillingModal();
    };
    handleBillingMethod = ()=>{
        this.setState({ openBillingModal: true });
    }
      paypalApiCall = () => {
        const currentProposalsDetails = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
      
        const header = {
          token: this.state.authToken,
          "Content-Type": "application/json", 
        };
      
        const body = {
          payment_type: "paypal",
        };
      
        this.paypalApiCallDetailsId = currentProposalsDetails.messageId;
      
        currentProposalsDetails.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `bx_block_stripe_integration/add_paypal_payment_method`
        );
      
        currentProposalsDetails.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
      
        currentProposalsDetails.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(body) 
        );
      
        currentProposalsDetails.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          "POST"
        );
      
        runEngine.sendMessage(currentProposalsDetails.id, currentProposalsDetails);
      };
      
      redirectPaypalApiCall = () => {
        const currentProposalsDetails = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        const header = {
          token: this.state.authToken,
          "Content-Type": "application/json", 
        };
        const body = {
          payment_type: "paypal",
        };
      
        this.redirectPaypalApiCallDetailsId = currentProposalsDetails.messageId;
      
        currentProposalsDetails.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `bx_block_stripe_integration/paypal_client_to_admin?milestone_id=168`
        );
      
        currentProposalsDetails.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
      
        currentProposalsDetails.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(body) 
        );
      
        currentProposalsDetails.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          "POST"
        );
        runEngine.sendMessage(currentProposalsDetails.id, currentProposalsDetails);
      };

      getUserData = async () => {

          
          const requestMessage = new Message(
              getName(MessageEnum.RestAPIRequestMessage)
            );
            
            const header = {
                "Content-Type": configJSON.dashboarContentType,
                token: this.state.authToken
            };
            this.userCallID = requestMessage.messageId;
   
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          'account_block/accounts/get_client_profile_online_status'
        );
      
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
      
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
         "GET"
        );
      
        runEngine.sendMessage(requestMessage.id, requestMessage);
      };

    handleOkay = () => {
        this.setState({ 
            isDialogOpen: false,
         });
    }

    handleCloseDialog = () => {
        this.setState({ isDialogOpen: false });
      };

      handleCloseCardDialog = () => {
        this.setState({
            isCardDialogOpen: false,
            openPaymentCardModal: false,
        })
      }

    handleCloseSuccessPopup = () =>{
        this.setState({ paymentSuccessPopup: false, showDetails: true})
    }

    handleDeletePaypalAccount = async () => {     
     
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
          );

          const body = {
            payment_type: "paypal",
          };
          
          const header = {
              "Content-Type": configJSON.dashboarContentType,
              token: this.state.authToken
          };
          this.paypalDeleteCallId = requestMessage.messageId;
 
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_stripe_integration/delete_paypal_account_from_db?id=${this.state.paypalData?.id}`
      );
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body) 
      );
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
       "DELETE"
      );
    
      runEngine.sendMessage(requestMessage.id, requestMessage);
    };


    handleDeleteMethod = (method: string) => {
        if (method === "PayPal") {
          this.setState({ showPayPalSection: false });
          this.handleDeletePaypalAccount()
        } else if (method === "PaymentCard") {
          this.setState({ showAddPaymentCard: false });
        }
      };
        // Customizable Area End
}