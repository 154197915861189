//Customizable Area Start
import React from "react";
//Customizable Area Start
import { Box, Grid, Tab, Tabs, Typography, styled, Divider } from "@material-ui/core";
import ClientNavBar from "./Components/ClientNavBar.web";
import FooterWeb from "./Components/Footer.web";
import ActiveContractDetailsControllerClient, { Props } from './ActiveContractDetailsControllerClient.web';
import ArchivedContract from "./ArchivedContract.web";
import ActiveContractClient from "./ActiveContractClient.web";

export const webConfigJSON = require("./config");



const CustomTabs = styled(Tabs)({
    '& .MuiTab-textColorPrimary-25.Mui-selected': {
        fontWeight: "600",
        fontSize: "22px",
        textTransform: "inherit"
    },
    '& .MuiTab-textColorPrimary-25': {
        fontWeight: "600",
        fontSize: "22px",
        textTransform: "inherit"
    },
    '& .PrivateTabIndicator-root-31': {
        height: "4px"
    }
})
// Customizable Area End

export default class AllContractClient extends ActiveContractDetailsControllerClient {
    constructor(props: Props) {
        super(props);
        //Customizable Area Start
        // Customizable Area End
    }
    //Customizable Area Start



    // Customizable Area End

    render() {
        //Customizable Area Start
        const { selectedTab } = this.state;
        return (
            <>
                <ClientNavBar />
                <Grid container spacing={2} lg={12} style={webStyle.gridBox}>
                    <Grid item lg={12} xs={12}>
                        <Box>
                            <HeaderTxt>{webConfigJSON.allcontract}</HeaderTxt>
                            <CustomTabs
                                value={selectedTab}
                                indicatorColor="primary"
                                onChange={this.handleTabChange}
                                textColor="primary">
                                <Tab label="Active Contracts" style={{ textTransform: "none", fontFamily: "Rubik", fontSize: "16px", color: "#3F3F3F", fontWeight: 500 }} />
                                <Tab label="Archived Contracts" style={{ textTransform: "none", fontFamily: "Rubik", fontSize: "16px", color: "#3F3F3F", fontWeight: 500 }} />
                            </CustomTabs>
                            <Divider />
                        </Box>
                    </Grid>
                    {selectedTab === 1 && (
                        <ArchivedContract navigation={this.props.navigation} />
                    )}
                    {selectedTab === 0 && (
                        <ActiveContractClient navigation={this.props.navigation}/>
                    )}

                </Grid>

                <FooterWeb />
            </>
        );
        //Customizable Area End
    }
}

//Customizable Area Start

const webStyle = {

    gridBox: {
        display: 'flex',
        margin: "45px 35px 45px 35px",
        overflow: "hidden"
    },

}
const TitleTxt = styled(Typography)({
    margin: "20px 0px",
    display: "flex",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "16px",
    width: "91%",
    color: "#353535"
});

const HeaderTxt = styled(Typography)({
    fontSize: "30px",
    color: "#3F3F3F",
    fontFamily: "Inter",
    fonSize: "36px",
    fontWeight: 700,
    lineHeight: "44px",
    letterSpacing: "0px",
    textAlign: "left"
});

//Customizable Area End
// Customizable Area End