import { IBlock } from 'framework/src/IBlock';
import { BlockComponent } from 'framework/src/BlockComponent';
import { runEngine } from 'framework/src/RunEngine';
// Customizable Area Start
import MessageEnum, { getName } from '../../../framework/src/Messages/MessageEnum';
import { Message } from "../../../framework/src/Message";
import storage from 'framework/src/StorageProvider.web';
// Customizable Area End
export const webConfigJSON = require('./config.js');

export interface Props {
    navigation: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    activeButton: string;
    age: string;
    open: boolean;
    milestoneDropdownData: any;
    date: string;
    authToken: string;
    activeTab: any
    activeDetailsData: any;
    uploadedFileName: any; 
    milestoneupdate: boolean;
    selectMilestones: string | unknown;
    value: any
    details: string
    amount: string
    isOpenModal: boolean;
    comment: string;
    status: string;
    succesMessage: string;
    commentErrorMessage: string | null,
    amountErrorMessage:string | null,
    statusErrorMessage: string | null, 
    detailsErrorMessage: string | null,
    selectMilestonesErrorMessage: string|null,
    milestoneDetailsData: any
    message: string

    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export default class FreelancerRaiseDisputesController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    contractDetailsId: string = ""
    updateRaiseDisputesID: string = ""
    milestoneDetailsId: string = ""
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
        // Customizable Area Start

        this.state = {
            open: false,
            date: "",
            activeButton: "offers",
            authToken: '',
            activeTab: 0,
            uploadedFileName: '',
            activeDetailsData: [],
            milestoneDetailsData: [],
            message: '',
            selectMilestones: "",
            isOpenModal: false,
            age: "",
            amount: '',
            details: '',
            value: '',
            succesMessage: '',
            commentErrorMessage: null,
            amountErrorMessage: null,
            statusErrorMessage: null,
            detailsErrorMessage: null,
            selectMilestonesErrorMessage: null,
            comment: '',
            status: '',
            milestoneupdate: false,
            milestoneDropdownData: [
                {
                    attributes: {
                        fullName: "Milestone 1",
                    },
                    id: 1,
                },
                {
                    attributes: {
                        fullName: "Milestone 2",
                    },
                    id: 2,
                },
                {
                    attributes: {
                        fullName: "Milestone 3",
                    },
                    id: 3,
                },
            ],
        };

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area End
    }

    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start
        this.setState({
            authToken: await storage.get('authToken'),
        });
        
        this.getActiveContractDetails()
        this.getActiveMilestoneDetails()

        // Customizable Area End
    }



    // Customizable Area Start
    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {

            // return;
        }

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const webApiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let webResponseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            this.handleActiveContractRes(webResponseJson, webApiRequestCallId)
            this.handleMilestoneContractRes(webResponseJson, webApiRequestCallId)
            this.handleupdateRaiseDisputesContractRes(webResponseJson, webApiRequestCallId)
        }
        // Customizable Area End
    }
    // Customizable Area End

    // Customizable Area Start

    handleActiveContractRes = (webResponseJson: { data: any; error: 'string' } | undefined, webApiRequestCallId: string | undefined) => {
        if (webResponseJson) {
            if (webApiRequestCallId === this.contractDetailsId) {
                if (webResponseJson?.data) {
                    this.setState({ activeDetailsData: webResponseJson?.data })
                }
            }
        }
    }

    handleChanges = (event: any) => {
        this.setState({
            date: event.target.value as string
        })
    };
    handleupdateRaiseDisputesContractRes = (webResponseJson: { data: any; error: 'string',message: string } | undefined, webApiRequestCallId: string | undefined) => {
        if (webResponseJson) {
            if (webApiRequestCallId === this.updateRaiseDisputesID) {
                if (webResponseJson?.message) {
                    this.setState({ 
                        isOpenModal: true,
                        message: webResponseJson?.message,
                        amount: '',
                        details: '',
                        comment: '',
                        status: '',
                        selectMilestones: ''
                    })
                }
                else{
                    this.setState({ 
                        isOpenModal: true,
                        message: webResponseJson?.error
                     })
                }
            }
        }
    }
    
    handleMilestoneContractRes = (webResponseJson: { data: any; error: 'string' } | undefined,webApiRequestCallId: string | undefined) =>{
        if (webResponseJson) {
        if (webApiRequestCallId === this.milestoneDetailsId) {
              if(webResponseJson?.data){
                this.setState({ milestoneDetailsData: webResponseJson?.data })
              }
                         }
          }
      }

 
    handleOpen = () => {
        this.setState({ open: true });
    };

    handleStatusChange = (event: { target: { value: string } }) => {
        const value = event.target.value.trim();
        let statusErrorMessage = null;
    
        if (!value) {
            statusErrorMessage = "Please enter a status";
        } else if (value.length > 20) {
            statusErrorMessage = "Status must not exceed 20 characters";
        }
    
        this.setState({
            status: value,
            statusErrorMessage,
        });
    }

    handleCommentChange = (event: { target: { value: string } }) => {
        const value = event.target.value.trim();
        let commentErrorMessage = null;
    
        if (!value) {
            commentErrorMessage = "Please enter a comment";
        } else if (value.length < 20) {
            commentErrorMessage = "Comment must be at least 20 characters long";
        }
    
        this.setState({
            comment: value,
            commentErrorMessage,
        });
    };

    handleAmountChange = (event: { target: { value: string } }) => {
        const value = event.target.value.trim();
        if (/^\d+(\.\d+)?$/.test(value)) {
            this.setState({
                amount: value,
                amountErrorMessage:
                    value === "" || parseFloat(value) <= 0
                        ? "Amount must be a positive number."
                        : null,
            });
        } else {
            this.setState({
                amountErrorMessage: "Invalid amount format. Use numbers only.",
            });
        }
    };

    handleMilestoneChange = (event: any) => {
        const selectedId = event.target.value;
        const { milestoneDetailsData } = this.state;
    
        if (Array.isArray(milestoneDetailsData)) {
            const selectedMilestone = milestoneDetailsData.find(
                (milestone: any) => milestone.attributes.id === selectedId
            );
    
            this.setState({
                selectMilestones: selectedId,
                amount: selectedMilestone?.attributes.amount || "",
            });
        } 
    };
    
    handleDetailsChange = (event: { target: { value: string } }) => {
        const value = event.target.value.trim();
        let detailsErrorMessage = null;
    
        if (!value) {
            detailsErrorMessage = "Please enter details"; 
        } else if (value.length < 20) {
            detailsErrorMessage = "Details must be at least 20 characters long"; 
        }
    
        this.setState({
            details: value,
            detailsErrorMessage,
        });
    };
    

    handleDropdownChange = (event: { target: { value: string } }) => {
        const value = event.target.value;
        this.setState({
            selectMilestones: value,
            selectMilestonesErrorMessage: value
                ? null
                : "Please select a milestone.",
        });
    };

    handleFileUpload = (event: any) => {
        const file = event.target.files[0];
        if (file) {
            this.setState({ uploadedFileName: file.name });
        }
    };

    validateForm = () => {
        console.log(this.state.selectMilestones,"selectMilestones")
        const { comment, amount, details, selectMilestones, status } = this.state;
        let isValid = true;

        if(!status){
            this.setState({ statusErrorMessage: "Please enter a status." });
            isValid = false;
        }

        if (!comment) {
            this.setState({ commentErrorMessage: "Please enter a comment." });
            isValid = false;
        }

        if (!amount || parseFloat(amount) <= 0) {
            this.setState({
                amountErrorMessage: "Amount must be a positive number.",
            });
            isValid = false;
        }

        if (!details) {
            this.setState({ detailsErrorMessage: "Please enter a details." });
            isValid = false;
        }

        if (!selectMilestones) {
            this.setState({
                selectMilestonesErrorMessage: "Please select a milestone.",
            });
            isValid = false;
        }

        return isValid;
    };
    handleCloseModal = () => {
        this.setState({ isOpenModal: false })
    }

    handleInputChangeS = (event: any) => {
        this.setState({ value: event.target.value });
    };
    handleSubmit = () => {
        if (this.validateForm()) {
            this.handleUpdateRaiseDisputes();
        }
    };
    
    getActiveContractDetails = async () => {

        const header = {
            "Content-Type": webConfigJSON.validationApiContentType,
            token: this.state.authToken
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.contractDetailsId = requestMessage.messageId;
        const paramId = this.props.navigation.getParam("id")

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_contract/contracts/contract_details?id=${paramId}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    };
    
  

    getActiveMilestoneDetails = async () => {
        const header = {
            token: this.state.authToken,
            "Content-Type": webConfigJSON.validationApiContentType,
        }
    
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        )
    
        const paramId = this.props.navigation.getParam("id")
        this.milestoneDetailsId = requestMessage.messageId
    
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_contract/milestones/get_proposal_milestones?proposal_generation_id=${paramId}`
        )

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );
    
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
    
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true
    };

    handleUpdateRaiseDisputes = () => {
        const paramId = this.props.navigation.getParam("id")
      
        const formData = new FormData();
        formData.append('refund[post_id]', this.state.activeDetailsData?.attributes?.post_attributes?.data?.attributes?.id);
        formData.append('refund[proposal_generation_id]', paramId);
        formData.append('refund[given_to_id]', this.state.activeDetailsData?.attributes?.client_details?.client_id);
        formData.append('refund[milestone_id]', JSON.stringify(this.state.selectMilestones));
        formData.append('refund[details]', this.state.details);
        formData.append('refund[comment]', this.state.comment);
        formData.append('refund[status]', this.state.status);

        const header = { token: this.state.authToken };
      
        const submitContracts = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.updateRaiseDisputesID = submitContracts.messageId;
      
        submitContracts.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `bx_block_refundmanagement/refunds/refund_freelancer_side`
        );
        submitContracts.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        submitContracts.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);
        submitContracts.addData(getName(MessageEnum.RestAPIRequestMethodMessage), 'POST');
      
        runEngine.sendMessage(submitContracts.id, submitContracts);
      };

    handleTabChange = (event: any, newValue: number) => {
        this.setState(
            { activeTab: newValue });
    };


    // Customizable Area End
}

// Customizable Area Start
// Customizable Area End  
