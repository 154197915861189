import React from "react";
// Customizable Area Start
import {
  Box,
  Button,
  Chip,
  Modal,
  TextField,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Grid,
  InputAdornment,
  Card,
  CardContent,
  styled,
} from "@material-ui/core";
import { edit } from "./assets";
import JobReviewPageController, {
  Props,
  webConfigJSON
} from "./JobReviewPageController";
import ClientNavBar from "./Components/ClientNavBar.web";
import FooterWeb from "./Components/Footer.web";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { green } from "@material-ui/core/colors";


const CustomButton = styled(Button)({
  background: "#364BA0",
  fontSize: "14px",
  lineHeight: "19px",
  letterSpacing: "0px",
  color: "white",
  borderRadius: "10px",
  width: " 136px",
  height: " 56px",
  marginLeft:"2rem",
  fontFamily:"Rubik",
  fontWeight:"bold",
  cursor:"pointer",
  textTransform:"inherit",
  '&:hover': {
    width: " 136px",
    height: "56px",
    borderRadius: "10px",
    color:"#364BA0",
    border:"1px solid #364BA0",
    backgroundColor: "#fff",
  }
})
// Customizable Area End

export default class JobReviewPage extends JobReviewPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { jobReviewData } = this.state;
    return (
      //Merge Engine DefaultContainer
      <>
        <ClientNavBar />
        <Box style={webStyles.mainBox}>
          <Typography style={webStyles.jobDetailsHeading}>
            Job details
          </Typography>
          <Grid container spacing={3} style={webStyles.jobReviewContainer}>
            <Grid container item xs={12} justifyContent="space-between" alignItems="center">
              <Typography style={webStyles.headings}>
                {jobReviewData.attributes.name}
              </Typography>
              <img
                src={edit}
                style={webStyles.editImage}
                onClick={this.handleOpenPopup}
                alt="Edit Title"
              />
            </Grid>
            <Grid item xs={12}>
              <div style={webStyles.divider} />
            </Grid>
            <Grid container item xs={12} justifyContent="space-between" alignItems="center">
              <Typography style={webStyles.descriptionStyle}>
                {jobReviewData.attributes.description}
              </Typography>
              <img
                src={edit}
                style={webStyles.editImage}
                onClick={this.handleOpenDescriptionPopup}
                data-test-id="openDescriptionPopup"
              />
            </Grid>
            <Grid item xs={12}>
              <div style={webStyles.divider} />
            </Grid>
            <Grid container item xs={12} justifyContent="space-between" alignItems="center">
              <Grid item>
                <Typography style={webStyles.subHeadings}>Skills</Typography>
                <Typography style={webStyles.skillsStyle}>
                  {jobReviewData.attributes.skills}
                </Typography>
              </Grid>
              <img
                src={edit}
                style={webStyles.editImage}
                onClick={this.handleOpenSkillsPopup}
                alt="Edit Skills"
                data-test-id="handleOpenSkillsPopup"
              />
            </Grid>
            <Grid container item xs={12} justifyContent="space-between" alignItems="center">
              <Grid item>
                <Typography style={webStyles.subHeadings}>Scope</Typography>
                <Typography style={webStyles.subSubHeading}>
                  {jobReviewData.attributes.estimate}, {jobReviewData.attributes.project_length}
                </Typography>
              </Grid>
              <img
                src={edit}
                style={webStyles.editImage}
                onClick={this.handleOpenScopePopup}
                alt="Edit Scope"
                data-test-id="handleOpenScopePopup"
              />
            </Grid>
            <Grid container item xs={12} justifyContent="space-between" alignItems="center">
              <Grid item>
                <Typography style={webStyles.subHeadings}>Budget</Typography>
                <Typography style={webStyles.subSubHeading}>
                  $ {jobReviewData.attributes.per_day_charge}/ {"project"}
                </Typography>
              </Grid>
              <img
                src={edit}
                style={webStyles.editImage}
                onClick={this.handleOpenBudgetPopup}
                alt="Edit Budget"
              />
            </Grid>
            <Grid container item xs={12}>
                <Typography style={webStyles.subHeadings}>
                Category of Patent
                </Typography>
                <Typography style={webStyles.subSubHeading}>
                {jobReviewData.attributes.patents.map(
              (
                patent: {
                  id: any;
                  categories: {
                    name: React.ReactNode;
                    sub_categories: Array<{
                      name: string;
                      sub_category_menus: Array<{
                        title: string;
                        sub_category_sub_menus: Array<{
                          title: string;
                          description: string;
                        }>;
                      }>;
                    }>;
                  };
                },
                patentIndex: number
              ) => (
                <Grid container direction="column" key={patent.id || patentIndex}>
                    <Typography style={webStyles.subSubHeading}>
                      {patent.categories.name}
                    </Typography>
                    <Typography style={webStyles.subSubHeading}>
                      {patent.categories.sub_categories
                        .map(subCategory => subCategory.name)
                        .join(", ")}
                    </Typography>
                    {patent.categories.sub_categories.map((subCategory, subCatIndex) => (
                      <Box key={subCatIndex} 
                      style={webStyles.subSubHeading}
                      >
                        <Typography style={webStyles.subSubHeading}>
                          {subCategory.sub_category_menus
                            .map(menu => menu.title)
                            .join(", ")}
                        </Typography>
                        {subCategory.sub_category_menus.map((menu, menuIndex) => (
                          <Box key={menuIndex} 
                          >
                            <Typography 
                            style={webStyles.subSubHeading}
                            >
                              {menu.sub_category_sub_menus
                                .map(subMenu => `${subMenu.title} (${subMenu.description})`)
                                .join(", ")}
                            </Typography>
                          </Box>
                        ))}
                      </Box>
                    ))}
                </Grid>
              )
            )}
                </Typography>
              <img
                src={edit}
                style={webStyles.editImage}
              />
            </Grid>
            <Grid container item xs={12} justifyContent="space-between" alignItems="center">
              <Grid item>
                <Typography style={webStyles.subHeadings}>Attachments</Typography>
                 <Box style={{display:"flex",gap:"10px"}}>
                 {jobReviewData?.attributes.files && jobReviewData.attributes.files.length > 0? (
                    jobReviewData.attributes.files.map((file: any, index: number) => (
                    <Box
                      key={index}
                      style={webStyles.attactmentStyle}>
                        {file.name}
                    </Box>
                  ))
                ) : (
                  <Typography>No files attached</Typography>
                )}
              </Box>
              </Grid>
              <img
                src={edit}
                style={webStyles.editImage}
              />
            </Grid>
            
            <Grid container item xs={12} justifyContent="space-between" style={webStyles.buttonContainer}>
                <Button data-test-id="handleSaveAsDraft" style={webStyles.saveDraftBtn} onClick={this.handleSaveAsDraft}>
                Save as a draft
                </Button>
                <CustomButton data-test-id="postJobTest" onClick={this.handlePostJob}>
                 Post this job
                </CustomButton>
            </Grid>
          </Grid>
        </Box>
        <FooterWeb />
        
         {/* Title Popup Modal */}
        <Modal
          open={this.state.isPopupOpen}
          onClose={this.handleClosePopup}
        >
          <Box style={webStyles.modalStyle}>
            <Typography style={webStyles.EditHeadings}>Edit your job title</Typography>
            <TextField
             variant="outlined"
             data-test-id="titleInput"
              value={jobReviewData.attributes.name}
              onChange={this.handleTitleChange}
              style={webStyles.searchTextFiled}
            />
            <Box style={webStyles.modalButtons}>
              <Button data-test-id="closePopupButton" onClick={this.handleClosePopup}>Cancel</Button>
              <Button onClick={this.handleSave} variant="contained" style={webStyles.postBtn} data-test-id="saveButton">Save</Button>
            </Box>
          </Box>
        </Modal>

         {/* Description Popup Modal */}
         <Modal
          open={this.state.isDescriptionPopupOpen}
          onClose={this.handleCloseDescriptionPopup}
        >
          <Box style={webStyles.modalStyle}>
            <Typography style={webStyles.EditHeadings}>Edit your job description</Typography>
            <TextField
              variant="outlined"
              value={jobReviewData.attributes.description}
              onChange={this.handleDescriptionChange}
              style={webStyles.searchTextFiled}
              data-test-id="descriptionInput"
              multiline
              rows={4}
            />
            <Box style={webStyles.descriptionFieldbtn}>
              <Button onClick={this.handleCloseDescriptionPopup} data-test-id="closeDescriptionPopup">Cancel</Button>
              <Button onClick={this.handleSaveDescription} variant="contained" style={webStyles.postBtn} data-test-id="saveDescriptionButton">Save</Button>
            </Box>
          </Box>
        </Modal>

         {/* skills Popup Modal */}
         <Modal open={this.state.isSkillsPopupOpen} onClose={this.handleCloseSkillsPopup}>
          <Box style={webStyles.modalStyle}>
            <Typography style={webStyles.EditHeadings}>Edit your skills</Typography>
            <Autocomplete
              options={this.state.skillsOption}
              value={jobReviewData.attributes.skills}
              data-testid = "skillset"
              onChange={this.handleSkillMenuInputChange}
              inputValue={this.state.skillInputValue}
              onInputChange={this.handleInputChange}
              multiple
              renderInput={(params) => <TextField {...params} variant="outlined" />}
            />
            <Box style={webStyles.modalButtons}>
              <Button onClick={this.handleCloseSkillsPopup} data-test-id="handleCloseSkillsPopup">Cancel</Button>
              <Button onClick={this.handleSaveSkills} variant="contained" style={webStyles.postBtn} data-test-id="handleSaveSkills" >Save</Button>
            </Box>
          </Box>
        </Modal>

         {/* scope Popup Modal */}
        <Modal
          open={this.state.isScopePopupOpen}
          onClose={this.handleCloseScopePopup}
        >
          <Box style={webStyles.modalStyle}>
            <Typography style={webStyles.EditHeadings}>Edit your job scope</Typography>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                value={jobReviewData.attributes.estimate}
                name="radio-buttons-group"
                onChange={this.handleScopeChange}
                data-test-id="handleScopeChange"
              >
                <FormControlLabel
                  value="large"
                  control={<Radio style={{ color: "#206FC4" }} />}
                  label="Large"
                />
                <Typography style={webStyles.secgridTypo as React.CSSProperties}>
                  Longer term or complex initiatives (e.g., develop and execute a brand strategy)
                </Typography>
                <FormControlLabel
                  value="medium"
                  control={<Radio style={{ color: "#206FC4" }} />}
                  label="Medium"
                />
                <Typography style={webStyles.secgridTypo as React.CSSProperties}>
                  Well-defined projects (e.g., design a business rebrand package)
                </Typography>
                <FormControlLabel
                  value="small"
                  control={<Radio style={{ color: "#206FC4" }} />}
                  label="Small"
                />
                <Typography style={webStyles.secgridTypo as React.CSSProperties}>
                  Quick and straightforward tasks (e.g., create a logo for a new product)
                </Typography>
              </RadioGroup>
              <Typography style={webStyles.howwillTxt as React.CSSProperties}>
                How long will your work take?
              </Typography>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                value={jobReviewData.attributes.project_length}
                name="radio-buttons-group"
                onChange={this.handleDurationChange}
                data-test-id="handleDurationChange"
              >
                <FormControlLabel
                  value="less than 1 month"
                  control={<Radio style={{ color: "#206FC4" }} />}
                  label="Less than 1 month"
                />
                <FormControlLabel
                  value="1 to 3 months"
                  control={<Radio style={{ color: "#206FC4" }} />}
                  label="1 to 3 months"
                />
                <FormControlLabel
                  value="3 to 6 months"
                  control={<Radio style={{ color: "#206FC4" }} />}
                  label="3 to 6 months"
                />
                <FormControlLabel
                  value="6 to 12 months"
                  control={<Radio style={{ color: "#206FC4" }} />}
                  label="6 to 12 months"
                />
                <FormControlLabel
                  value="more than 12 months"
                  control={<Radio style={{ color: "#206FC4" }} />}
                  label="more than 12 months"
                />
              </RadioGroup>
            </FormControl>
            <Box style={webStyles.modalButtons}>
              <Button onClick={this.handleCloseScopePopup} data-test-id="handleCloseScopePopup">Cancel</Button>
              <Button onClick={this.handleSaveScope} variant="contained" style={webStyles.postBtn} data-test-id="handleSaveScope">Save</Button>
            </Box>
          </Box>
        </Modal>

         {/* budget Popup Modal */}
         <Modal
         data-test-id="modalCloseTest"
          open={this.state.isBudgetPopupOpen}
          onClose={this.handleCloseBudgetPopup}
        >
          <Box style={webStyles.modalStyle}>
            <Typography style={{ ...webStyles.EditHeadings, marginBottom: "10px" }}>Edit your budget</Typography>
            <Box style={{width:"100%" }}>
                <Box style={webStyles.budgetBox}>
                  <label style={webStyles.labelText}>
                    Project Budget:
                  </label>
                  <Box style={webStyles.inputBox}>
                    <TextField
                      onKeyDown={this.handleKeyDown}
                      variant="outlined"
                      data-test-id="txtInputBudget"
                      style={webStyles.input}
                      id="outlined-adornment-amount"
                      value={jobReviewData.attributes.per_day_charge}
                      onChange={this.handleBudgetAmountChange}
                      placeholder="$0"
                      inputProps={{ maxLength: 10, style: { textAlign: "end" } }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                </Box>
            </Box>
            <Box style={webStyles.modalButtons}>
              <Button onClick={this.handleCloseBudgetPopup}>Cancel</Button>
              <Button data-test-id="saveBudgetTest" onClick={this.handleSaveBudget} variant="contained" style={webStyles.postBtn}>Save</Button>
            </Box>
          </Box>
        </Modal>
        {/* Success Popup Modal */}
        <Modal data-test-id="closeSuccesspopup" open={this.state.isSuccessPopupOpen} onClose={this.handleCloseSuccessPopup}>
          <Box style={{...webStyles.modalStyle,width:500,padding:"50px"}}>
            <Typography style={{...webStyles.EditHeadings,marginBottom:"8px"}}>Congratulations!!</Typography>
            <Typography style={{...webStyles.EditHeadings, marginBottom:"36px"}}>You have successfully posted the job.</Typography>
            <Box style={{display:"flex",justifyContent:"center"}}>
            <Button onClick={this.handleCloseSuccessPopup} style={{...webStyles.postBtn,width:"200px",padding:"5px"}}>
              Ok
            </Button>
            </Box>
          </Box>
        </Modal>

        <Modal data-test-id="closeDraftSuccesspopup" open={this.state.isSuccessDraftPopupOpen} onClose={this.handleCloseSaveDraftSuccessPopup}>
          <Box style={{...webStyles.modalStyle,width:500,padding:"50px"}}>
            <Typography style={{...webStyles.EditHeadings, marginBottom:"36px", display:"flex",justifyContent:"center"}}>You have successfully Drafted the job.</Typography>
            <Box style={{display:"flex",justifyContent:"center"}}>
            <Button onClick={this.handleCloseSaveDraftSuccessPopup} style={{...webStyles.postBtn,width:"200px",padding:"5px"}}>
              Ok
            </Button>
            </Box>
          </Box>
        </Modal>
      </>

      //Merge Engine End DefaultContainer
    );
    
    // Customizable Area End
  }
}

// Customizable Area Start

const webStyles = {
  headings: {
    fontSize: "24px",
    color: "#000000",
    fontWeight: 500,
    padding: "24px 0px 8px 24px",
    fontFamily:"Rubik"
  },
  subHeadings:{
    fontSize: "20px",
    padding: "0",
    marginBottom:"6px",
    color: "#000000",
    fontWeight: 600,
    fontFamily:"Rubik"

  },
  descriptionStyle:{
    fontSize:"18px",
    color: "#000000",
    padding: "18px 0px 8px 24px",
    fontFamily:"Rubik",
    whiteSpace: "pre-wrap" as 'pre-wrap', 
    overflow: "hidden" as 'hidden',  
    textOverflow: "ellipsis" as 'ellipsis', 
    maxHeight: "100px",  
    overflowY: "auto" as 'auto',
    width:"1000px"
  },
  skillsWrapper: {
    display: 'flex',
    flexDirection:"column" as 'column',
    marginLeft:"24px",
    marginTop:"28px"
  },
  skillsStyle:{
    fontSize:"18px",
    border:"1px solid #000000",
    borderRadius:"18px",
    display:"flex",
    justifyContent:"center" as 'center',
    padding:"2px 16px",
    textAlign: 'center' as 'center',
    fontFamily:"Rubik"
  },
  subSubHeading:{
    fontSize:"18px",
    fontFamily:"Rubik"
  },
  jobDetailsHeading: {
    fontSize: "30px",
    fontFamily: "Rubik",
    fontWeight: 600,
    marginTop: "32px",
    marginBottom:"40px",
    maxWidth: '95%',
    margin: '50px auto'
  },
  headingsContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  editImage: {
    alignSelf: "center",
    marginLeft: "auto",
    marginRight:"24px",
    marginTop:"8px",
    cursor: "pointer",
    width:"24px",
    height:"24px"
  },
  divider: {
    margin: "20px 0px",
    border: "1px solid #959595",
  },
  jobReviewContainer: {
    border: "1px solid #959595",
    borderRadius: "20px",
    maxWidth: '95%',
    margin: 'auto'
  },
  mainBox:{
    width: "100%",
    maxWidth: "1280px",
    margin: "auto",
  },
  saveDraftBtn:{
    fontSize : "16px",
    color:"#364BA0",
    fontWeight:600,
    textTransform: "inherit" as "inherit",
    fontFamily:"Rubik"
  },
  buttonContainer:{
    display: "flex",
    justifyContent: "end",
    padding: "30px"
},
postBtn:{
    background: "#364BA0",
color: "white",
fontSize: "14px",
fontWeight: 600,
textTransform: "inherit" as "inherit",
marginLeft: "10px",
padding: "10px",
borderRadius: "10px",
fontFamily:"Rubik"

},
modalStyle: {
    position: 'absolute' as 'absolute' ,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    backgroundColor: 'white',
    padding: '20px',
    boxShadow: "24",
    borderRadius: '10px',
    fontFamily:"Rubik"
  },
  modalButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '10px',
  },
  descriptionFieldbtn:{
      marginTop: '90px',
      justifyContent: 'flex-end',
      display: 'flex',
  },
  searchTextFiled: {
    width: "100%",
    maxHeight: "56px",
    borderRadius: "4rem",
    marginTop: "5px",
  },
  autoCompleteField:{
    width:500,
    fontWeight:700,

    "& .MuiAutocomplete-input":{
      fontWeight: 700
    }
  },
  examplesDiv1: {
    marginTop: "30px",
  },
  examplesText: {
    fontSize: "20px",
    color: "#535353",
    lineHeight: "30px",
    fontFamily: "Rubik",
    fontWeight: 700
  },

  examplesDiv2: {
    marginTop: "5px",
    display: "contents",
    flexWrap: "wrap",
  },
  examplesItems: {
    backgroundColor: "#D2E2F3",
    borderRadius: "23px",
    marginTop: "10px",
    marginRight: "15px",
    fontSize: "14px",
    color: "#206FC4",
    border: "none",
    padding: "5px",
    fontFamily: "Rubik",
  },
  secgridTypo: {
    marginLeft: "10px",
    fontFamily: "Rubik",
    fontSize: "16px",
    lineHeight: "30px",
    letterSpacing: "0px",
    textAlign: "left",
    color: "#000000",
    width: "523px",
  },
  howwillTxt: {
    fontSize: "16px",
    lineHeight: "35px",
    fontWeight: "bold",
    letterSpacing: "0px",
    textAlign: "left",
    color: "#000000",
    marginTop: "1rem",
    fontFamily: "Rubik",
  },
  selectBox: {
    marginTop: "2%",
    textAlign: "center" as "center"
  },
  inputBox: {
    display: "flex",
    alignContent: "center",
    alignItems: "center",
    justifyContent: "start",
    gap: "3px"
  },
  input: {
    width: "100%"
  },
  budgetBox: {
    marginTop: "15px",
    display: "grid"
  },
  labelText: {
    textAlign: "initial" as "initial",
    marginBottom: "1%",
    fontFamily: "Rubik",
    fontSize: "19px",
    fontWeight: 600,
    lineHeight: "22.99px",

  },
  afterSelect: {
    color: "#fff"
  },
  beforeSelect: {
    color: "#000"
  },
  budgetSelect: {
    color: "#fff",
    fontSize: "0.7rem"
  },
  budgetUnselect: {
    color: "#000",
    fontSize: "0.7rem"
  },
  cardContainer: {
    marginRight: "12px",
    padding: "15% 0 0",
    position: "relative" as "relative",
    borderRadius: "5px",
    cursor: "pointer",
    textAlign: "start" as "start",
    maxWidth: "100%"
  },
  radioInput: {
    position: "absolute" as "absolute",
    right: "10",
    top: "8",
    color: green[500]
  },
  EditHeadings:{
    fontSize:"20px",
    fontWeight:600,
    fontFamily:"Rubik",
    color:"#515354"
  },
  attactmentStyle:{
    display:"flex",
    alignItems:"center",
    border:"1px solid #206FC4",
    borderRadius:"8px",
    maxWidth:"200px",
    overflow:"hidden" as "hidden",
    background: "#206FC4",
    color:"white",
    padding:"10px",
    fontFamily:"Rubik",
    fontWeight:400
  },
};
// Customizable Area End
