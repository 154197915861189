import { IBlock } from 'framework/src/IBlock';
import { Message } from 'framework/src/Message';
import { BlockComponent } from 'framework/src/BlockComponent';
import { runEngine } from 'framework/src/RunEngine';
import moment from 'moment';

// Customizable Area Start
import MessageEnum, { getName } from 'framework/src/Messages/MessageEnum';
import storage from 'framework/src/StorageProvider';
export interface ActiveProposalData{
  data: {
      id: string,
      type: string,
      attributes: {
          active_proposals: {
              active_proposal_count: number,
              last_received_invitation_date: string,
              days_ago: string,
              active_proposals: {
                  data: [
                      {
                          id: string,
                          type: string,
                          attributes: {
                              id: number,
                              post_id: number,
                              created_at: string,
                              updated_at: string,
                              accepted_at: null,
                              rejected_at: null,
                              post: {
                                  data: {
                                      id: string,
                                      type: string,
                                      attributes: {
                                          id: number,
                                          name: string,
                                          description: string,
                                          created_at:string,
                                          updated_at: string,
                                          posted_job: number
                                      }
                                  }
                              },
                              days_ago: string,
                          }
                      }
                  ]
              },
              total_count: number
          }
      }
  }
}
export interface SubmittedProposalsData{
  data: {
      id: string,
      type: string,
      attributes: {
          submitted_proposals: {
              submitted_proposal_count: number,
              last_received_invitation_date: string,
              days_ago:string,
              submitted_proposals: {
                  data: [
                      {
                          id: string,
                          type: string,
                          attributes: {
                              id: number,
                              post_id: number,
                              created_at: string,
                              updated_at: string,
                              accepted_at: string,
                              rejected_at: null,
                              post: {
                                  data: {
                                      id: string,
                                      type: string,
                                      attributes: {
                                          id: number,
                                          name: string,
                                          description: string,
                                      }
                                  }
                              },
                              days_ago: string,
                          }
                      },
                  ]
              },
              total_count: number
          }
      }
  }
}

export interface InvitationToInterviewData{
  data: {
      id: string,
      type: string,
      attributes: {
          invitation_to_interview: {
              invitation_to_interview_count: number,
              last_received_invitation_date: string,
              days_ago: string,
              invitation_to_interviews: {
                  data: [
                      {
                          id: string,
                          type:string,
                          attributes: {
                              id: number,
                              post_id: number,
                              created_at: string,
                              updated_at: string,
                              accepted_at: null,
                              rejected_at: null,
                              post: {
                                  data: {
                                      id: string,
                                      type:string,
                                      attributes: {
                                          id: 19,
                                          name: string,
                                          description: string,
                                      }
                                  }
                              },
                              days_ago: string,
                          }
                      }
                  ]
              },
              total_count: number
          }
      }
  }
}
// Customizable Area End
export const webConfigJSON = require('./config.js');


export interface Props {
  navigation: any;
  // Customizable Area Start
  proposalType?:number|string
  activeProposalData:ActiveProposalData| null | any
  submittedProposalsData:SubmittedProposalsData|null
  invitationToInterviewData:InvitationToInterviewData|null
  handleOfferProposalProps?:any
  handleActiveProposalData?:any
  handleRejectProposalData?:any

// Customizable Area End
}
interface S {
  // Customizable Area Start
  activeButton: string
  content:any;
  authToken: string
  offerData:any;
  isDialogOpen: boolean
activeProposalData:ActiveProposalData |null|any
submittedProposalsData:SubmittedProposalsData|null
invitationToInterviewData:InvitationToInterviewData|null
rejectInterviewData : any[]
  viewInvitationData:{
    data: {
        id: string,
        type: string,
        attributes: {
            id: number,
            post_id: number,
            message: string,
            created_at: string,
            updated_at: string,
            post: {
                data: {
                    id: string,
                    type: string,
                    attributes: {
                        id: number,
                        name: string,
                        description: string,
                        body: string,
                        job_title: string,
                        job_description: string,
                        per_day_charge: string,
                        budget: string,
                        duration: string,
                        employment_term:string,
                        estimate:string,
                        skills: any[],
                        location: string,
                        account_id: number,
                        created_at: string,
                        updated_at: string,
                        proposals: number,
                        messaged: number,
                        hired: number,
                        is_drafted: boolean,
                        est_budget: number,
                        last_viewed_by_client: string,
                        rating: null,
                        intermediate: null,
                        model_name: string,
                        categorizations: [],
                        images_and_videos: [],
                        drafted_at: string,
                        posted_job: number
                    }
                }
            },
            days_ago: string,
            client_first_name: string
            client_last_name: string
        }
    }
}
archiveInterviewData:any
currentPage:number;
totalPages:number;
activeProposalCurrentPage:number,
activeProposalTotalPages:number;
noProposalsMessage: string;
loadingOffers: boolean; 
loadingActiveProposals: boolean; 
loadingSubmittedProposals: boolean; 
loadingInvitationToInterview: boolean; 
loadingViewInvitation: boolean; 
loadingArchiveInterview: boolean;
  // Customizable Area End
}
interface SS {
  // Customizable Area Start
// Customizable Area End
}

export default class FreelancerOffersController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  acceptProposalId:string
  rejectProposalId:string
  offersDataId:string
  proposalsDataId:string
  viewInvitationDataId:string
  archivedProposalsDataId:string
  activeProposalDataId:string
  rejectProposalDataId: string;
  submittedProposalDataId:string
  invitationToInterviewId:string
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    this.acceptProposalId=""
    this.rejectProposalId=""
    this.offersDataId=""
    this.proposalsDataId=""
    this.viewInvitationDataId=""
    this.archivedProposalsDataId=""
    this.activeProposalDataId=""
    this.rejectProposalDataId=""
    this.submittedProposalDataId=""
    this.invitationToInterviewId=""
    this.state = {
        activeProposalData:null,
        invitationToInterviewData:null,
        isDialogOpen: false,
          offerData:{
          offers: {
              data: [
                  {
                      id: "0",
                      type: "invitation_to_interview",
                      attributes: {
                          id: 0,
                          post_id: 0,
                          message: "",
                          post: {
                              data: {
                                  id: "0",
                                  type: "post",
                                  attributes: {
                                      id: 0,
                                      name: "",
                                      description: "",
                                      job_description: "",
                                      body: "",
                                      job_title: "",
                                      per_day_charge: "0",
                                      budget: "",
                                      employment_term: "",
                                      estimate: "",
                                      skills: [],
                                      location: "",
                                      file: {
                                          "url": null
                                      },
                                      account_id: 0,
                                      created_at: "",
                                      project_length: "",
                                      proposals: 0,
                                      weekly_hours: 0,
                                      experience_level: "",
                                      images_and_videos: [],
                                      file_name: "",
                                      country: {
                                          id: 0,
                                          name: "",
                                          code: "",
                                      },
                                      states: [],
                                      proposals_count: 0,
                                      invites_sent: 0,
                                      cities: [
                                          {
                                              id: 0,
                                              name: "",
                                              code: null
                                          },
                                      ],
                                  }
                              }
                          },
                      }
                  }
              ]
          },
      },
      submittedProposalsData:null,
      rejectInterviewData: [
        {
          "attributes": {
            "payment_type": "",
            "status": "",
            "post_attributes": {
                "data": {
                    "id": "",
                    "type": "",
                    "attributes": {
                        "id": 0,
                        "name": "",
                        "description": "",
                        "body": "",
                        "job_title": "",
                        "job_description": "",
                        "per_day_charge": "",
                        "budget": "",
                        "employment_term": "",
                        "estimate": "",
                        "skills": [],
                        "location": "",
                        "file": {
                            "url": ""
                        },
                        "account_id": 0,
                        "created_at": "",
                        "updated_at": "",
                        "est_budget": 0.0,
                        "rating": null,
                        "intermediate": null,
                        "project_length": "",
                        "proposals": 0,
                        "weekly_hours": null,
                        "experience_level": null,
                        "images_and_videos": [],
                        "file_name": "",
                        "file_type": "",
                        "country": null,
                        "states": [],
                        "proposals_count": 0,
                        "invites_sent": 0,
                        "cities": [],
                        "posted_job": 7,
                        "patents": []
                    }
                }
            },
            "proposal_attributes": {
                "hour_rate": null,
                "duration": "",
                "work_experience": null,
                "work_experience_description": null,
                "service_rate": null,
                "total_price_of_project": 0,
                "freelancer_service_fee": 0,
                "you_will_receive": 0,
                "project_budget": 0,
                "resume_document": null,
                "cover_letter": "",
                "submission_date": ""
            },
            "term": {
                "milestones_attributes": []
            }
        }
        }
      ],
        activeButton: "offers",
        content : [
          {
            days: "10 Days ago",
            detail: "I need web designer...",
            date: "Feb 25, 2023",
            amount: "$10.00",
            endDate: "04/04/20Start23",
            startDate: "04/04/2023",
          },
          {
            days: "10 Days ago",
            date: "Feb 25, 2023",
            detail: "I need web designer...",
            endDate: "04/04/2023",
            amount: "$10.00",
            startDate: "04/04/2023",
          },
          {
            amount: "$10.00",
            days: "10 Days ago",
            detail: "I need web designer...",
            startDate: "04/04/2023",
            date: "Feb 25, 2023",
            endDate: "04/04/2023",
          },
          {
            date: "Feb 25, 2023",
            startDate: "04/04/2023",
            endDate: "04/04/2023",
            detail: "I need web designer...",
            amount: "$10.00",
            days: "10 Days ago",
          },
          {
            days: "10 Days ago",
            startDate: "04/04/2023",
            detail: "I need web designer...",
            endDate: "04/04/2023",
            amount: "$10.00",
            date: "Feb 25, 2023",
          },
        ],
        authToken:"",   
        viewInvitationData:{
          data: {
              id: "",
              type: "",
              attributes: {
                  id: 0,
                  post_id: 0,
                  message: "",
                  created_at: "",
                  updated_at: "",
                  post: {
                      data: {
                          id: "",
                          type: "",
                          attributes: {
                              id: 0,
                              name: "",
                              description: "",
                              body: "",
                              job_title: "",
                              job_description: "",
                              per_day_charge: "",
                              budget: "",
                              duration: "",
                              employment_term: "",
                              estimate: "",
                              skills: [],
                              location: "",
                              account_id: 0,
                              created_at: "",
                              updated_at: "",
                              proposals: 0,
                              messaged: 0,
                              hired: 0,
                              is_drafted: false,
                              est_budget: 0.0,
                              last_viewed_by_client: "",
                              rating: null,
                              intermediate: null,
                              model_name: "",
                              categorizations: [],
                              images_and_videos: [],
                              drafted_at: "",
                              posted_job: 0
                          }
                      }
                  },
                  days_ago: "",
                  client_first_name: "",
                  client_last_name: ""
              }
          }
      },
      archiveInterviewData:{
        "archived_proposals": {
          "data": [
              {
                  "id": "",
                  "type": "",
                  "attributes": {
                      "id": 0,
                      "post_id": 0,
                      "message": "",
                      "post": {
                          "data": {
                              "id": "",
                              "type": "post",
                              "attributes": {
                                  "id": 0,
                                  "name": "",
                                  "description": "",
                                  "body": "",
                                  "job_title": "",
                                  "job_description": "",
                                  "per_day_charge": "",
                                  "budget": "",
                                  "employment_term": "",
                                  "estimate": "",
                                  "skills": [],
                                  "location": "",
                                  "file": {
                                      "url": null
                                  },
                                  "account_id": 0,
                                  "hired": 0,
                                  "rating": 0,
                                  "intermediate": null,
                                  "project_length": "",
                                  "proposals": 0,
                                  "weekly_hours": 0,
                                  "experience_level": "",
                                  "images_and_videos": [],
                                  "file_name": "",
                                  "file_type": null,
                                  "country": {
                                      "id": 0,
                                      "name": "",
                                      "code": "",
                                  },
                                  "states": [],
                                  "proposals_count": 0,
                                  "invites_sent": 0,
                                  "cities": [
                                      {
                                          "id": 1,
                                          "name": "",
                                          "code": null
                                      },
                                  ],
                                  "patents": []
                              }
                          }
                      },
                  }
              },
          ]
      },
      },
      currentPage:1,
      totalPages:0,
      activeProposalCurrentPage:1,
      activeProposalTotalPages:0,
      noProposalsMessage: '',
      loadingOffers: false,
      loadingActiveProposals: false,
      loadingSubmittedProposals: false,
      loadingInvitationToInterview: false,
      loadingViewInvitation: false,
      loadingArchiveInterview: false,
      };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.setState({
      authToken: await storage.get('authToken')
    });
    this.handleOffersData()
    this.handleActiveProposalData('active')
    this.handleSubmittedData('active')
    this.handleInvitationToInterviewData('active')
    this.handleInvitationToInterviewData('archived')
    this.handleRejectProposalData()
    // Customizable Area End
  }

  

  async receive(_from: string, _message: Message) {
    // Customizable Area Start
   this.handleAllApiResponse(_message)
    
    // Customizable Area End
  }
  // Customizable Area Start
  handleButtonClick = (buttonType: string) => {
    this.setState({ activeButton: buttonType });
  };

  handleAllApiResponse = (_message:Message) => {
    if (getName(MessageEnum.RestAPIResponceMessage) === _message.id) {
      const requestCallId = _message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = _message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
   
      if (responseJson ) {
         if (requestCallId===this.offersDataId){            
          this.setState({
            totalPages: Math.ceil(responseJson.total_count / 10),
            offerData: responseJson?.data?.attributes?.offers,
            loadingOffers: false 
          });
      } 
     else if (requestCallId === this.activeProposalDataId) {

      this.setState({
        activeProposalTotalPages: Math.ceil(responseJson.total_count / 10),
        activeProposalData: responseJson?.proposals?.data,
        loadingActiveProposals: false 
      });
      }
    else if (requestCallId===this.submittedProposalDataId){  
      this.setState({
        activeProposalTotalPages: Math.ceil(responseJson.total_count / 10),
        submittedProposalsData: responseJson,
        loadingSubmittedProposals: false 
      });
     }
    else if (requestCallId===this.invitationToInterviewId){  
      this.setState({
        activeProposalTotalPages: Math.ceil(responseJson.total_count / 10),
        invitationToInterviewData: responseJson,
        loadingInvitationToInterview: false 
      });
    } 
     }
    if (responseJson ) {
      if (requestCallId===this.viewInvitationDataId){   
        this.setState({
          viewInvitationData: responseJson,
        });
       this.props.navigation.navigate('InvitationToInterview',{id:responseJson.data.id})
     }    
   }
     this.handleRejectInterviewRes(responseJson,requestCallId)
     this.handleArchiveInterviewRes(responseJson,requestCallId)
     this.handleRejectProposalRes(responseJson,requestCallId)
    }
  }

  handleAcceptProposal = (responseJson: any,requestCallId: string) => {
    if (responseJson){
      if (requestCallId===this.acceptProposalId){ 
        this.setState({
          isDialogOpen: true
        })
      }
    }
  }

  handleRejectInterviewRes = (responseJson: { message: string; },requestCallId: string) => {
    if (responseJson){
      if (requestCallId===this.rejectProposalId){ 
        if (responseJson?.message ) {
          this.props.handleOfferProposalProps();
          this.props.handleActiveProposalData();
          this.props.handleRejectProposalData();
          this.handleOffersData()
          this.setState({
            isDialogOpen: true
          })
        } 
      }
    }
  }

  handleArchiveInterviewRes = (responseJson: { data: { attributes: { archived_proposal: any; }; }; },requestCallId: string | undefined) => {
    if(responseJson){
      if (requestCallId===this.archivedProposalsDataId){   
        this.setState({
          archiveInterviewData: responseJson?.data?.attributes?.archived_proposal,
          loadingArchiveInterview: false 
        });
        }
    }
  }

  handleRejectProposalRes = (responseJson: any,requestCallId: string | undefined) => {
    if(responseJson){
      if (requestCallId===this.rejectProposalDataId){   
        this.setState({
          rejectInterviewData: responseJson?.archived_proposals?.data,
        });
        }
    }
  }

  handleAccept=()=>{
    this.props.navigation.navigate("FreelancerStripeAccount")
  }
  handleVisitPage=(id:string)=>{
    const header = {
      "Content-Type": webConfigJSON.validationApiContentType,
      "token": this.state.authToken
    };
 
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.viewInvitationDataId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_posts/invitation_to_interviews/view_invitation?id=${id}`
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleRejectProposalData = () => {
    const header = {
      "Content-Type": webConfigJSON.validationApiContentType,
      token: this.state.authToken,
    };
  
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
  
    this.rejectProposalDataId = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_proposal_generation/proposal_generations/archived_list_proposals/`
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  
   handleReject = (index: number) => {
     const updatedContent = [...this.state.content];
    updatedContent.splice(index, 1);
    this.setState({ content:updatedContent });
  };  
   formatDate=(dateString: string)=> {
    return moment(dateString).format('MMMM Do, YYYY');
  }
  handlePageChange = (_event: object, page: number) => {
    this.setState({ currentPage: page }, () => {
      this.handleOffersData();
    });
  };
  handleActiveProposalPageChange = (_event: object, page: number) => {
    this.setState({ activeProposalCurrentPage: page }, () => {
      this.handleActiveProposalData('active');
    });
  };
  handleInvitationPageChange = (_event: object, page: number) => {
    this.setState({ activeProposalCurrentPage: page }, () => {
      this.handleInvitationToInterviewData('active');
    });
  };

  handleOffersData = () => {
    this.setState({ loadingOffers: true })
    const { currentPage } = this.state;
    const header = {
      "Content-Type": webConfigJSON.validationApiContentType,
      "token": this.state.authToken
    };
 
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.offersDataId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_posts/my_proposals/offers_details?type=active`
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  
  handleSubmittedPageChange = (_event: object, page: number) => {
    this.setState({ activeProposalCurrentPage: page }, () => {
      this.handleSubmittedData('active');
    });
  };
  
  
  acceptProposal = async (offerId: number) => {
    const acceptProposalResponse = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const { endpoint, header, method, body } = await this.acceptProposalBody(offerId);
    this.acceptProposalId = acceptProposalResponse.messageId;
    acceptProposalResponse.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint 
    );
    acceptProposalResponse.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    acceptProposalResponse.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method 
    );
    acceptProposalResponse.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    runEngine.sendMessage(acceptProposalResponse.id, acceptProposalResponse);
  };

  rejectProposal = async (offerId: number) => {
    const rejectProposalResponse = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    const { endpoint, header, method, body } = await this.rejectProposalBody(offerId);
  
    this.rejectProposalId = rejectProposalResponse.messageId;
    rejectProposalResponse.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint 
    );
    rejectProposalResponse.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    rejectProposalResponse.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    rejectProposalResponse.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
  
    runEngine.sendMessage(rejectProposalResponse.id, rejectProposalResponse);
  };
  
  rejectProposalBody = async (offerId: number) => {
    const header = {
      "Content-Type": 'application/json',
      "token": this.state.authToken,
    };
  
    const body = {
      "interview_id": offerId,
      "message": "I reject 121 job; this is not based on my skill. Please suggest something else."
    };
  
    return {
      endpoint: "bx_block_posts/invitation_to_interviews/reject_interview",
      header,
      method: "PUT", 
      body,
    };
  };
  
  acceptProposalBody = async (offerId: number) => {
    const header = {
      "Content-Type": 'application/json',
      "token": this.state.authToken,
    };
  
    const body = {
      "interview_id": offerId,
      "message": "I accept 121 job; this is not based on my skill. Please suggest something else."
    };
  
    return {
      endpoint: "bx_block_posts/my_proposals",
      header,
      method: "POST", 
      body,
    };
  };
  
  handleActiveProposalData = (proposalType: string) => {
    this.setState({ loadingActiveProposals: true }); 
    const { activeProposalCurrentPage } = this.state;
    const header = {
      "Content-Type": webConfigJSON.validationApiContentType,
      token: this.state.authToken,
    };
  
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
  
    this.activeProposalDataId = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_proposal_generation/proposal_generations`
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  handleSubmittedData = (proposalType: string) => {
    this.setState({ loadingSubmittedProposals: true }); // Set loading state to true
    const { activeProposalCurrentPage } = this.state;
    const header = {
      "Content-Type": webConfigJSON.validationApiContentType,
      token: this.state.authToken,
    };
  
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
  
    this.submittedProposalDataId = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_posts/my_proposals/submitted_proposals_details?type=${proposalType}&page=${activeProposalCurrentPage}&per_page=10`
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleCloseDialog = () => {
    this.setState({ isDialogOpen: false });
  };

  handleHome = () => {
    this.setState({ isDialogOpen: false });
    this.props.handleOfferProposalProps()
    this.props.handleActiveProposalData();
    this.props.handleRejectProposalData();
  }

  handleInvitationToInterviewData = (proposalType: string) => {
    this.setState({ loadingInvitationToInterview: true }); // Set loading state to true
    const { activeProposalCurrentPage } = this.state;
    const header = {
      "Content-Type": webConfigJSON.validationApiContentType,
      token: this.state.authToken,
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
  
    if(proposalType == "archived"){
      this.archivedProposalsDataId = requestMessage.messageId
      }else{
        this.invitationToInterviewId = requestMessage.messageId
      }
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_posts/my_proposals/archived_proposals`
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  
  // Customizable Area End
}
