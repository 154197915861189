import { IBlock } from '../../../framework/src/IBlock';
import { Message } from '../../../framework/src/Message';
import { BlockComponent } from '../../../framework/src/BlockComponent';
import MessageEnum, {
  getName
} from '../../../framework/src/Messages/MessageEnum';
import { runEngine } from '../../../framework/src/RunEngine';

// Customizable Area Start
import storage from "../../../framework/src/StorageProvider";

// Customizable Area End

export const configJSON = require('./config');

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  months: string;
  open: any;
  reason: any;
  savioServiceFee: any;
  projectBudget: any;
  youReceive: any;
  authToken:any;
  message:any;
  proposalId:any;
  uploadedResume:any;
  text: string;
  describeYourExp: any;
  jobId: any;
  submitProposalRes:object;
  files: any[];
  jobDetailsData:any;
  jobActiveDetailsData: any;
  milestones:any [],
  errors: any;
  selectedPaidOption: string,
  isDialogOpen: boolean,
  responseMessage: string;
  isBlocked: boolean
  archivedList: any
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class ViewSubmitProposalController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  withdrawProposalsId:any;
  submitProposalsId:any;
  fetchCurrentProposalsJobDetailsId:any
  getArchiveProposalDetailsId: any;
  getProposalDetailsId: any;
  getActiveProposalDetailsId: any;
  maxLength: any;

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.withdrawProposalsId="";
    this.submitProposalsId="";
    this.fetchCurrentProposalsJobDetailsId="";
    this.getArchiveProposalDetailsId="";
    this.getProposalDetailsId="";
    this.getActiveProposalDetailsId = "";
    this.maxLength = 400;
    this.state = {
      months: '',
      open: false,
      reason: '',
      savioServiceFee: '5',
      projectBudget: '',
      youReceive: '',
      authToken: '',
      message:"",
      proposalId:"24",
      uploadedResume:"",
      text: '',
      describeYourExp:"",
      jobId:"",
      submitProposalRes:{},
      files: [],
      jobActiveDetailsData :{
        "proposal"  : {
            "data"   : {
                "id": "0" ,
                "type": "" ,
                "attributes":   {
                  "payment_type":   "",
                    "post_attributes"   : {
                        "data"   : {
                          "type": "",
                          "id"   : "0" ,
                          "attributes"    : {
                              "skills": [],
                              "name": "" ,
                              "description" : "" ,
                              "body": "" ,
                              "job_description": "",
                              "id": 0,
                              "budget": "",
                              "job_title": "",
                              "employment_term": "",
                              "estimate": "",
                              "per_day_charge": "0",
                                "location": "",
                                "rating": null,
                                "intermediate": null,
                                "project_length": "",
                                "experience_level": null,
                                "proposals": 0,
                                "weekly_hours": null,
                                "account_id": 0,
                                "files": [] ,
                                "country": null,
                                "states": [] ,
                                "proposals_count": 0,
                                "invites_sent": 0,
                                "drafted_at": "",
                                "cities": [] ,
                                "patents": [] ,
                                "posted_job": 0,
                            }
                        }},
                    "proposal_attributes"  : {
                      "hour_rate"  : null ,
                      "work_experience": null,
                      "work_experience_description": null,
                      "total_price_of_project": 0,
                      "service_rate": null,
                      "cover_letter": "",
                      "duration": "" ,
                      "submission_date": "",
                        "freelancer_service_fee": 0,
                        "you_will_receive": 0,
                        "project_budget": 0 ,
                        "resume_documents": [] ,
                    },
                }}}},
      jobDetailsData:{
        "post": {
          "data": {
            "id": "610",
            "type": "post",
            "attributes": {
              "id": 610,
              "name": "savio-project ",
              "description": " sav project",
              "body": " wuedgfweficgweiygfyigweui",
              "job_title": "seo",
              "job_description": "uidfdeywdf",
              "per_day_charge": "0.01",
              "budget": "per_day_rate",
              "employment_term": "short_term_work",
              "estimate": "small",
              "skills": [
                "ww",
                "rr",
                "ff"
              ],
              "location": "india",
              "file": {
                "url": null
              },
              "account_id": 704,
              "created_at": "December 03, 2024 ● 03:34 PM",
              "updated_at": "2024-12-03T10:04:52.651Z",
              "messaged": 0,
              "hired": 0,
              "is_drafted": false,
              "est_budget": 100,
              "last_viewed_by_client": null,
              "rating": 4.5,
              "intermediate": null,
              "model_name": "BxBlockPosts::Post",
              "project_length": "6 to 12 months",
              "proposals": 0,
              "weekly_hours": 40,
              "experience_level": "Intermediate",
              "images_and_videos": [],
              "file_name": "No file attached",
              "file_type": null,
              "country": {
                "id": 188,
                "name": "Samoa",
                "code": "WS",
                "created_at": "2024-07-12T09:26:46.579Z",
                "updated_at": "2024-07-12T09:26:46.579Z",
                "dialing_code": null
              },
              "states": [],
              "proposals_count": 1,
              "invites_sent": 1,
              "cities": [
                {
                  "id": 3,
                  "name": "Alpine",
                  "code": null
                },
                {
                  "id": 4,
                  "name": "Amado",
                  "code": null
                }
              ],
              "drafted_at": "Not drafted",
              "posted_job": 2,
              "patents": []
            }
          }
        },
      }
      ,
      milestones: [
        { id: 1, description: '', date: '', amount: '', },
      ],
      errors: {},
      selectedPaidOption: 'milestone',
      isDialogOpen: false,
      responseMessage: '',
      isBlocked: false,
      archivedList: {
        "proposal": {
          "data": {
              "id": "0",
              "type": "",
              "attributes": {
                  "payment_type": "",
                  "status": "",
                  "post_attributes": {
                      "data": {
                          "id": "0",
                          "type": "",
                          "attributes": {
                              "id": 0,
                              "name": "",
                              "description": "",
                              "body": "",
                              "job_title": "",
                              "job_description": "",
                              "per_day_charge": "0",
                              "budget": "",
                              "employment_term": "",
                              "estimate": "",
                              "skills": [],
                              "location": "",
                              "account_id": 0,
                              "rating": null,
                              "intermediate": null,
                              "project_length": "",
                              "proposals": 0,
                              "weekly_hours": null,
                              "experience_level": null,
                              "files": [],
                              "country": null,
                              "states": [],
                              "proposals_count": 0,
                              "invites_sent": 0,
                              "cities": [],
                              "drafted_at": "",
                              "posted_job": 0,
                              "patents": []
                          }
                      }
                  },
                  "proposal_attributes": {
                      "hour_rate": null,
                      "duration": "",
                      "work_experience": null,
                      "work_experience_description": null,
                      "service_rate": null,
                      "total_price_of_project": 0,
                      "freelancer_service_fee": 0,
                      "you_will_receive": 0,
                      "project_budget": 0,
                      "resume_documents": [],
                      "cover_letter": "",
                      "submission_date": ""
                  },
              }
          }
      }
      }
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start

    this.setState({
      authToken: await storage.get("authToken"),
    })
    this.setState({jobId:await storage.get('currentProposalJobId')});
    this.fetchCurrentProposalsJobDetails()
    this.getArchiveProposalsDetails()
    this.getActiveProposalsDetails()
    this.getProposalsDetails()

    // Customizable Area End
  }

  // Customizable Area Start
  // Web Event Handling
  handleCloseDialog = () => {
    this.setState({
      isDialogOpen: false,
    });
  };
  handleHome = () => {
    this.setState({ isDialogOpen: false });
    this.props.navigation.navigate("FullWidthTabProposals")
  }
  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false,
      reason: '',
      message: '',
      isBlocked: false,
     });
  };

  handleProjectBudget = (event: { target: { value: string; }; }) => {
    const { value } = event.target;
    const numericRegex = /^\d+(?:\.\d*)?$/;
  
    if (numericRegex.test(value) || value === '') {
      const numericValue = parseFloat(value);
      const commission = isNaN(numericValue) ? 0 : numericValue * 0.05;
      const remainingAmount = isNaN(numericValue) ? '' : (numericValue - commission).toFixed(2);
  
      this.setState({
        projectBudget: value,
        youReceive: remainingAmount,
        errors: {
          ...this.state.errors,
          bid: '', 
        },
      });
    } else {
      this.setState({
        errors: {
          ...this.state.errors,
          bid: '*Please enter a bid amount',
        },
      });
    }
  }; 


  handleWithdrawProposals = () => {
    const paramId=this.props.navigation.getParam("id")
    const withdrawProposals = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.apiContentType,
     
    };

    const body = {
      proposals: {
        reason: this.state.reason,
        message: this.state.message,
        block_future_invitations: this.state.isBlocked
      },

      token: this.state.authToken
    };
    this.withdrawProposalsId = withdrawProposals.messageId;
    withdrawProposals.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.withdrawProposalsEndpoint}/${paramId}/freelancer_withdraw_proposal`
    );
    withdrawProposals.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    withdrawProposals.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    )
    withdrawProposals.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );
    runEngine.sendMessage(withdrawProposals.id, withdrawProposals);

  }
  handleResumeUpload = (event:any) => {
    const file = event.target.files[0]; 
    this.setState({ uploadedResume: file });
    
  };
  
  
  validateForm() {
    const errors: Record<string, any> = {};

    const coverLetterError = this.validateCoverLetter(this.state.message);
    if (coverLetterError) {
      errors.coverLetter = coverLetterError;
    }

    const reasonError = this.validateReason(this.state.reason);
    if (reasonError) {
        errors.reasonserror = reasonError;
      }

    this.setState({ errors });

    return Object.keys(errors).length === 0;
}

  validateCoverLetter(coverLetter:string) {
    if (!coverLetter.trim()) {
      return "*Please enter a message";
    }
    return "";
  }

  validateReason(reason:string) {
    if (!reason.trim()) {
      return "*Please select a reason";
    }
    return "";
  }
  
  handleSubmit = () => {
    if(this.validateForm()){
        this.handleWithdrawProposals()
    }
  }

  handleMessageChange = (e:any) => {
    const message = e.target.value;
    const coverLetterError = this.validateCoverLetter(message);
    this.setState({
        message,
      errors: {
        ...this.state.errors,
        coverLetter: coverLetterError,
      },
    });
  };


  handleReasonChange = (e:any) => {
    const reason = e.target.value;
    const reasonError = this.validateReason(reason);
    this.setState({
      reason,
      errors: {
        ...this.state.errors,
        reasonserror: reasonError,
      },
    });
  };
  
  getActiveProposalsDetails=()=>{
    const paramId = this.props.navigation.getParam("id")
    const currentProposalsDetails = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    ); 
    const header = {
      "token": this.state.authToken, 
      'Content-Type': configJSON.dashboarContentType,
    };
    this.getActiveProposalDetailsId = currentProposalsDetails.messageId;

    currentProposalsDetails.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_proposal_generation/proposal_generations/proposal_view_detail?proposal_id=${paramId}`
    );

    currentProposalsDetails.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    currentProposalsDetails.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    )
    runEngine.sendMessage(currentProposalsDetails.id, currentProposalsDetails)}

  fetchCurrentProposalsJobDetails=()=>{
    const currentProposalsJobDetails = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    ); 
    const header = {
      "token": this.state.authToken, 
      'Content-Type': configJSON.dashboarContentType,
    };
    this.fetchCurrentProposalsJobDetailsId = currentProposalsJobDetails.messageId;

    currentProposalsJobDetails.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.fetchCurrentProposalsJobDetailsEndPoint+`${this.state.jobId}`
    );

    currentProposalsJobDetails.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    currentProposalsJobDetails.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(currentProposalsJobDetails.id, currentProposalsJobDetails);
  };


handleCheckboxChange = (e:any) => {
  const blocked = e.target.checked
  this.setState({isBlocked:blocked })
}

  handlePaidOptionChange = (event: { target: { value: string; }; }) => {
    this.setState({ selectedPaidOption: event.target.value })};

  getArchiveProposalsDetails = () => {
    const paramId=this.props.navigation.getParam("id")
    const archivedProposalsDetails=new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    const header = {
      'Content-Type': configJSON.dashboarContentType,
      "token": this.state.authToken, 
    };
    this.getArchiveProposalDetailsId = archivedProposalsDetails.messageId;
    archivedProposalsDetails.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_proposal_generation/proposal_generations/proposal_view_archived_detail?proposal_id=${paramId}`
    )

    archivedProposalsDetails.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )

    archivedProposalsDetails.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    )
    runEngine.sendMessage(archivedProposalsDetails.id, archivedProposalsDetails);
  };


  getProposalsDetails = () => {
    const paramId=this.props.navigation.getParam("id")
    const newProposalsDetails = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    ); 
    const header = {
      "token": this.state.authToken, 
      'Content-Type': configJSON.dashboarContentType,
    };
    this.getProposalDetailsId = newProposalsDetails.messageId;

    newProposalsDetails.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_posts/my_proposals/view_by_id_archived_proposal?id=${paramId}`
    );

    newProposalsDetails.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    newProposalsDetails.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(newProposalsDetails.id, newProposalsDetails);
  };

  handleWithdrawProposalRes = (requestCallId: any,message:any) => {
    if (this.withdrawProposalsId === requestCallId) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );    

      if (responseJson) {
          this.setState({
            isDialogOpen: true,
            open: false,
            responseMessage: responseJson.message
        })
      } else {
          window.alert(responseJson.message)   
      }
  }
  }

  handleSubmitProposalRes = (requestCallId:any,message:Message) => {
    if (this.getProposalDetailsId === requestCallId) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
  

      if (responseJson) {
          this.setState({ jobDetailsData: responseJson?.data?.attributes?.archived_proposal?.data?.attributes });
      } else {
        const errorMessage = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorMessage);
       
      }
  }
}

handleJobPosting = () => {
  this.props.navigation.navigate("/SendProposals")
}

handleActiveProposalRes = (requestCallId:any,message:Message) => {
  if (this.getActiveProposalDetailsId === requestCallId) {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson) {
        this.setState({ jobActiveDetailsData: responseJson });
    } else {
      const errorMessage = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      this.parseApiCatchErrorResponse(errorMessage);
     
    }
}
}

  handleArchiveJobDetailsRes = (requestCallId: any,message:any) => {
    if (this.getArchiveProposalDetailsId === requestCallId) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      ;
  

      if (responseJson) {
          this.setState({ archivedList: responseJson });
      } else {
        const errorMessage = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorMessage);
       
      }
  }
  }
  
  handleJobDetailsRes = (requestCallId: any,message:any) => {
    if (this.fetchCurrentProposalsJobDetailsId === requestCallId) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      ;
  

      if (responseJson) {
      } else {
        const errorMessage = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorMessage);
       
      }
  }
  }
    
  // Customizable Area End

  // Customizable Area Start
  async receive(_form: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const requestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (this.submitProposalsId === requestCallId) {
        const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        ;
        

        if (responseJson) {
        
          if(responseJson.message){
            this.setState({isDialogOpen: true})

          }        
          
        } else {
          const errorMessage = message.getData(
            getName(MessageEnum.RestAPIResponceErrorMessage)
          );
          this.parseApiCatchErrorResponse(errorMessage);
         
        }
    }
    this.handleWithdrawProposalRes(requestCallId, message)
    this.handleSubmitProposalRes(requestCallId, message)
    this.handleJobDetailsRes(requestCallId, message)
    this.handleArchiveJobDetailsRes(requestCallId, message)
    this.handleActiveProposalRes(requestCallId, message)
    
  }
    // Customizable Area End
  }
  // Customizable Area End
}