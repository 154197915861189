import React, { Component } from "react";
import { Box, Typography } from "@material-ui/core";

interface UploadWorkBoxProps {
  navigation?: any;
  milestoneText: string;
  iconSrc: string;
  index: number; 
}

class UploadWorkBox extends Component<UploadWorkBoxProps> {
  render() {
    const { milestoneText, iconSrc, index ,navigation} = this.props;

    return (
      <Box marginLeft="10px">
        <Box
          style={{
            borderRadius: "12px",
            cursor: "not-allowed",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "8px 16px",
            width: "565px", 
            border: "1px solid #ECECEC",
            height: "35px"  
          }}
        >
          <Typography
            style={{
              fontWeight: 600,
              color: "#515354",
              textDecoration: "underline",
            }}
          >
            {milestoneText}
          </Typography>
          <img
            src={iconSrc}
            alt="Upload Icon"
            style={{
              width: "24px",
              height: "24px",
              opacity: index === 0 || index === 1 ? 0.5 : 1,
              cursor: index === 0 || index === 1 ? "not-allowed" : "pointer",
            }}
          />
        </Box>
      </Box>
    );
  }
}

export default UploadWorkBox;
