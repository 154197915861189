import React from 'react';
// Customizable Area Start
import { Props } from './ViewJobController.web';
import FreelancerNavBar from './FreelancerNavBar.web';
import FooterWeb from './Components/Footer.web';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Box, Chip, Typography, Button, styled } from '@material-ui/core';
import { calendar } from './assets';
import FreelancerSavedJobController from './FreelancerSavedJobController.web';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import FavoriteIcon from '@material-ui/icons/Favorite';
import Rating from '@material-ui/lab/Rating';

// Customizable Area End
export default class FreelancerSavedJob extends FreelancerSavedJobController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    return (
      //Merge Engine DefaultContainerj
      <>
        <FreelancerNavBar navigation={this.props.navigation} />
        <CustomBox>
        <Box
          display={'grid'}
          gridAutoFlow={'row'}
          gridRowGap={'1rem'}
        >
          <Box display={'grid'} gridAutoFlow={'column'} width={'17%'}>
            <Typography
              style={{
                ...webStyle.text,

                fontSize: '20px',
                fontWeight: 700,
                marginBottom: '15px'
              }}
            >
              Find Work
            </Typography>
            <ChevronRightIcon style={{ marginTop: '4px' }} />
            <Typography
              style={{
                ...webStyle.text,

                fontSize: '20px',
                fontWeight: 700,
                color: '#364BA0'
              }}
            >
              Saved Jobs
            </Typography>
          </Box>
          <Typography
            style={{
              ...webStyle.text,

              fontSize: '36px',
              fontWeight: 700,
              marginBottom: '15px'
            }}
          >
            Saved Jobs
          </Typography>
        </Box>
        </CustomBox>
        <CustomBox style={{marginTop: '32px'}}>
        <Box
          width={'70%'}
        >
          {this.state.favJobsList
            .filter(
              (fItem: any) => !this.state.removedIDS.includes(fItem.favId)
            )
            .map(item => (
              <CustomCard
              >
                <Card>
                <CardContent>
                  <Typography
                    style={{
                      ...webStyle.text,
                      fontSize: '20px',
                      fontWeight: 700,
                      color: '#303030'
                    }}
                  >{item.attributes.name}</Typography>
                  <Box display={'flex'} 
                  alignItems={'center'}>
              <LocationOnIcon style={{
                 marginLeft: '-5px', 
                 marginRight: '3px' 
                 }} />
              <Typography style={{ 
                ...webStyle.text, 
                marginTop: '10px', 
                display: 'contents',
                 }}>
                {item.attributes.location}</Typography>
              <Rating name="simple-controlled" value={3} disabled
                style={{
                  marginRight: '15px',
                  marginLeft: '7px',
                  width: '90px',
                  padding: '8px 0px',
                }}/>
              <Typography style={{ ...webStyle.text, marginLeft: '20px' }}>
                2.91/5
              </Typography>
            </Box>
                    <Box display={'flex'} style={{ 
                      alignItems: 'center', 
                      marginTop: '12px' 
                      }}>
                    <Typography 
                    style={{fontSize: '14px', 
                    fontFamily: 'Rubik', 
                    marginRight: '5px'
                    }}>Est. Time:{''}
                    </Typography>
                    <Typography style={{
                      fontSize: '14px' , 
                      fontFamily: 'Rubik'
                      }}>
                        {item.attributes.project_length}
                      </Typography>
                    </Box><Box style={{ marginTop: '10px', marginBottom: '24px' }}>
                    <Typography 
                    style={{fontSize: '14px', 
                    fontFamily: 'Rubik', 
                    color: '#6b6969'}}>{item.attributes.description}</Typography>
                  </Box><Box>{item.attributes.skills.map(
                      (item:
                          | boolean
                          | React.ReactPortal
                          | null
                          | undefined
                          | React.ReactChild
                          | React.ReactFragment) =>(
                        <Chip label={item} className="chiplabel"
                        />))}</Box></CardContent>
                <Box><Box display={'flex'}>
                  <Typography 
                  style={{fontSize: '16px', 
                  fontFamily: 'Rubik', 
                  whiteSpace: 'nowrap', 
                  margin: '0px 5px' 
                  }}>Project Budget: {''}</Typography>
                  <Typography style={{fontSize: '16px', fontFamily: 'Rubik'}}>
                   ${item?.attributes?.per_day_charge}
                  </Typography>
                </Box>
                <ProposalCount >
                  <Typography style={{fontSize: '14px', 
                                      fontFamily: 'Rubik',  
                                      whiteSpace: 'nowrap', 
                                      margin: '0px 5px' ,
                                      color: '#6b6969',
                                    }}>
                    Proposals: {''}
                  </Typography>
                  <Typography style={{fontSize: '16px', fontFamily: 'Rubik,',
                                      color: '#6b6969',
                  }}>
                   ${item?.attributes?.proposals_count}
                  </Typography>
                </ProposalCount>
                </Box>  
                </Card>

                  <CardFooter  >
                   <Box style={{alignContent: 'end'}}>
                    <Typography style={{ ...webStyle.text, 
                                         fontWeight: 400, 
                                         fontFamily: 'Rubik',
                                         alignContent: 'end'}}>
                       {item.attributes.created_at}
                    </Typography>
                   </Box>
            
                    <CustomButton>
                  <Button
                  className='favouriteBtn'
                       onClick={() => {
                        this.removeJobFromFavourite(item.favId);
                        this.setState(prev => ({
                          removedIDS: [...prev.removedIDS, item.favId]
                        }));
                      }}
                    style={{ cursor: 'pointer' , 
                             border: '1px solid #364BA0', 
                             color:'364BA0',
                             padding: '7px 30px',
                            marginRight: '10px',
                            borderRadius: '12px',
                            fontWeight: 700
                            }}
                  >
                    <FavoriteIcon
                      style={{ color: '#364BA0' , margin: '0px 5px'}}
                      data-testid={`favorite-${item.attributes.id}`}
                    />REMOVE
                  </Button>

                    <Button
                      variant="contained"
                      style={{ backgroundColor: '#364BA0', color: 'FFF' ,
                        borderRadius: '12px', fontWeight: 700, whiteSpace: 'nowrap'
                      }}
                      data-testid='sendProposalButton'
                      onClick={() => this.handleSendProposalClick(item.attributes.id)}
                    >
                      SEND PROPOSAL

                    </Button>

                  </CustomButton>
                  </CardFooter>
              </CustomCard>
            ))}
             {this.state.favJobsList
            .filter(
              (fItem: {favId:number|string}) => !this.state.removedIDS.includes(fItem.favId)
            ).length==0 && <Typography
            style={{
              fontSize: '19px',
                color: '#878484',
                fontFamily: 'Rubik',
                fontWeight: 700,
                marginTop: '100px',
                marginBottom: '20pc'
            }}
            >No saved jobs</Typography>
  }
        </Box>
        </CustomBox>
        <FooterWeb />
      </>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  text: {
    color: '#0A0A0A',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontSize: '14px',
    fontWeight: 300
  },
  card: {
    borderRadius: '24px',
    backgroundColor: 'rgb(230, 235, 239)',
    padding: '18px',
    width: '100%',
  },
};
const CustomCard = styled(Box)(({ theme }) => ({
  borderRadius: '12px',
  backgroundColor: 'rgb(230, 235, 239)',
  padding: '24px',
  width: '100%',
  marginBottom: '24px'
}));

const ProposalCount = styled(Box)(({ theme }) => ({
  marginTop: '10px',
  justifyContent: 'end',
  display: 'flex',
  '@media (max-width: 700px)': {
    justifyContent: 'start'
  },
}));

const CardContent = styled(Box)(({ theme }) => ({
   width: '70%',
  '@media (max-width: 450px)': {
    width: '100%',
    marginBottom: '30px'
  },
  '& .chiplabel': {
    fontWeight: 700,
    marginRight: '10px',
    color: '#364BA0',
    backgroundColor: '#E4F5FF',
    fontSize: '16px', 
    padding: '12px',
    '& .MuiChip-label': {
      textTransform: 'uppercase',
    fontWeight: 700,
    fontSize: '16px', 
    },
    '@media (max-width: 800px)': {
      marginBottom: '10px'
    },
  },
}));

const CustomButton = styled(Box)(({ theme }) => ({
 display: 'flex',
 marginTop: '10px',
 '@media (max-width: 500px)': {
  display: 'block',
 },
 '& .favouriteBtn': {
   '@media (max-width: 500px)': {
    marginTop: '10px',
    marginBottom: '10px',
   },
 }
}));

const Card = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  '@media (max-width: 700px)': {
    display: 'block'
  },
}));

const CardFooter = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '10px',
  '@media (max-width: 700px)': {
    display: 'block',
  },
}));

const CustomBox = styled(Box)(({ theme }) => ({
  marginLeft: '100px',
  marginRight: '50px',
  marginTop: '52px',
  display: 'grid',
  gridAutoFlow: 'row',
  gridRowGap: '1rem',
  '@media (max-width: 700px)': {
    marginLeft: '45px',
  },
  '@media (max-width: 500px)': {
    marginLeft: '30px',
  },
}));

// Customizable Area End
