import React from "react";
//Customizable Area Start

import {
    Box,
    Button,
    Typography,
    Select,
    styled,
    FormControl,
    MenuItem,
    TextField
} from "@material-ui/core";
import FreelancerNavBar from "./FreelancerNavBar.web";
import FooterWeb from "./Components/Footer.web";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Search from '@material-ui/icons/Search';
import Tune from '@material-ui/icons/Tune';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import AllContractsController, { Props } from '../../dashboard/src/AllContractsController.web';
import { webConfigJSON } from "./ActiveContractProjectsController.web";

// Customizable Area End

export default class AllContracts extends AllContractsController {
    constructor(props: Props) {
        super(props);
        //Customizable Area Start
        // Customizable Area End
    }

    //Customizable Area Start

    renderSelect = () => {
        return (
            <AboveSelect>
                <UpperText>{webConfigJSON.myJobs}</UpperText>
                <ArrowIcon>{">"}</ArrowIcon>
                <ActiveText>{webConfigJSON.activecontract}</ActiveText>
            </AboveSelect>
        );
    };

    renderContract = () => {
        return (
            <ContractMainBox>
                <Box>
                <HeaderTxt>{webConfigJSON.allcontract}</HeaderTxt>
                </Box>
                <Box style={{ display: "flex" }}>
                    <SearchMainBox>
                        <SearchIcon />
                        <SearchTextField placeholder="Search by contract or company name" />
                    </SearchMainBox>
                    <ExportButton
                        variant="contained"
                        color="default"
                        startIcon={<CloudUploadIcon />}>
                        {webConfigJSON.exportCSV}
                    </ExportButton>
                </Box>
            </ContractMainBox>
        );
    };

    renderDate = () => {
        return (
            <DateBox>
                <DateInnerBox>
                    <Box>
                        <SelectTxt>{webConfigJSON.selectDate}</SelectTxt>
                        <FormControl>
                            <SelectButton
                                data-test-id='sectionButton'
                                labelId="demo-controlled-open-select-label"
                                 id="demo-controlled-open-select"
                                value={this.state.age}
                                onOpen={this.handleOpen}
                                onChange={this.handleChangeS}
                                MenuProps={{
                                    getContentAnchorEl: null,
                                    anchorOrigin: {
                                      vertical: "bottom",
                                      horizontal: "left",
                                    },
                                    transformOrigin:{
                                        vertical: "top",
                                        horizontal: "left"
                                    },
                                     style: { maxHeight: "350px", marginTop: "8px" }
                                  }}
                                  IconComponent={KeyboardArrowDownIcon}
                                >
                                <MenuItem value={10}>{webConfigJSON.ten}</MenuItem>
                                <MenuItem value={20}>{webConfigJSON.twenty}</MenuItem>
                                <MenuItem value={30}>{webConfigJSON.thirty}</MenuItem>
                            </SelectButton>
                        </FormControl>
                    </Box>
                    <Box style={{ marginLeft: "30px" }}>
                        <SelectTxt>{webConfigJSON.selectorder}</SelectTxt>
                        <FormControl>
                            <SelectButton
                                data-test-id='sectionButton1'
                                labelId="demo-controlled-open-select-label"
                                id="demo-controlled-open-select"
                                value={this.state.date}
                                onOpen={this.handleClickAway}
                                onChange={this.handleChanges}
                                MenuProps={{
                                    getContentAnchorEl: null,
                                    anchorOrigin: {
                                      vertical: "bottom",
                                      horizontal: "left",
                                    },
                                    style: { maxHeight: "350px", marginTop: "8px" }
                                  }}
                                  IconComponent={KeyboardArrowDownIcon}
                            >
                                <MenuItem value={10}>{webConfigJSON.ascending}</MenuItem>
                                <MenuItem value={20}>{webConfigJSON.descending}</MenuItem>
                            </SelectButton>
                        </FormControl>
                    </Box>
                </DateInnerBox>
                <FilterButton
                    variant="contained"
                    color="default"
                    startIcon={<Tune />}
                >
                    {webConfigJSON.filter}
                </FilterButton>
            </DateBox>
        );
    };

    renderManagement = () => {
        return (
            <ManagementBox>
                <Box>
                    <HotelTxt>{webConfigJSON.hotelmanagementwebsite}</HotelTxt>
                </Box>
                <TextBox>
                    <HourBox>
                        <TotalTxt>{webConfigJSON.totalhourstracked}</TotalTxt>
                        <TotalTxt>{webConfigJSON.clientcetimation}</TotalTxt>
                        <TotalTxt>{webConfigJSON.totalearned}</TotalTxt>
                    </HourBox>
                    <HourBox>
                    <TotalTxtDescription>{webConfigJSON.hours72}</TotalTxtDescription>
                        <TotalTxtDescription>{webConfigJSON.hr120}</TotalTxtDescription>
                        <TotalTxtDescription>{webConfigJSON.$9250}</TotalTxtDescription>
                    </HourBox>
                </TextBox>
                <Box>
                    <TotalTxt>{webConfigJSON.jan2021}</TotalTxt>
                    <PriceTxt>{webConfigJSON.$10hr}</PriceTxt>
                </Box>
            </ManagementBox>
        );
    };

    // Customizable Area End

    render() {
        //Customizable Area Start
        return (
            <>
                <FreelancerNavBar navigation={this.props.navigation} />
                <MainBox>
                    {this.renderSelect()}
                    {this.renderContract()}
                    {this.renderDate()}
                    {this.renderManagement()}
                </MainBox>
                <FooterWeb />
            </>
        );
        //Customizable Area End
    }
}

//Customizable Area Start
const ArrowIcon = styled(Typography)({
    fontWeight: "bold",
    margin: "0px 8px",
});

const MainBox = styled(Box)({
    margin: "60px",
});

const ActiveText = styled(Typography)({
    fontWeight: "bold",
    color: "blue",
    cursor: "pointer",
});

const HeaderTxt = styled(Typography)({
    fontWeight: "bold",
    fontSize: "30px",
    margin: "18px 0px"
});

const UpperText = styled(Typography)({
    fontWeight: "bold",
    cursor: "pointer",
});

const SelectTxt = styled(Typography)({
    color: "#8F8F8f",
    marginBottom: "10px",
});

const ExportButton = styled(Button)({
    background: "#2478D1",
    marginLeft: "10px",
    borderRadius: "8px",
    color: "#fff",
    width: "200px",
    marginRight: "18px",
    fontFamily: "Rubik",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    textTransform: "uppercase",
    "&:hover": {
        background: "#185A8D",
    },
});

const FilterButton = styled(Button)({
    background: "#2478D1",
    marginLeft: "10px",
    borderRadius: "8px",
    height: "40px",
    color: "#fff",
    padding: "0px 40px",
    fontFamily: "Rubik",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    textTransform: "uppercase",
    "&:hover": {
        background: "#185A8D",
    },
});
const SelectButton = styled(Select)({
    background: "#fff",
    width: "200px",
    borderRadius: "7px",
    height: "45px"
});
const DateBox = styled(Box)({
    display: "flex",
    background: "#F2F2F2",
    padding: "20px",
    borderRadius: "8px",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "20px",
    "& .MuiInput-root": {
        "&:focus": {
            outline: "none",
        },
        "&::before": {
            content: "none",
        },
        "&::after": {
            content: "none",
        },
    },
});
const DateInnerBox = styled(Box)({
    display: "flex"
});

const ManagementBox = styled(Box)({
    display: "flex",
    background: "#F2F2F2",
    padding: "20px",
    borderRadius: "8px",
    justifyContent: "space-between",
    marginTop: "20px",
});
const HourBox = styled(Box)({
    marginBottom: "5px",
    justifyContent: "space-between",
});
const TextBox = styled(Box)({
    display: "flex",
});
const PriceTxt = styled(Typography)({
    color: "#206FC4",
    textAlign: "right",
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "italic",
    fontWeight: 500,
    lineHeight: "normal"

});
const AboveSelect = styled(Typography)({
    display: "flex",
});
const SearchIcon = styled(Search)({
    marginLeft: '15px',
    color: "gray"
});
const ContractMainBox = styled(Typography)({
    marginTop: "30px",
    display: "flex",
    justifyContent: "space-between",
    height:"50px"
});

const HotelTxt = styled(Typography)({
    fontWeight: "bold",
    fontSize: "24px",
});
const SearchMainBox = styled(Typography)({
    height: "50px",
    border: "1px solid #878787",
    borderRadius: "8px",
    display: "flex",
    alignItems: "center",
    width: "350px",
});
const TotalTxt = styled(Typography)({
    color: "#777778",
});
const TotalTxtDescription = styled(Typography)({
    color: "#000",
    marginLeft: "10px"
});
const SearchTextField = styled(TextField)({
    width: "100%",
    "& .MuiInput-root": {
        "&:focus": {
            outline: "none",
        },
        "&::before": {
            content: "none",
        },
        "&::after": {
            content: "none",
        },
    },
});

//Customizable Area End
