// Customizable Area Start
import { IBlock } from 'framework/src/IBlock';
import { BlockComponent } from 'framework/src/BlockComponent';
import { runEngine } from 'framework/src/RunEngine';
import MessageEnum, { getName } from '../../../framework/src/Messages/MessageEnum';
// Customizable Area Start
// Customizable Area End
export const webConfigJSON = require('./config.js');


export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  activeButton: string;
  selectedTab: number | string;
  services: any;
  milestones: any


  // Customizable Area End
}
interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class ProjectTrackingControllerClient extends BlockComponent<Props, S, SS> {
  // Customizable Area Start

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      activeButton: "offers",
      selectedTab: 0,
      services: [
        "Provide legal advice on intellectual property matters",
        "Assist in filing and managing trademarks, copyrights, and patents",
        "Draft, review, and negotiate IP-related agreements and contracts",
        "Conduct IP audits and provide recommendations for IP protection strategies",
        "Handle IP infringement cases and disputes"
      ],
      milestones: [
        {
          service: "Provide legal advice on intellectual property matters",
          milestoneDescription: "Flow Of Project",
          date: "27/05/24",
          status: "Approved",
          uploadWork: "Flow of project.jpg",
          totalDisputes: 0,
        },
        {
          service: "Assist in filing and managing trademarks, copyrights, and patents",
          milestoneDescription: "Flow Of Project",
          date: "04/06/24",
          status: "Approved",
          uploadWork: "Flow of project.jpg",
          totalDisputes: 6,
        },
        {
          service: "Draft, review, and negotiate IP-related agreements and contracts",
          milestoneDescription: "Flow Of Project",
          date: "05/06/24",
          status: "Ongoing",
          uploadWork: "Add attachment",
          totalDisputes: 0,
        },
        {
          service: "Conduct IP audits and provide recommendations for IP protection strategies",
          milestoneDescription: "Flow Of Project",
          date: "27/05/24",
          status: "Yet to start",
          uploadWork: "Flow of project.pdf",
          totalDisputes: 0,
        },
      ],
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  // Customizable Area Start
 
  // Customizable Area End
}
// Customizable Area End


