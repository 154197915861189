import React from "react";
// Customizable Area Start
import {
    Typography,
    Box,
    TextField,
    styled,
    OutlinedInput,
    Button,
    Select,
    MenuItem,
    FormControl,
    Dialog,
    DialogContent,
} from "@material-ui/core";
import FreelancerNavBar from "./FreelancerNavBar.web";
import FooterWeb from "./Components/Footer.web";

import FreelancerRaiseDisputesController, {
    Props,
} from "./FreelancerRaiseDisputesController";
import { image_Success } from "../../email-account-registration/src/assets";

// Customizable Area End

export default class FreelancerRaiseDisputes extends FreelancerRaiseDisputesController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End

    }
    // Customizable Area Start
    // Customizable Area End

    renderContractData = () => {
        return (
            <>
             <Box>
                    <HeaderTxt>
                        Raise a Dispute
                    </HeaderTxt>
                    <TitleTxt>
                        Submit your concerns for review and resolution. Our team will ensure a fair and timely process to address any issues related to your project.
                    </TitleTxt>
                </Box>
            </>
        );
    };

    renderModal = () =>{
        return(
          <>
             <CustomDialogBox
          open={this.state.isOpenModal}
          onClose={this.handleCloseModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
      >
           
          <DialogContent style={{
             textAlign: 'center',padding:"24px" }}>
              {this.state.milestoneupdate && 
              <>
              <img 
              src={image_Success}
               alt="Success" 
               style={{ width: "35%",
               borderRadius: "50%",
               marginTop:"20px" ,marginBottom:"10px"}} />
              <Typography 
              gutterBottom style={webStyle.congratsText}>
                  Congratulations!  
                  </Typography>
                  </>
                  }
              <Typography style={{...webStyle.profileCreatedText, 
              textTransform: 'capitalize'
              }}>
             {this.state.message}</Typography>
              <Box style={{ display: "flex",
               flexDirection: "column" }}>
                  <Button
                      variant="outlined"
                      style={webStyle.blueButton}
                      onClick={this.handleCloseModal}
                  >
                     OK
                  </Button>
              </Box>
          </DialogContent>
      </CustomDialogBox>
          </>
        )
      }

    renderProject =()=>{
        return(
            <ProjectOuterMainBox >
                <Box 
                className="projectDes">
                    <Typography 
                    style={{
                        color: '#303030' ,
                        marginBottom: '32px',
                        fontSize: '24px' ,
                        fontFamily: 'Rubik' ,
                        fontWeight: 700 ,
                    }}>
                        {this.state.activeDetailsData?.attributes?.post_attributes?.data?.attributes?.name}
                        </Typography>
                    <Box >
                        <Box 
                        className="projectContent"
                        >
                            <Typography 
                            style={webStyle.clientLeftAtrribute} 
                            >Project :
                            </Typography>
                            <Typography 
                            style={{ ...webStyle.clientRightAtrribute, 
                            textTransform: 'capitalize'
                             }}>IP Lawyer Needed
                             </Typography>
                        </Box>
                        <Box 
                        className="projectContent">
                            <Typography 
                            style={webStyle.clientLeftAtrribute} >
                                IP Client :
                            </Typography>
                            <Typography 
                            style={{ ...webStyle.clientRightAtrribute, 
                            textTransform: 'capitalize',
                             }}>
                                Rahul Sharma</Typography>
                        </Box><Box 
                        className="projectContent">
                            <Typography 
                            style={{...webStyle.clientLeftAtrribute}} 
                            >
                                IP Professional :
                                </Typography>
                            <Typography 
                            style={{ ...webStyle.clientRightAtrribute, 
                            textTransform: 'capitalize' 
                            }}>
                                John
                                </Typography></Box>
                    </Box></Box>
                <Box style={{
                    border: '1px solid #ECECEC',
                    height: '1px',
                    width: '100%'
                }}> </Box>
                {this.renderMilestone()}
            </ProjectOuterMainBox>
        );
    }

    renderButtons = () => {
        const paramId = this.props.navigation.getParam("id")
        return (
            <CustomButton >
                <Box style={{ marginTop: '48px' }}>
                    <Button
                        variant="contained"
                        className="alldisputeBtn"
                        color="primary"
                        data-testid="dailog-withdraw-proposal-btn"
                      onClick={() => this.props.navigation.navigate('FreelancerActiveContract', {id: paramId })}
                        >
                        Cancel
                    </Button>
                    <Button
                        data-testid="dailog-cancel-btn"
                        onClick={this.handleSubmit}
                        className="raisedisputeBtn">
                        Submit
                    </Button>

                </Box>
            </CustomButton>
        )
    }


    renderMilestone = () => {
        console.log(this.state.milestoneDetailsData,"milestoneDetailsData")
        return (
            <MilestoneBox>
                <Box>
                    <Box className="milestoneMainbox" >
                        <Box className={'milestoneBox'}>
                            <Box style={webStyle.clientDropdown}>
                                <Typography style={webStyle.tableHeading}>Milestone*</Typography>
                                <InputField className="textField" variant="outlined">
                                <Select
    defaultValue=""
    value={this.state.selectMilestones}
    onChange={this.handleMilestoneChange}
    style={{ fontWeight: 700 }}
    MenuProps={{
        anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
        },
        transformOrigin: {
            vertical: "bottom",
            horizontal: "left",
        },
        getContentAnchorEl: null,
    }}
    data-test-id="counter"
>
    <MenuItem disabled value="">
        Select Milestone
    </MenuItem>
    {this.state.milestoneDetailsData &&
        this.state.milestoneDetailsData.map((itm: any, index: number) => (
            <MenuItem key={index} value={itm?.attributes?.id}>
                {itm?.attributes?.id}
            </MenuItem>
        ))}
</Select>
</InputField>
{this.state.selectMilestonesErrorMessage && (
    <Typography
        style={{
            color: "red",
            marginBottom: "24px",
            fontSize: "14px",
        }}
    >     {this.state.selectMilestonesErrorMessage}
    </Typography>)}

  
                            </Box>
                            <Box className="inputBoxAmountField">
                                <Typography style={webStyle.tableHeading}>Amount*</Typography>
                                <OutlinedInput
                                    fullWidth
                                    readOnly={true}
                                    multiline
                                    value={this.state.amount}
                                    onChange={this.handleAmountChange}
                                    data-testid="project-btn"
                                    style={webStyle.inputFields}
                                    name="budget"
                                    inputProps={{
                                        style: {
                                            fontWeight: 500,
                                            color: "#515354",
                                            fontSize: "16px",
                                            fontFamily: "Rubik",
                                        },
                                    }}
                                />
                      {this.state.amountErrorMessage && (
                    <Typography style={{ color: 'red',
                      marginBottom: '24px',
                      fontSize:'14px'
                    }}>
                      {this.state.amountErrorMessage}
                    </Typography>
                  )} 
                            </Box>
                            <Box className="inputBoxField">
                                <Typography style={webStyle.tableHeading}>Status*</Typography>
                                <OutlinedInput fullWidth
                                    data-testid="project-btn"
                                    multiline
                                    value={this.state.status} 
                                    onChange={this.handleStatusChange} 
                                    style={webStyle.inputFields}
                                    inputProps={{
                                        style: {
                                            fontWeight: 500,
                                            color: '#515354',
                                            fontSize: '16px',
                                            fontFamily: 'Rubik',
                                        }
                                    }}
                                />
                                                        {this.state.statusErrorMessage && (
                    <Typography style={{ color: 'red',
                      marginBottom: '24px',
                      fontSize:'14px'
                    }}>
                      {this.state.statusErrorMessage}
                    </Typography>
                  )} 
                            </Box>
                            <Box className="inputBoxField">
                                <Typography style={webStyle.tableHeading}>Details*</Typography>
                                <OutlinedInput fullWidth
                                    data-testid="project-btn"
                                    multiline
                                    value={this.state.details} 
                                    onChange={this.handleDetailsChange} 
                                    style={webStyle.inputFields}
                                    inputProps={{
                                        style: {
                                            fontWeight: 500,
                                            color: '#515354',
                                            fontSize: '16px',
                                            fontFamily: 'Rubik',
                                        }
                                    }}
                                />
                                                        {this.state.detailsErrorMessage && (
                    <Typography style={{ color: 'red',
                      marginBottom: '24px',
                      fontSize:'14px'
                    }}>
                      {this.state.detailsErrorMessage}
                    </Typography>
                  )} 
                            </Box>

                            <Box className="inputBoxField">
                                <Typography style={webStyle.tableHeading}>Comment*</Typography>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    multiline
                                    data-testid="project-btn"
                                    value={this.state.comment}  
                                    onChange={this.handleCommentChange} 
                                    name="status"
                                    style={webStyle.inputFields}
                                    rows={4} 
                                    inputProps={{
                                        style: {
                                            fontWeight: 500,
                                            fontSize: '16px',
                                            fontFamily: 'Rubik',
                                            borderRadius: '12px'
                                        },
                                    }}
                                />
                                       {this.state.commentErrorMessage && (
                    <Typography style={{ color: 'red',
                      marginBottom: '24px',
                      fontSize:'14px'
                    }}>
                      {this.state.commentErrorMessage}
                    </Typography>
                  )} 
                           
                            </Box>
                        </Box>
                        <Box>
                        </Box>
                    </Box>
                </Box>

            </MilestoneBox>
        );
    };


    render() {
        //Customizable Area Start
        return (
            <>
                <FreelancerNavBar navigation={this.props.navigation}/>
                {this.renderModal()}
                <BoxMain>
                    {this.renderContractData()}
                    {this.renderProject()}
                    {this.renderButtons()}
                </BoxMain>
                <FooterWeb/>
            </>
        )
        //Customizable Area End
    }

}

// Customizable Area Start
const webStyle = {
    clientLeftAtrribute : {
        fontSize: '18px',
        fontWeight: 700,
        marginBottom: '6px',
        width: '30%',
        fontFamily: 'Rubik',
        color: '#3F3F3F',
        textWrap: 'nowrap'
    },
    congratsText : {
        color: "#059669",
        fontWeight: 600,
        fontFamily: "Rubik",
        fontSize: "22px",
        marginTop: "10px",
    } ,
    profileCreatedText : {
        fontWeight: 500,
        marginTop: "18px",
        fontFamily: "Rubik",
        marginBottom: '20px',
        fontSize: "16px",
    },
    inputFields : {
        borderRadius: '12px',
        '& .MuiOutlinedInput-root': {
            borderRadius: '12px !important',
        },
        '& .MuiOutlinedInput-input': {
            borderRadius: '12px !important',
        }},
    blueButton : {
        height: "40px",
        margin: 'auto',
        borderColor: '#364BA0',
        padding: "22px",
        width: '50%',
        color: '#fff',
        fontSize: "18px",
        fontFamily: "Rubik",
        marginBottom: '10px',
        fontWeight: 500,
        backgroundColor: '#364BA0',
        borderRadius: "10px",
    } ,
    clientRightAtrribute : {
        color: '#545454' ,
        fontSize: '18px' ,
        marginBottom: '6px',
        fontFamily: 'Rubik' ,
        width: '70%' ,
    },
    clientDropdown : {
        marginBottom: '20px',
        fontSize: '14px',
        fontFamily: 'Rubik',
        paddingRight: '32px' ,
        fontWeight: 700 ,
        borderRadius: '13px',
        width: '100%',
        '.MuiOutlinedInput-root' : {
            '& fieldset' : {
                borderRadius: '12px'
            }
        }
    },
    tableHeading:{
        marginBottom: '16px',
        fontFamily: 'Rubik' ,
        color: '#0A0A0A' ,
        fontWeight: 600 ,
        fontSize: '16px' ,
    } ,
    inputBox : {
        width: '60%',
    },

};

const CustomButton=styled(Box)({
    justifyContent: 'space-between' ,
    display: 'flex' ,
    "@media(max-width:600px)" : {
        display: 'block'
    } ,
    '& .endisputeBtn': {
        border: '1px solid #D60000',
        fontSize: '18px',
        fontFamily: 'Rubik',
        borderRadius: '12px',
        color: 'white',
        padding: '10px 45px',
        backgroundColor: '#D60000',
        "@media( max-width: 600px)": {
            margin: 'auto',
            display: 'flex',
        }},
    "& .alldisputeBtn" : {
        marginRight: '16px',
        border: '1px solid #364BA0',
        color: '#364BA0',
        fontSize: '18px',
        backgroundColor: 'white',
        fontFamily: 'Rubik',
        padding: '10px 45px',
        borderRadius: '12px',
        marginTop: '12px',
        "@media(max-width: 600px)" : {
            display: 'flex',
            margin: 'auto',
            marginTop: '12px',
        }} ,
    '& .raisedisputeBtn' : { 
        backgroundColor: '#364BA0',
        border: '1px solid #364BA0',
        marginTop: '12px',
        marginRight: '20px',
        color: 'white',
        borderRadius: '12px',
        fontFamily: 'Rubik',
        padding: '10px 45px',
        fontSize: '18px',
        "@media(max-width:600px)": {
            marginBottom: '40px',
            margin: 'auto',
            display: 'flex',
            marginTop: '12px',
        }},
})

const HeaderTxt= styled(Typography)({
    color: "3F3F3F",
    fontWeight: "bold",
    fontFamily: "Rubik",
    fontSize: "36px",
});

const ProjectOuterMainBox = styled(Box)({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    gap: "30px",
    '& .projectDes':{
        width: '50%',
        "@media(max-width:600px)":{
            width: '100%',
        },
        '& .projectContent':{
            gap: '100px',
            display: 'flex',
            "@media( max-width: 600px)": {
                display: 'block'
            }}
    }
});

const BoxMain=styled(Box)({
    margin: "60px"
})

const TitleTxt = styled(Typography)({
    margin: "20px 0px",
    display: "flex",
    fontSize: "14px",
    fontFamily: "Rubik, sans-serif",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "16px",
    width: "91%",
    color: "#595959"
});

const SelectManu_style = styled(MenuItem)({

    "&:hover": {
        backgroundColor: "364BA0",
        color: "white",
    },
});

const MilestoneBox = styled(Box)({
    width: '100%',
    '& .milestoneBox': {
        gap: '10px',
        "@media(max-width:600px)": {
            display: 'block',
        },
    },
    '& .milestoneMainbox':{
        marginTop: "20px", width: "50%",
        "@media(max-width:800px)": {
            width: '100%',
        },
    },
    '& .inputBoxDes': {
        width: '80%',
        marginBottom: '20px',
        "@media(max-width:600px)": {
            width: '100%',
        },
    },
    '& .inputBoxAmountField': {
        width: '60%',
        marginBottom: '20px',
        "@media(max-width:600px)": {
            width: '100%',
        },
    },
    '& .inputBoxField': {
        width: '100%',
        marginBottom: '20px',
        "@media(max-width:600px)": {
            width: '100%',
        },
    }
});

const CustomDialogBox = styled(Dialog)({
    '& .MuiPaper-rounded':{
      width:"480px",
      borderRadius: "18px",
    }
  })

const InputField = styled(FormControl)({
    width: '100%',
    "& .MuiOutlinedInput-root": {
        borderRadius: "12px !important"
    },
    "@media(max-width:500px)": {
        "&  .MuiOutlinedInput-input": {
            padding: "11.5px 12px",
        }
    },

    "& .MuiListItem-button": {
        "& :hover": {
            textDecoration: "none",
            backgroundolor: "red",

        }
    }
});

// Customizable Area End