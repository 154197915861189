import { IBlock } from 'framework/src/IBlock';
import { Message } from 'framework/src/Message';
import { BlockComponent } from 'framework/src/BlockComponent';
import { runEngine } from 'framework/src/RunEngine';
// Customizable Area Start
// Customizable Area End
export const webConfigJSON = require('./config.js');

export interface Props {
    navigation: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    activeButton: string;
    age:  string;
    open: boolean;
    showJobs:boolean;
    date:string;
    projectsData: any
    activeTab: any
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    // Customizable Area End
}

  // Customizable Area Start
  // Customizable Area End

export default class AllContractsController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        console.disableYellowBox = true;
        // Customizable Area Start

        this.state = {
            activeButton: "offers",
            age: "",
            open: false,
            showJobs:false,
            date:"",
            activeTab: 0,
            projectsData: [
                {
                  title: 'Field Management Service...',
                  author: 'Rahul Sharma',
                  activeDisputes: 4,
                  totalDisputes: 6,
                  hourlyRate: 14.5,
                  date: 'Oct 24, 2022',
                },
                {
                  title: 'Field Management Service...',
                  author: 'Rahul Sharma',
                  activeDisputes: 5,
                  totalDisputes: 8,
                  hourlyRate: 14.5,
                  date: 'Oct 24, 2022',
                },
                {
                  title: 'Field Management Service...',
                  author: 'Rahul Sharma',
                  activeDisputes: 3,
                  totalDisputes: 7,
                  hourlyRate: 14.5,
                  date: 'Oct 24, 2022',
                },
                {
                  title: 'Field Management Service...',
                  author: 'Rahul Sharma',
                  activeDisputes: 2,
                  totalDisputes: 5,
                  hourlyRate: 14.5,
                  date: 'Oct 24, 2022',
                },
              ],          
        };

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area End
    }

    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start
        this.setState({

        });
        // Customizable Area End
    }



    // Customizable Area Start
    // Customizable Area End
    
    // Customizable Area Start

    handleChangeS = (event: React.ChangeEvent<{ value: unknown }> ) => {
        this.setState({
            age: event.target.value as string
        })
    };

    handleOpen = () => {
        this.setState({ open: true });
    };

    handleChanges = (event: React.ChangeEvent<{ value: unknown }> ) => {
        this.setState({
            date: event.target.value as string
        })
    };
    handleClickAway = () => {
        this.setState({ showJobs: true});
      }

      handleTabChange = (event: any, newValue: number) => {
        this.setState({ activeTab: newValue });
      };

    // Customizable Area End
}

     // Customizable Area Start
    // Customizable Area End  
