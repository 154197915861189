//Customizable Area Start
import React from "react";
//Customizable Area Start
import { Box, Button, FormControl, Tabs, Typography, styled } from "@material-ui/core";
import ProjectTrackingControllerClient, { Props } from "./ProjectTrackingControllerClient.web";
import { uploadIcon1 } from "./assets";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import UploadWorkBox from "./Components/UploadWorkBox.web";
export const webConfigJSON = require("./config");



const CustomTabs = styled(Tabs)({
  '& .MuiTab-textColorPrimary-25.Mui-selected': {
    fontWeight: "600",
    fontSize: "22px",
    textTransform: "inherit"
  },
  '& .MuiTab-textColorPrimary-25': {
    fontWeight: "600",
    fontSize: "22px",
    textTransform: "inherit"
  },
  '& .PrivateTabIndicator-root-31': {
    height: "4px"
  }

})
// Customizable Area End

export default class ProjectTrackingClient extends ProjectTrackingControllerClient {
  constructor(props: Props) {
    super(props);
    //Customizable Area Start
    // Customizable Area End
  }

  //Customizable Area Start



  // Customizable Area End


  render() {
    //Customizable Area Start

    return (
      <Box marginTop={"3%"} marginRight={"5%"}>
        <Box display={"flex"} border={"1px solid #ECECEC"} borderRadius={"10px"} flexDirection={"column"} padding={"18px 30px"}>

          <Box marginLeft="-30px" marginRight="-30px" marginTop={"18px"}>
            <Box paddingLeft={"30px"}>

              <Box display={"flex"}>
                <Box>
                  <Typography style={{ ...webStyle.text, paddingRight: "10%", color: "#0A0A0A" }}>Efficiently track your project’s progress with real-time updates and detailed overviews. Stay on top of deadlines, manage tasks, and ensure every aspect of your project is moving forward smoothly.</Typography>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box borderTop="1px solid #ECECEC" width="calc(100% + 60px)" marginLeft="-30px" marginRight="-30px" marginTop={"18px"}>
            <Box marginTop={"2%"} paddingLeft={"30px"} display={"grid"}>
              <Box>
                <Typography style={{ ...webStyle.header, color: "#303030" }}>Milestone Tracking</Typography>
              </Box>
              {this.state.milestones.map((milestones: any, index: number) =>
                <Box display={"flex"} marginTop={"3%"}>
                  <Box display={"flex"} gridColumnGap={"42px"} flexWrap="wrap">

                    <Box display={"grid"} marginLeft={"1%"} width={"425px"} gridRowGap={"15px"}>
                      <Box display={"flex"} alignItems={"center"}>
                        <Box marginLeft={"-20px"}>
                          <Typography style={{ ...webStyle.text, fontWeight: 500, color: "#515354" }}>{index + 1}.</Typography>
                        </Box>
                        <Box marginLeft={"10px"}>
                          <Typography style={{ ...webStyle.text, fontWeight: 500, color: "#000000" }}>Milestone Description</Typography>
                        </Box>
                      </Box>


                      <Box
                        style={{
                          ...webStyle.textField,
                          borderRadius: "12px",
                          cursor: "not-allowed",
                          width: "425px"
                        }}

                      >
                        <Typography style={{ fontWeight: 600, color: "#515354" }}>
                          {milestones.milestoneDescription}
                        </Typography>
                      </Box>


                    </Box>

                    <Box display={"grid"} width={"300px"} gridRowGap={"15px"}>
                      <Box>
                        <Typography style={{ ...webStyle.text, fontWeight: 500, color: "#000000" }}>Submission Date</Typography>
                      </Box>
                      <Box display={"flex"}>
                        <Box
                          style={{
                            ...webStyle.textField,
                            borderRadius: "12px",
                            cursor: "not-allowed",
                            width: "300"
                          }}

                        >
                          <Typography style={{ fontWeight: 600, color: "#515354" }}>
                            {milestones.date}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Box display={"grid"} width={"300px"} gridRowGap={"15px"}>
                      <Box>

                        <Typography style={{ ...webStyle.text, fontWeight: 600, color: "#000000" }}>Status</Typography>
                      </Box>
                      <Box display={"flex"}>
                        <Box
                          style={{
                            ...webStyle.textField,
                            borderRadius: "12px",
                            cursor: "not-allowed",
                            width: "300px"
                          }}

                        >
                          <Typography style={{ fontWeight: 600, color: "#348306" }} >
                            {milestones.status}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Box display={"grid"} width={"25px"} gridRowGap={"15px"}>
                      <Box>
                        <Typography style={{ ...webStyle.text, fontWeight: 500 }}>Action</Typography>
                      </Box>
                      <Box
                        style={{
                          ...webStyle.textField,
                          borderRadius: "12px",
                          cursor: "not-allowed",
                          width: "25px"
                        }}

                      >
                        <Typography style={{ fontWeight: 600, color: "black" }} >
                          <MoreVertIcon />
                        </Typography>
                      </Box>
                    </Box>

                    <Box display={"grid"} gridRowGap={"15px"} marginTop={"15px"}>
                      <Box marginLeft={"15px"}>
                        <Typography style={{ ...webStyle.text, fontWeight: 500, color: "#000000" }}>Submitted Work</Typography>
                      </Box>
                  
                      
                        <Box display={"flex"} justifyContent={"space-between"}>
                          <UploadWorkBox
                            milestoneText={milestones.uploadWork}
                            iconSrc={uploadIcon1}
                            index={index}
                          />
                          <UploadWorkBox
                            milestoneText={milestones.uploadWork}
                            iconSrc={uploadIcon1}
                            index={index}
                          />
                        </Box>

                      </Box>
                   

                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
        <Box display={"flex"} justifyContent={"space-between"} marginTop={"3%"}>
          <Box display={"flex"} gridColumnGap={"2rem"}>
            <Box>
              <Button variant="contained" style={{ ...webStyle.button, backgroundColor: "#364BA0", color: "#FFF", borderRadius: "10px" }}>
                raise dispute
              </Button>
            </Box>
            <Box>
              <Button variant="contained" style={{ ...webStyle.button, color: "#364BA0", backgroundColor: "#FFF", border: "1px solid #364BA0", borderRadius: "10px" }}>
                All Disputes
              </Button>
            </Box>
          </Box>
          <Box>
            <Box>
              <Button variant="contained" style={{ ...webStyle.button, color: "#FFF", backgroundColor: "#D60000", borderRadius: "10px" }}>
                end contract
              </Button>
            </Box>
          </Box>
        </Box>

      </Box>
    );
    //Customizable Area End
  }
}

//Customizable Area Start
const InputField = styled(FormControl)({
  "@media(max-width:500px)": {
    "&  .MuiOutlinedInput-input": {
      padding: "11.5px 12px",
    }
  },

  "& .MuiListItem-button": {
    "& :hover": {
      textDecoration: "none",
      backgroundolor: "red",

    }
  }
});

const webStyle = {
  textField: {
    border: "1px solid #ECECEC",
    padding: "10px 16px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    backgroundColor: "#fff",
    cursor: "not-allowed",
    height: "35px"
  },
  borderRadius: {
    borderRadius: "12px"
  },
  header: {
    fontFamily: "Rubik",
    fontSize: "24px",
    color: "#3F3F3F",
    fontWeight: 700
  },
  subHeader: {
    fontFamily: "Rubik",
    fontSize: "16px",
    color: "#3F3F3F",
    fontWeight: 500
  },
  text: {
    fontFamily: "Rubik",
    fontSize: "15px",
    color: "#3F3F3F",
  },
  button: {
    width: "200px",
    height: "50px",
    fontFamily: "Rubik",
    fontWeight: 450

  }
}

//Customizable Area End
// Customizable Area End