import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import storage from 'framework/src/StorageProvider';
import React from "react";

interface IProfileData {
  data: {
    type: string;
    attributes: {
      basic_details: {
        id: number;
        first_name: string;
        last_name: string;
        email: string;
        country_code: string | null;
        full_phone_number: string | null;
        phone_number: number | null;
        dob: string;
        address: string;
        additional_address: string;
        apt_suite: string;
        nation: {
          id: number;
          name: string;
        };
        state: {
          id: number;
          name: string;
          nation_id: number;
        };
        city: string;
        zipcode: string;
        profile_status: {
          patents: boolean;
          expertise: boolean;
          experience: boolean;
          achievements: boolean;
          basic_details: boolean;
        };
        job_title: string;
        job_description: string;
        skills: string[];
        title_description: string | null;
        price: number | null;
        work_history: string | null;
        profile_image: string;
        experience_level: string;
        project_preferences: string;
        created_at: string;
        updated_at: string;
      };
      profile_data: {
        copyright_specialist: string | null;
        location: string | null;
        hour_rate: number;
        email: string;
        area_of_expertise: string[];
        language_expertises: {
          id: number;
          name: string;
          proficiency: string;
          created_at: string;
          updated_at: string;
        }[];
        industries: {
          id: number;
          name: string;
          created_at: string;
          updated_at: string;
        }[];
        services: {
          id: number;
          name: string;
          created_at: string;
          updated_at: string;
        }[];
        social_media: {
          id: number;
          media_name: string;
          link: string;
          created_at: string;
          updated_at: string;
        }[];
        skills: {
          name: string;
        }[];
        educations: {
          id: number;
          degree_name: string;
          college_name: string;
          country: string;
          start_date: string;
          end_date: string;
          description: string;
          degree_img: string | null;
          created_at: string;
          updated_at: string;
        }[];
        certificates: {
          id: number;
          certificate_name: string;
          certificate_provider: string;
          certification_id: string;
          certification_url: string;
          description: string | null;
          issue_date: string;
          expiration_date: string;
          created_at: string;
          updated_at: string;
        }[];
        licenses: {
          id: number;
          licensed_profession: string;
          jurisdiction_or_licensing_body: string;
          license_number: string;
          licensing_verification_url: string;
          issue_date: string;
          expiry_date: string;
          created_at: string;
          updated_at: string;
        }[];
        work_experiences: {
          id: number;
          company: string;
          job_title: string;
          location: string | null;
          currently_working: boolean;
          start_date: string;
          end_date: string;
          description: string;
          created_at: string;
          updated_at: string;
        }[];
        achievements: {
          public_relation_meterials: {
            id: number;
            organization_name: string;
            description: string;
            link: string;
            created_at: string;
            updated_at: string;
          }[];
          publications: {
            id: number;
            title: string;
            description: string;
            link: string;
            created_at: string;
            updated_at: string;
          }[];
          engagements_and_seminars: {
            id: number;
            title: string;
            description: string;
            link: string;
            account_id: number;
            created_at: string;
            updated_at: string;
          }[];
        };
        work_history: any[];
      };
      patents: {
        id: number;
        first_name: string;
        last_name: string;
        profile_status: {
          patents: boolean;
          expertise: boolean;
          experience: boolean;
          achievements: boolean;
          basic_details: boolean;
        };
        categories: {
          id: number;
          name: string;
          sub_categories: {
            id: number;
            name: string;
            sub_category_menus: {
              id: number;
              title: string;
              sub_category_sub_menus: {
                id: number;
                title: string;
                description: string;
              }[];
            }[];
          }[];
        }[];
      };
    };
  };
}

interface LanguageUpdateData {
  data: {
    id: number,
    type: string,
    attributes: {
      language_expertises: [
        {
          id: 662,
          name: string,
          proficiency: string,
          created_at: string,
          updated_at: string
        }
      ]
    }
  }
}
interface FreelancerTitle {
  message: string;
  account: {
    job_title: string;
  }
}

interface Education {
  id: number;
  degree_name: string;
  college_name: string;
  country: string | null;
  start_date: string;
  end_date: string;
  description: string;
  degree_img: string | null;
  created_at: string;
  updated_at: string;
}
interface FreelancerProfileSkills {
  data: {
    id: number;
    type: string;
    attributes: {
      skills: Skill[];
    };
  };
}

interface Skill {
  name: string;
}

interface FreelancerAreaOfExpertiseResponse {
  message: string;
  area_of_expertise: string[];
}

interface HourRateResponse {
  message: string;
  account: {
    hour_rate: number;
  };
}
export interface Service {
  data: [
    {
      id: number,
      percentage: string | any,
    }
  ]
}
interface ISocialMedia {
  id: number;
  media_name: string;
  link: string;
  created_at: string;
  updated_at: string;
}
interface IFreelancerProfileSocialMediaAttributes {
  social_media: ISocialMedia[];
}
interface IFreelancerProfileSocialMediaData {
  id: number;
  type: string;
  attributes: IFreelancerProfileSocialMediaAttributes;
}
interface IFreelancerProfileSocialMediaResponse {
  data: IFreelancerProfileSocialMediaData;
}

interface FreelancerUpdateEducation {
  data: {
    id: number,
    type: string,
    attributes: {
      degree_name: string,
      college_name: string,
      country: string,
      start_date: string,
      end_date: string,
      description: string,
      degree_img: null,
      created_at: string,
      updated_at: string
    }
  }
}
interface Job {
  id: number;
  job_title: string;
  created_at: string;
  updated_at: string;
}
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  ratingNumber: number | null;
  rating: string
  selectedTab: number,
  projects: any[]
  isPublicView: boolean
  accountId: number
  freelancerTitle: FreelancerTitle | null
  freelancerProfileSkills: FreelancerProfileSkills | null
  freelancerAreaOfExpertiseResponse: FreelancerAreaOfExpertiseResponse | null
  freelancerProfileSocialMediaResponse: IFreelancerProfileSocialMediaResponse | null
  hourRateResponse: HourRateResponse | null
  freelancerUpdateEducation: FreelancerUpdateEducation | null
  educationForms: Education[];
  editCertificateModalOpen: boolean
  addLanguageModalOpen: boolean
  addEducationModalOpen: boolean
  addExperienceModalOpen: boolean
  addCertificateModalOpen: boolean
  certificateName: string
  certificateProvider: string
  certificationID: string
  CertificationURL: string
  certificateIssueDate: string
  certificateExpirationDate: string
  addLanguages: string
  addProficiency: string
  addDegree: string
  addUniversityName: string
  addCountry: string
  addYearAttended: string
  addYearEnded: string
  addDescription: string
  addExperienceTitle: string
  addExperienceCompany: string
  addExperienceStartMonth: string
  addExperienceStartYear: string
  addExperienceCurrentlyWorking: false,
  addExperienceEndMonth: string
  addExperienceEndYear: string
  addExperienceDescription: string
  AddCertificateName: string
  AddCertificateProvider: string
  AddCertificationID: string
  AddCertificationURL: string
  AddCertificateIssueDate: string
  AddCertificateExpirationDate: string
  authToken: string
  freelancerCompleteData: IProfileData | null;
  languageUpdateData: LanguageUpdateData | null
  showAll: boolean
  showAllEducation: boolean
  showAllArticles: boolean
  articles: any[],
  selectedFile: any;
  title: string
  projectRate: number,
  servicesFee: Service,
  estimatedAmount: number,
  languages: string
  proficiency: string
  languageArray: any[]
  skills: string
  skillsArray: any[]
  industryFocus: string
  industryFocusArray: any[]
  selectedIndustries: any[]
  selectedServices: any[]
  expertise: string,
  expertiseArray: string[]
  service: string,
  servicesArray: any[],
  industriesArray: any[]
  degree: string
  universityName: string
  country: string
  yearAttended: string
  yearEnded: string
  description: string
  experienceTitle: string
  experienceCompany: string
  experienceStartMonth: string
  experienceEndMonth: string
  experienceStartYear: string
  experienceEndYear: string
  experienceDescription: string
  licensedProfession: string
  LicensingBody: string
  licenseNumber: string
  licensingVerificationURL: string
  Issue_Date: string
  ExpirationDate: string
  experienceCurrentlyWorking: boolean
  editServicesModalOpen: boolean
  expertiseModalOpen: boolean
  editProfileModalOpen: boolean
  editTitleModalOpen: boolean
  editRateModalOpen: boolean
  editLanguageModalOpen: boolean
  editSkillsModalOpen: boolean
  editIndustryFocusModalOpen: boolean
  editEducationModalOpen: boolean
  editExperienceModalOpen: boolean
  editLicenceModalOpen: boolean
  editSocialMediaModalOpen: boolean
  socialMediaData: any[]
  fileInputRef: any,
  profileImage: any;
  imageUploadError: string
  editAchivementsModalOpen: boolean
  categoryAchivements: string
  selectedAchivements: string[]
  AchivementTitle: string
  achivementsDescription: string
  achivementsLink: string
  currentEditingEducationId: number
  currentEditingCertificationId: any
  currentEditingLicenseId: number
  currentEditingExperienceId: number
  categoryAchivementsArray: any[],
  titleArray:Job[]
  // Customizable Area End
}
interface SS { }

export default class FreelancerCompleteProfileController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  freelancerServiceID: string = ""
  jobTitleID:string=""
  freelancerIndustryID: string = ""
  freelancerCompleteDataId: string = "";
  freelancerLanguageUpdateID: string = ""
  freelancerTitleUpdateID: string = "";
  freelancerEducationUpdateID: string = ""
  freelancerCertificationUpdateID: string = ""
  freelancerLicensesUpdateID: string = ""
  freelancerExperienceUpdateID: string = ""
  freelancerSkillsUpdateID: string = ""
  freelancerAreaExpertiseUpdateID: string = ""
  updateFreelancerProfileImageId: string = ""
  freelancerRateUpdateID: string = ""
  serviceFeesID: string = ""
  freelancerSocialMediaUpdateID: string = ""
  freelancerServiceUpdateID: string = ""
  freelancerIndustriesUpdateID: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage)
    ];

    this.state = {
      ratingNumber: 5,
      rating: "4.95/5",
      selectedTab: 0,
      authToken: "",
      accountId: 0,
      projects: [
        {
          title: "XYZ Project",
          duration: "Mar 6, 2020 - Oct 12, 2020",
          description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry...",
        },
        {
          title: "ABC Project",
          duration: "Jan 1, 2021 - Jul 15, 2021",
          description: "Another project description, with its own details...",
        },
      ],
      educationForms: [],
      isPublicView: false,
      freelancerTitle: null,
      freelancerProfileSkills: null,
      freelancerAreaOfExpertiseResponse: null,
      hourRateResponse: null,
      editCertificateModalOpen: false,
      addLanguageModalOpen: false,
      addEducationModalOpen: false,
      addExperienceModalOpen: false,
      addCertificateModalOpen: false,
      certificateName: "",
      certificateProvider: "",
      certificationID: "0",
      CertificationURL: "",
      certificateIssueDate: "",
      certificateExpirationDate: "",
      addLanguages: "",
      languageArray: [],
      addProficiency: "",
      addDegree: "",
      addUniversityName: "",
      addCountry: "",
      addYearAttended: "",
      addYearEnded: "",
      addDescription: "",
      addExperienceTitle: "",
      addExperienceCompany: "",
      addExperienceStartMonth: "",
      addExperienceStartYear: "",
      addExperienceCurrentlyWorking: false,
      addExperienceEndMonth: "",
      addExperienceEndYear: "",
      addExperienceDescription: "",
      AddCertificateName: '',
      AddCertificateProvider: '',
      AddCertificationID: '',
      AddCertificationURL: '',
      AddCertificateIssueDate: '',
      AddCertificateExpirationDate: '',
      freelancerCompleteData: null,
      languageUpdateData: null,
      freelancerProfileSocialMediaResponse: null,
      freelancerUpdateEducation: null,
      showAll: false,
      showAllEducation: false,
      showAllArticles: false,
      articles: [],
      selectedFile: "",
      title: "",
      projectRate: 150,
      servicesFee: {
        data: [
          {
            id: 0,
            percentage: "",
          }
        ]
      },
      estimatedAmount: 1650,
      languages: "",
      proficiency: "",
      skills: "",
      skillsArray: [],
      industryFocus: "",
      industryFocusArray: [],
      selectedIndustries: [],
      selectedServices: [],
      expertise: '',
      expertiseArray: [],
      service: '',
      servicesArray: [],
      industriesArray: [],
      degree: "",
      universityName: "",
      country: "",
      yearAttended: "",
      yearEnded: "",
      description: "",
      experienceTitle: "",
      experienceCompany: "",
      experienceStartMonth: "",
      experienceEndMonth: "",
      experienceStartYear: "",
      experienceEndYear: "",
      experienceDescription: "",
      licensedProfession: "",
      LicensingBody: "",
      licenseNumber: "",
      licensingVerificationURL: "",
      Issue_Date: "",
      ExpirationDate: "",
      experienceCurrentlyWorking: false,
      editServicesModalOpen: false,
      expertiseModalOpen: false,
      editProfileModalOpen: false,
      editTitleModalOpen: false,
      editRateModalOpen: false,
      editLanguageModalOpen: false,
      editSkillsModalOpen: false,
      editIndustryFocusModalOpen: false,
      editEducationModalOpen: false,
      editExperienceModalOpen: false,
      editLicenceModalOpen: false,
      editSocialMediaModalOpen: false,
      socialMediaData: [],
      fileInputRef: React.createRef(),
      profileImage: '',
      imageUploadError: "",
      editAchivementsModalOpen: false,
      categoryAchivements: "",
      selectedAchivements: [],
      AchivementTitle: "",
      achivementsDescription: "",
      achivementsLink: "",
      currentEditingEducationId: 0,
      currentEditingCertificationId: "0",
      currentEditingLicenseId: 0,
      currentEditingExperienceId: 0,
      categoryAchivementsArray: [
        { id: 1, name: "Public Relation Materials" },
        { id: 2, name: "Publications" },
        { id: 3, name: "Seminars" },
      ],
      titleArray:[]
    };
    this.handleSkillsChange = this.handleSkillsChange.bind(this);
    this.handleIndustrySelect = this.handleIndustrySelect.bind(this);
    this.handleExpertiseChange = this.handleExpertiseChange.bind(this);
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getDashboardData();
    // Customizable Area Start
    this.setState({
      authToken: await storage.get('authToken'),
      accountId: await storage.get('accountId')
    });
    this.freelancerCompleteProfileData()
    this.getFreelancerIndustry()
    this.getFreelancerService()
    this.getFreelancerServiceFees()
    this.getJobTitle()
    // Customizable Area End
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (webResponseJson) {
        if (webApiRequestCallId === this.jobTitleID) {
          if (webResponseJson && webResponseJson.data) {
            const jobTitles = webResponseJson.data.map((job: any) => ({
              id: job.id,
              job_title: job.job_title,
            }));
            this.setState({ titleArray: jobTitles });
          }
        }  
        if (webApiRequestCallId === this.freelancerIndustryID) {
          if (webResponseJson && webResponseJson.data) {
            const industries = webResponseJson.data.map((industry: any) => ({
              id: industry.id,
              name: industry.name
            }));
            this.setState({ industryFocusArray: industries });
          }
        }
        if (webApiRequestCallId === this.freelancerServiceID) {
          if (webResponseJson && webResponseJson.data) {
            const services = webResponseJson.data.map((service: any) => ({
              id: service.id,
              name: service.name
            }));
            this.setState({ servicesArray: services }, () => {
              console.log(this.state.servicesArray, "=== Updated servicesArray ===");
            });
          }
        }
        if (webApiRequestCallId === this.freelancerCompleteDataId) {
          this.setState({ freelancerCompleteData: webResponseJson });
        }
        if (webApiRequestCallId === this.freelancerTitleUpdateID) {
          if (webResponseJson) {
            const updatedTitle = webResponseJson.account.job_title;
            this.setState((prevState: any) => ({
              ...prevState,
              title: updatedTitle,
              freelancerCompleteData: {
                ...prevState.freelancerCompleteData,
                data: {
                  ...prevState.freelancerCompleteData.data,
                  attributes: {
                    ...prevState.freelancerCompleteData.data.attributes,
                    basic_details: {
                      ...prevState.freelancerCompleteData.data.attributes.basic_details,
                      job_title: updatedTitle,
                    },
                  },
                },
              },
            }));
          }
        }
        if (webApiRequestCallId === this.freelancerSkillsUpdateID) {
          const updatedSkills: { name: string }[] | undefined = webResponseJson?.data?.attributes?.skills;

          if (updatedSkills && this.state.freelancerCompleteData) {
            this.setState((prevState: any) => {
              const { freelancerCompleteData } = prevState;
              const updatedFreelancerCompleteData: IProfileData = {
                ...freelancerCompleteData,
                data: {
                  ...freelancerCompleteData.data,
                  attributes: {
                    ...freelancerCompleteData.data.attributes,
                    profile_data: {
                      ...freelancerCompleteData.data.attributes.profile_data,
                      skills: updatedSkills,
                    },
                  },
                },
              };

              return {
                ...prevState,
                skillsArray: updatedSkills.map((skill) => skill.name),
                freelancerCompleteData: updatedFreelancerCompleteData,
              };
            });
          }
        }
        if (webApiRequestCallId === this.freelancerAreaExpertiseUpdateID) {
          const updatedExpertises: { name: string }[] | undefined = webResponseJson?.data?.area_of_expertise;

          if (updatedExpertises && this.state.freelancerCompleteData) {
            this.setState((prevState: any) => {
              const { freelancerCompleteData } = prevState;
              const updatedFreelancerCompleteData: IProfileData = {
                ...freelancerCompleteData,
                data: {
                  ...freelancerCompleteData.data,
                  attributes: {
                    ...freelancerCompleteData.data.attributes,
                    profile_data: {
                      ...freelancerCompleteData.data.attributes.profile_data,
                      area_of_expertise: updatedExpertises,
                    },
                  },
                },
              };

              return {
                ...prevState,
                expertiseArray: updatedExpertises.map((expertise) => expertise.name),
                freelancerCompleteData: updatedFreelancerCompleteData,
              };
            });
          }
          this.freelancerCompleteProfileData()
        }
        if (webApiRequestCallId === this.updateFreelancerProfileImageId) {
          const profileImage =
            webResponseJson?.data?.attributes?.basic_details?.profile_image;

          if (profileImage && this.state.freelancerCompleteData) {
            this.setState(
              {
                profileImage,
                freelancerCompleteData: {
                  ...this.state.freelancerCompleteData,
                  data: {
                    ...this.state.freelancerCompleteData.data,
                    attributes: {
                      ...this.state.freelancerCompleteData.data.attributes,
                      basic_details: {
                        ...this.state.freelancerCompleteData.data.attributes.basic_details,
                        profile_image: profileImage,
                      },
                    },
                  },
                },
              },
              () => this.handleUpdateProfileImage()
            );
          }
          this.freelancerCompleteProfileData()
        }
        if (webApiRequestCallId === this.serviceFeesID) {
          const serviceFeesString = webResponseJson.data[0]?.percentage || "0";
          const serviceFees = parseFloat(serviceFeesString);
          const projectRate = (this.state.projectRate) || 0;

          this.setState({
            servicesFee: { data: [{ id: 0, percentage: serviceFeesString }] },
            estimatedAmount: (projectRate * (1 - serviceFees / 100))
          });
        }
        if (webApiRequestCallId === this.freelancerLanguageUpdateID) {
          const updatedLanguages = webResponseJson?.data?.attributes?.language_expertises;

          if (updatedLanguages && this.state.freelancerCompleteData) {
            this.setState((prevState: any) => {
              const { freelancerCompleteData } = prevState;
              const updatedFreelancerCompleteData: IProfileData = {
                ...freelancerCompleteData,
                data: {
                  ...freelancerCompleteData.data,
                  attributes: {
                    ...freelancerCompleteData.data.attributes,
                    profile_data: {
                      ...freelancerCompleteData.data.attributes.profile_data,
                      language_expertises: updatedLanguages,
                    },
                  },
                },
              };

              return {
                ...prevState,
                freelancerCompleteData: updatedFreelancerCompleteData,
              };
            });
          }
          this.freelancerCompleteProfileData()
        }

        if (webApiRequestCallId === this.freelancerEducationUpdateID) {
          const updatedEducation = webResponseJson.data.educations;
          if (updatedEducation && this.state.freelancerCompleteData) {
            this.setState((prevState: any) => {
              const { freelancerCompleteData } = prevState;
              const updatedFreelancerCompleteData: IProfileData = {
                ...freelancerCompleteData,
                data: {
                  ...freelancerCompleteData.data,
                  attributes: {
                    ...freelancerCompleteData.data.attributes,
                    profile_data: {
                      ...freelancerCompleteData.data.attributes.profile_data,
                      educations: updatedEducation,
                    },
                  },
                },
              };

              return {
                ...prevState,
                freelancerCompleteData: updatedFreelancerCompleteData,
              };
            });
          }
          this.freelancerCompleteProfileData()
        }
        if (webApiRequestCallId === this.freelancerCertificationUpdateID) {
          const updatedCertificate = webResponseJson?.data?.certifications;
          if (updatedCertificate && this.state.freelancerCompleteData) {
            this.setState((prevState: any) => {
              const { freelancerCompleteData } = prevState;
              const updatedFreelancerCompleteData: IProfileData = {
                ...freelancerCompleteData,
                data: {
                  ...freelancerCompleteData.data,
                  attributes: {
                    ...freelancerCompleteData.data.attributes,
                    profile_data: {
                      ...freelancerCompleteData.data.attributes.profile_data,
                      certificates: updatedCertificate,
                    },
                  },
                },
              };

              return {
                ...prevState,
                freelancerCompleteData: updatedFreelancerCompleteData,
              };
            });
          }
          this.freelancerCompleteProfileData()
        }
        if (webApiRequestCallId === this.freelancerLicensesUpdateID) {
          const updatedLicense = webResponseJson?.data?.licenses;
          if (updatedLicense && this.state.freelancerCompleteData) {
            this.setState((prevState: any) => {
              const { freelancerCompleteData } = prevState;
              const updatedFreelancerCompleteData: IProfileData = {
                ...freelancerCompleteData,
                data: {
                  ...freelancerCompleteData.data,
                  attributes: {
                    ...freelancerCompleteData.data.attributes,
                    profile_data: {
                      ...freelancerCompleteData.data.attributes.profile_data,
                      licenses: updatedLicense,
                    },
                  },
                },
              };

              return {
                ...prevState,
                freelancerCompleteData: updatedFreelancerCompleteData,
              };
            });
          }
          this.freelancerCompleteProfileData()
        }
        if (webApiRequestCallId === this.freelancerExperienceUpdateID) {
          const updatedExperience = webResponseJson?.data?.experience;
          if (updatedExperience && this.state.freelancerCompleteData) {
            this.setState((prevState: any) => {
              const { freelancerCompleteData } = prevState;
              const updatedFreelancerCompleteData: IProfileData = {
                ...freelancerCompleteData,
                data: {
                  ...freelancerCompleteData.data,
                  attributes: {
                    ...freelancerCompleteData.data.attributes,
                    profile_data: {
                      ...freelancerCompleteData.data.attributes.profile_data,
                      work_experiences: updatedExperience,
                    },
                  },
                },
              };

              return {
                ...prevState,
                freelancerCompleteData: updatedFreelancerCompleteData,
              };
            });
          }
          this.freelancerCompleteProfileData()
        }
        if (webApiRequestCallId === this.freelancerRateUpdateID) {
          if (webResponseJson) {
            const updatedHourRate = webResponseJson.account.hour_rate;
            this.setState((prevState) => ({
              ...prevState,
              projectRate: updatedHourRate,
            }));
          }
          this.freelancerCompleteProfileData()
        }
        if (webApiRequestCallId === this.freelancerSocialMediaUpdateID) {
          const updatedSocialMedia = webResponseJson?.data?.attributes?.profile_data?.social_media;

          if (updatedSocialMedia && this.state.freelancerCompleteData) {
            this.setState((prevState: any) => {
              const { freelancerCompleteData } = prevState;
              const updatedFreelancerCompleteData: IProfileData = {
                ...freelancerCompleteData,
                data: {
                  ...freelancerCompleteData.data,
                  attributes: {
                    ...freelancerCompleteData.data.attributes,
                    profile_data: {
                      ...freelancerCompleteData.data.attributes.profile_data,
                      social_media: updatedSocialMedia,
                    },
                  },
                },
              };

              return {
                ...prevState,
                freelancerCompleteData: updatedFreelancerCompleteData,
              };
            });
          }
          this.freelancerCompleteProfileData()
        }
        if (webApiRequestCallId === this.freelancerIndustriesUpdateID) {
          const updatedIndustries = webResponseJson?.data?.attributes?.profile_data?.industries
          if (updatedIndustries && this.state.freelancerCompleteData) {
            this.setState((prevState: any) => {
              const { freelancerCompleteData } = prevState;
              const updatedFreelancerCompleteData: IProfileData = {
                ...freelancerCompleteData,
                data: {
                  ...freelancerCompleteData.data,
                  attributes: {
                    ...freelancerCompleteData.data.attributes,
                    profile_data: {
                      ...freelancerCompleteData.data.attributes.profile_data,
                      industries: updatedIndustries,
                    },
                  },
                },
              };

              return {
                ...prevState,
                industriesArray: updatedIndustries.map((industry: any) => industry.name),
                freelancerCompleteData: updatedFreelancerCompleteData,
              };
            });
          }
          this.freelancerCompleteProfileData();
        }
        if (webApiRequestCallId === this.freelancerServiceUpdateID) {
          const updatedServices = webResponseJson?.data?.attributes?.profile_data?.service
          if (updatedServices && this.state.freelancerCompleteData) {
            this.setState((prevState: any) => {
              const { freelancerCompleteData } = prevState;
              const updatedFreelancerCompleteData: IProfileData = {
                ...freelancerCompleteData,
                data: {
                  ...freelancerCompleteData.data,
                  attributes: {
                    ...freelancerCompleteData.data.attributes,
                    profile_data: {
                      ...freelancerCompleteData.data.attributes.profile_data,
                      services: updatedServices,
                    },
                  },
                },
              };

              return {
                ...prevState,
                servicesArray: updatedServices.map((service: any) => service.name),
                freelancerCompleteData: updatedFreelancerCompleteData,
              };
            });
          }
          this.freelancerCompleteProfileData();
        }

      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  handleEditModalOpenCertificate = (certificateId: number) => {
    const { freelancerCompleteData } = this.state;

    const certificateData = freelancerCompleteData?.data?.attributes?.profile_data?.certificates.find(
      (certificate) => certificate.id === certificateId
    );
    if (certificateData) {
      this.setState({
        editCertificateModalOpen: true,
        certificateProvider: certificateData.certificate_provider || "",
        certificationID: certificateData.certification_id,
        certificateName: certificateData.certificate_name || "",
        CertificationURL: certificateData.certification_url || "",
        certificateIssueDate: certificateData.issue_date || "",
        certificateExpirationDate: certificateData.expiration_date || "",
        currentEditingCertificationId: certificateData.id,
      });
    }
  };
  handleTabChange = (event: any, newValue: number) => {
    this.setState({ selectedTab: newValue });
  };

  toggleView = () => {
    this.setState((prevState) => ({
      isPublicView: !prevState.isPublicView,
    }));
  };
  handleSaveCertificate = (certificateId: number) => {
    this.setState({ editCertificateModalOpen: false });

    const { accountId } = this.state;
    const header = {
      token: this.state.authToken,
    };

    const formData = new FormData();
    formData.append(
      `account[certificates_attributes][certificate_name]`,
      this.state.certificateName
    );
    formData.append(
      `account[certificates_attributes][certificate_provider]`,
      this.state.certificateProvider
    );
    formData.append(
      `account[certificates_attributes][certification_id]`,
      this.state.certificationID.toString()
    );
    formData.append(
      `account[certificates_attributes][certification_url]`,
      this.state.CertificationURL
    );
    formData.append(
      `account[certificates_attributes][issue_date]`,
      this.state.certificateIssueDate
    );
    formData.append(
      `account[certificates_attributes][expiration_date]`,
      this.state.certificateExpirationDate
    );

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.freelancerCertificationUpdateID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/freelancers/${accountId}/freelancer_profile_certificates?certificate_id=${certificateId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleSaveAddLanguage = () => {
    this.setState({ addLanguageModalOpen: false });
  };
  handleSaveAchivements = () => {
    this.setState({ editAchivementsModalOpen: false });
  }
  handleAddedEducationSave = () => {
    this.setState({ addEducationModalOpen: false });
  };
  handleAddModalOpenExperience = () => {
    this.setState({ addExperienceModalOpen: true });
  };

  handleAddModalCloseExperience = () => {
    this.setState({ addExperienceModalOpen: false });
  };

  handleChangeAddExperienceTitle = (event: { target: { value: any; }; }) => {
    this.setState({ addExperienceTitle: event.target.value });
  };

  handleChangeAddExperienceCompany = (event: { target: { value: any; }; }) => {
    this.setState({ addExperienceCompany: event.target.value });
  };

  handleChangeAddStartMonth = (event: { target: { value: any; }; }) => {
    this.setState({ addExperienceStartMonth: event.target.value });
  };

  handleChangeAddStartYear = (event: { target: { value: any; }; }) => {
    this.setState({ addExperienceStartYear: event.target.value });
  };

  handleAddCurrentlyWorkingToggle = (event: { target: { checked: any; }; }) => {
    this.setState({ addExperienceCurrentlyWorking: event.target.checked });
  };

  handleChangeAddEndMonth = (event: { target: { value: any; }; }) => {
    this.setState({ addExperienceEndMonth: event.target.value });
  };

  handleChangeAddEndYear = (event: { target: { value: any; }; }) => {
    this.setState({ addExperienceEndYear: event.target.value });
  };

  handleChangeAddExperienceDescription = (event: { target: { value: any; }; }) => {
    this.setState({ addExperienceDescription: event.target.value });
  };

  handleAddedSaveExperience = () => {

  };
  handleAddModalOpenCertificate = () => {
    this.setState({ addCertificateModalOpen: true });
  };

  handleAddModalCloseCertificate = () => {
    this.setState({ addCertificateModalOpen: false });
  };



  handleSaveAddCertificate = () => {

  };


  toggleShowAll = () => {
    this.setState((prevState) => ({ showAll: !prevState.showAll }));
  };
  toggleShowAllEducation = () => {
    this.setState((prevState) => ({ showAllEducation: !prevState.showAllEducation }));
  };
  handleProfileSettings = () => {
    this.props.navigation.navigate('FreelancerSetting')
  }
  toggleShowAllArticles = () => {
    this.setState((prevState) => ({ showAllArticles: !prevState.showAllArticles }));
  };
  handleEditModalOpenAchivements = () => {
    this.setState({ editAchivementsModalOpen: true })
  }
  
  handleEditModalCloseAchivements = () => {
    this.setState({ editAchivementsModalOpen: false });
  };
  handleCategoryAchivementsSelect = (event: { target: { value: any; }; }) => {
    this.setState({ categoryAchivements: event.target.value });
  };

  handleChangeAchivementTitle = (event: { target: { value: any; }; }) => {
    this.setState({ AchivementTitle: event.target.value });
  }
  handleChangeAchivementsDescription = (event: { target: { value: any; }; }) => {
    this.setState({ achivementsDescription: event.target.value })
  }
  handleChangeAchivementsLink = (event: { target: { value: any; }; }) => {
    this.setState({ achivementsLink: event.target.value })
  }
  handleChangeAddCertificateName = (event: { target: { value: any; }; }) => {
    this.setState({ AddCertificateName: event.target.value });
  };

  handleChangeAddCertificateProvider = (event: { target: { value: any; }; }) => {
    this.setState({ AddCertificateProvider: event.target.value });
  };

  handleChangeAddCertificationID = (event: { target: { value: any; }; }) => {
    this.setState({ AddCertificationID: event.target.value });
  };

  handleChangeAddCertificationURL = (event: { target: { value: any; }; }) => {
    this.setState({ AddCertificationURL: event.target.value });
  };

  handleChangeAddCertificateIssueDate = (event: { target: { value: any; }; }) => {
    this.setState({ AddCertificateIssueDate: event.target.value });
  };

  handleChangeAddCertificateExpirationDate = (event: { target: { value: any; }; }) => {
    this.setState({ AddCertificateExpirationDate: event.target.value });
  };

  handleEditModalCloseExperience = () => {
    this.setState({ editExperienceModalOpen: false });
  };
  handleEditModalCloseSkills = () => {
    this.setState({ editSkillsModalOpen: false });
  };
  handleEditProfileModalClose = () => {
    this.setState({ editProfileModalOpen: false });
  };

  handleEditModalCloseRate = () => {
    this.setState({ editRateModalOpen: false });
  };
  handleEditModalCloseLanguage = () => {
    this.setState({ editLanguageModalOpen: false });
  };

  handleEditModalLanguage = () => {
    this.setState({ editLanguageModalOpen: false });
  };

  handleEditModalCloseIndustryFocus = () => {
    this.setState({ editIndustryFocusModalOpen: false });
  };

  handleEditModalCloseTitle = () => {
    this.setState({ editTitleModalOpen: false });
  };
  handleEditModalCloseEducation = () => {
    this.setState({ editEducationModalOpen: false })
  }
  handleChangeTitle = (event: { target: { value: any; }; }) => {
    this.setState({ title: event.target.value })
  }
  handleChangeYearAttended = (event: { target: { value: any; }; }) => {
    this.setState({ yearAttended: event.target.value })
  };

  handleChangeYearEnded = (event: { target: { value: any; }; }) => {
    this.setState({ yearEnded: event.target.value })

  };
  handleSaveTitle = () => {
    this.freelancerTitleUpdate()
    this.setState({ editTitleModalOpen: false });
  }
  // Customizable Area End
  handleEditProfileModalOpen = () => {
    const { freelancerCompleteData } = this.state;
    const profileImage = freelancerCompleteData?.data?.attributes?.basic_details?.profile_image;

    this.setState({
      profileImage: profileImage || '',
      editProfileModalOpen: true,
    });
  };



  handleEditModalOpenTitle = () => {
    const { freelancerCompleteData } = this.state;

    if (freelancerCompleteData?.data?.attributes?.basic_details?.job_title) {
      this.setState({
        title: freelancerCompleteData.data.attributes.basic_details.job_title,
        editTitleModalOpen: true,
      });
    }
  }
  handleEditModalOpenRate = () => {
    this.getFreelancerServiceFees();

    const { freelancerCompleteData } = this.state;
    if (freelancerCompleteData?.data?.attributes?.profile_data?.hour_rate) {
      const currentRate = freelancerCompleteData.data.attributes.profile_data.hour_rate;

      this.setState({
        projectRate: currentRate,
        estimatedAmount: this.calculateEstimatedAmount(currentRate),
        editRateModalOpen: true,
      });
    }
  }

  calculateEstimatedAmount = (rate: number) => {
    const serviceFeePercentage = this.state.servicesFee.data[0]?.percentage || 0;
    const serviceFee = (rate * serviceFeePercentage) / 100;
    return rate - serviceFee;
  }

  handleEditModalOpenLanguage = () => {
    const { freelancerCompleteData } = this.state;

    if (freelancerCompleteData?.data?.attributes?.profile_data?.language_expertises) {
      this.setState({
        languageArray: freelancerCompleteData.data.attributes.profile_data.language_expertises.map((language) => ({
          id: language.id,
          name: language.name,
          proficiency: language.proficiency,
        })),
        editLanguageModalOpen: true,
      });
    }
  };
  handleEditModalOpenSkills = () => {
    const { freelancerCompleteData } = this.state;
    const updatedSkills = freelancerCompleteData?.data?.attributes?.profile_data?.skills;

    if (updatedSkills) {
      this.setState({
        skillsArray: updatedSkills.map((skill) => skill.name),
        editSkillsModalOpen: true,
      });
    }
  };

  handleEditModalOpenIndustryFocus = () => {
    const { freelancerCompleteData } = this.state;

    const industries = freelancerCompleteData?.data?.attributes?.profile_data?.industries;
    if (industries && industries.length > 0) {
      const selectedIndustriesNames = industries.map((service: any) => service.name);

      this.setState({
        industriesArray: industries,
        selectedIndustries: selectedIndustriesNames,
        editIndustryFocusModalOpen: true,
      });
    }
  };

  handleEditModalOpenEducation = (educationId: number) => {
    const { freelancerCompleteData } = this.state;

    const educationData = freelancerCompleteData?.data?.attributes?.profile_data?.educations.find(
      (education) => education.id === educationId
    );

    if (educationData) {
      this.setState({
        editEducationModalOpen: true,
        degree: educationData.degree_name || "",
        universityName: educationData.college_name || "",
        country: educationData.country || "",
        yearAttended: educationData.start_date
          ? new Date(educationData.start_date).getFullYear().toString()
          : "",
        yearEnded: educationData.end_date
          ? new Date(educationData.end_date).getFullYear().toString()
          : "",
        description: educationData.description || "",
        currentEditingEducationId: educationData.id,
      });
    }
  };

 
  handleEditModalCloseLicence = () => {
    this.setState({ editLicenceModalOpen: false });
  }
  handleEditModalCloseSocialMedia = () => {
    this.setState({ editSocialMediaModalOpen: false });
  }
  handleEditModalOpenExperience = (experienceId: number) => {
    const { freelancerCompleteData } = this.state;

    const experienceData = freelancerCompleteData?.data?.attributes?.profile_data?.work_experiences.find(
      (experience) => experience.id === experienceId
    );

    if (experienceData) {
      this.setState({
        editExperienceModalOpen: true,
        experienceTitle: experienceData.job_title || "",
        experienceCompany: experienceData.company || "",
        experienceDescription: experienceData.description || "",
        experienceStartMonth: experienceData.start_date || "",
        experienceEndMonth: experienceData.end_date || "",
        experienceStartYear: experienceData.description || "",
        experienceEndYear: experienceData.description || "",
        experienceCurrentlyWorking: experienceData.currently_working,
        currentEditingExperienceId: experienceData.id,
      });
    }
  };
  handleEditModalOpenLicence = (licenseId: number) => {
    const { freelancerCompleteData } = this.state;

    const licenseData = freelancerCompleteData?.data?.attributes?.profile_data?.licenses.find(
      (license) => license.id === licenseId
    );
    if (licenseData) {
      this.setState({
        editLicenceModalOpen: true,
        licenseNumber: licenseData.license_number || "",
        licensedProfession: licenseData.licensed_profession || "",
        LicensingBody: licenseData.jurisdiction_or_licensing_body || "",
        licensingVerificationURL: licenseData.licensing_verification_url || "",
        Issue_Date: licenseData.issue_date || "",
        ExpirationDate: licenseData.expiry_date,
        currentEditingLicenseId: licenseData.id,
      });
    }
  }
  handleEditModalOpenSocialMedia = () => {
    const { freelancerCompleteData } = this.state;

    if (freelancerCompleteData?.data?.attributes?.profile_data?.social_media) {
      this.setState({
        socialMediaData: freelancerCompleteData.data.attributes.profile_data.social_media.map((socialMedia) => ({
          id: socialMedia.id,
          media_name: socialMedia.media_name,
          link: socialMedia.link,
        })),
        editSocialMediaModalOpen: true,
      });
    }
  };


  handleSocialMediaChange = (id: number, field: string, value: string) => {
    this.setState((prevState: S) => ({
      socialMediaData: prevState.socialMediaData.map(media =>
        media.id === id ? { ...media, [field]: value } : media
      ),
    }));
  };
  handleAddModalOpenLanguage = () => {
    this.setState({ addLanguageModalOpen: true });
  };
  handleAddModalOpenEducation = () => {
    this.setState({ addEducationModalOpen: true });
  };
  handleEditModalCloseCertificate = () => {
    this.setState({ editCertificateModalOpen: false });
  };
  handleAddModalCloseLanguage = () => {
    this.setState({ addLanguageModalOpen: false });
  };
  handleAddModalCloseEducation = () => {
    this.setState({ addEducationModalOpen: false });
  };
  handleChangeCertificateName = (event: { target: { value: any; }; }) => {
    this.setState({ certificateName: event.target.value });
  }
  handleChangeCertificateProvider = (event: { target: { value: any; }; }) => {
    this.setState({ certificateProvider: event.target.value });
  }
  handleChangeCertificationID = (event: { target: { value: any; }; }) => {
    this.setState({ certificationID: event.target.value });
  }

  handleChangeCertificationURL = (event: { target: { value: any; }; }) => {
    this.setState({ CertificationURL: event.target.value });
  }
  handleChangeCertificateIssueDate = (event: { target: { value: any; }; }) => {
    this.setState({ certificateIssueDate: event.target.value });
  }
  handleCertificateExpirationDateChange = (event: { target: { value: any; }; }) => {
    this.setState({ certificateExpirationDate: event.target.value });
  }
  handleChangeAddLanguages = (event: { target: { value: any; }; }) => {
    this.setState({ addLanguages: event.target.value });
  }
  handleChangeAddProficiency = (event: { target: { value: any; }; }) => {
    this.setState({ addProficiency: event.target.value });
  }
  handleChangeAddDegree = (event: { target: { value: any; }; }) => {
    this.setState({ addDegree: event.target.value });
  }
  handleChangeAddUniversityName = (event: { target: { value: any; }; }) => {
    this.setState({ addUniversityName: event.target.value });
  }
  handleChangeAddCountry = (event: { target: { value: any; }; }) => {
    this.setState({ addCountry: event.target.value });
  }
  handleChangeAddYearAttended = (event: { target: { value: any; }; }) => {
    this.setState({ addYearAttended: event.target.value });
  }
  handleChangeAddYearEnded = (event: { target: { value: any; }; }) => {
    this.setState({ addYearEnded: event.target.value });
  }
  handleChangeAddDescription = (event: { target: { value: any; }; }) => {
    this.setState({ addDescription: event.target.value });
  }
  handleEditModalCloseIndustry = () => {
    this.setState({ editIndustryFocusModalOpen: false });
  };
  handleEditModalCloseFocus = () => {
    this.setState({ editIndustryFocusModalOpen: false });
  };

  handleChangeDegree = (event: { target: { value: any; }; }) => {
    this.setState({ degree: event.target.value })
  }

  handleChangeUniversityName = (event: { target: { value: any; }; }) => {
    this.setState({ universityName: event.target.value })
  }

  handleChangeCountry = (event: { target: { value: any; }; }) => {
    this.setState({ country: event.target.value })
  }
  handleChangeDescription = (event: { target: { value: any; }; }) => {
    this.setState({ description: event.target.value })
  }
  handleChangeExperienceTitle = (event: { target: { value: any; }; }) => {
    this.setState({ experienceTitle: event.target.value })
  }
  handleChangeExperienceCompany = (event: { target: { value: any; }; }) => {
    this.setState({ experienceCompany: event.target.value })
  }
  handleChangeStartMonth = (event: { target: { value: any; }; }) => {
    this.setState({ experienceStartMonth: event.target.value })
  }
  handleChangeEndMonth = (event: { target: { value: any; }; }) => {
    this.setState({ experienceEndMonth: event.target.value })
  }
  handleChangeStartYear = (event: { target: { value: any; }; }) => {
    this.setState({ experienceEndMonth: event.target.value })
  }
  handleChangeEndYear = (event: { target: { value: any; }; }) => {
    this.setState({ experienceEndMonth: event.target.value })
  }
  handleChangeExperienceDescription = (event: { target: { value: any; }; }) => {
    this.setState({ experienceDescription: event.target.value });
  };
  handleChangeLicensedProfession = (event: { target: { value: any; }; }) => {
    this.setState({ licensedProfession: event.target.value })
  }
  handleChangeLicensingBody = (event: { target: { value: any; }; }) => {
    this.setState({ LicensingBody: event.target.value })
  }
  handleChangeLicenseNumber = (event: { target: { value: any; }; }) => {
    this.setState({ licenseNumber: event.target.value })
  }
  handleChangeLicensingVerificationURL = (event: { target: { value: any; }; }) => {
    this.setState({ licensingVerificationURL: event.target.value })
  }
  handleIssueDateChange = (event: { target: { value: any; }; }) => {
    this.setState({ Issue_Date: event.target.value })
  }
  handleExpirationDateChange = (event: { target: { value: any; }; }) => {
    this.setState({ ExpirationDate: event.target.value })
  }
  handleCurrentlyWorkingToggle = (event: { target: { checked: any; }; }) => {
    this.setState({ experienceCurrentlyWorking: event.target.checked });
  };

  handleProjectRateChange = (event: { target: { value: string; }; }) => {
    const newRateString = event.target.value.replace('$', '');
    const newRateNumber = parseFloat(newRateString) || 0;

    const serviceFees = parseFloat(this.state.servicesFee.data[0]?.percentage) || 0;
    const estimatedAmount = (newRateNumber * (1 - serviceFees / 100));

    this.setState({
      projectRate: newRateNumber,
      estimatedAmount,
    });
  };





  handleIndustrySelect = (event: React.ChangeEvent<{ value: any }>) => {
    const selectedIndustry = event.target.value as string;
    this.setState((prevState) => ({
      selectedIndustries: prevState.selectedIndustries.includes(selectedIndustry)
        ? prevState.selectedIndustries
        : [...prevState.selectedIndustries, selectedIndustry],
    }));
  };

  handleServiceSelect = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectedService = event.target.value as string;

    this.setState((prevState) => ({
      selectedServices: prevState.selectedServices.includes(selectedService)
        ? prevState.selectedServices.filter((service) => service !== selectedService)
        : [...prevState.selectedServices, selectedService],
    }));
  };

  handleSkillsKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && this.state.skills.trim() !== '') {
      this.setState((prevState) => ({
        skillsArray: [...prevState.skillsArray, prevState.skills.trim()],
        skills: '',
      }));
    }
  };

  handleIndustryKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && this.state.industryFocus.trim() !== '') {
      this.setState((prevState) => ({
        industryFocusArray: [...prevState.industryFocusArray, prevState.industryFocus.trim()],
        industryFocus: '',
      }));
    }
  }
  handleDeleteSkill = (skillToDelete: string) => {
    this.setState((prevState) => ({
      skillsArray: prevState.skillsArray.filter((skill) => skill !== skillToDelete),
    }));
  };

  handleDeleteIndustry = (industryToDelete: string) => {
    this.setState((prevState) => ({
      selectedIndustries: prevState.selectedIndustries.filter((industry) => industry !== industryToDelete),
    }));
  };

  handleSaveRate = () => {
    const { projectRate, authToken, accountId } = this.state;

    this.setState({ editRateModalOpen: false });

    const body = {
      account: {
        hour_rate: projectRate,
      },
    };

    const header = {
      "Content-Type": "application/json",
      token: authToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.freelancerRateUpdateID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/account_block/freelancers/${accountId}/freelancer_update_hour_rate`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleSaveSkills = () => {
    const { skillsArray, authToken, accountId } = this.state;

    this.setState({ editSkillsModalOpen: false });

    const body = {
      account: {
        skills: skillsArray,
      },
    };

    const header = {
      "Content-Type": "application/json",
      token: authToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.freelancerSkillsUpdateID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/freelancers/${accountId}/freelancer_profile_skills`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleSaveIndustry = () => {
    const { selectedIndustries, authToken, accountId, industryFocusArray } = this.state;
    this.setState({ editIndustryFocusModalOpen: false });

    const selectedIndustryIds = selectedIndustries.map((industryName) =>
      industryFocusArray.find((industry) => industry.name === industryName)?.id
    );

    const body = {
      account: {
        industry_attributes: [
          {
            industry_ids: selectedIndustryIds,
          },
        ],
      },
    };
    const header = {
      "Content-Type": "application/json",
      token: authToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.freelancerIndustriesUpdateID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/account_block/freelancers/${accountId}/freelancer_profile_industries`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  handleSaveLanguage = () => {
    const { languageArray, authToken, accountId } = this.state;

    const formData = new FormData();
    languageArray.forEach((language, index) => {
      formData.append(`account[language_expertises_attributes][${index}][id]`, language.id);
      formData.append(`account[language_expertises_attributes][${index}][language_name]`,language.name);
      formData.append(`account[language_expertises_attributes][${index}][proficiency]`, language.proficiency);
    });
  
    const header = {
      Accept: "application/json",
      token: authToken,
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.freelancerLanguageUpdateID = requestMessage.messageId;

    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `/account_block/freelancers/${accountId}/freelancer_profile_languages`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PATCH");

    runEngine.sendMessage(requestMessage.id, requestMessage);


    this.setState({ editLanguageModalOpen: false });
  };

  handleSaveExperience = (experienceId: number) => {
    const { accountId } = this.state;
    this.setState({ editExperienceModalOpen: false });
    const header = {
      token: this.state.authToken
    };
    const formData = new FormData();
    formData.append(`account[work_experiences_attributes][company]`, this.state.experienceCompany);
    formData.append(`account[work_experiences_attributes][job_title]`, this.state.experienceTitle);
    formData.append(`account[work_experiences_attributes][start_date]`, this.state.experienceStartMonth);
    formData.append(`account[work_experiences_attributes][end_date]`, this.state.experienceEndMonth);
    formData.append(`account[work_experiences_attributes][description]`, this.state.experienceDescription);

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.freelancerExperienceUpdateID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/freelancers/${accountId}/freelancer_profile_work_experience?work_experience_id=${experienceId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  handleSaveLicence = (licenseId: number) => {
    this.setState({ editLicenceModalOpen: false });

    const { accountId } = this.state;
    const header = {
      token: this.state.authToken,
    };

    const formData = new FormData();
    formData.append(
      `account[licenses_attributes][licensed_profession]`,
      this.state.licensedProfession
    );
    formData.append(
      `account[licenses_attributes][jurisdiction_or_licensing_body]`,
      this.state.LicensingBody
    );
    formData.append(
      `account[licenses_attributes][license_number]`,
      this.state.licenseNumber
    );
    formData.append(
      `account[licenses_attributes][licensing_verification_url]`,
      this.state.licensingVerificationURL
    );
    formData.append(
      `account[licenses_attributes][expiry_date]`,
      this.state.ExpirationDate
    );
    formData.append(
      `account[licenses_attributes][issue_date]`,
      this.state.Issue_Date
    );

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.freelancerLicensesUpdateID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/freelancers/${accountId}/freelancer_profile_licenses?license_id=${licenseId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleEducationSave = (educationId: number) => {
    const { accountId } = this.state;
    this.setState({ editEducationModalOpen: false });
    const header = {
      token: this.state.authToken
    };
    const formData = new FormData();
    formData.append(`account[educations_attributes][id]`, educationId.toString());
    formData.append(`account[educations_attributes][degree_name]`, this.state.degree);
    formData.append(`account[educations_attributes][college_name]`, this.state.universityName);

    const formatDate = (year: string) => `${year}-01-01`;
    formData.append(
      `account[educations_attributes][start_date]`,
      this.state.yearAttended ? formatDate(this.state.yearAttended) : ""
    );
    formData.append(
      `account[educations_attributes][end_date]`,
      this.state.yearEnded ? formatDate(this.state.yearEnded) : ""
    );

    formData.append(`account[educations_attributes][description]`, this.state.description);
    formData.append(`account[educations_attributes][country]`, this.state.country);

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.freelancerEducationUpdateID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/account_block/freelancers/${accountId}/freelancer_profile_educations?education_id=${educationId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleChangeProficiency = (event: { target: { value: any; }; }, index: any) => {
    const updatedLanguageArray = [...this.state.languageArray];
    updatedLanguageArray[index].proficiency = event.target.value;
    this.setState({ languageArray: updatedLanguageArray });
  };

  handleChangeLanguages = (event: { target: { value: any; }; }, index: any) => {
    const updatedLanguages = [...this.state.languageArray];
    updatedLanguages[index].name = event.target.value;
    this.setState({ languageArray: updatedLanguages });
  };

  handleEditModalOpenExpertise = () => {
    this.setState({ expertiseModalOpen: true });
    const { freelancerCompleteData } = this.state;

    if (freelancerCompleteData?.data?.attributes?.profile_data.area_of_expertise) {
      this.setState({
        expertiseArray: freelancerCompleteData.data.attributes.profile_data.area_of_expertise,
        expertiseModalOpen: true,
      });
    }
  };

  handleEditModalCloseExpertise = () => {
    this.setState({ expertiseModalOpen: false });
  };

  handleExpertiseChange = (event: { target: { value: any; }; }) => {
    this.setState({ expertise: event.target.value });
  };

  handleExpertiseKeyPress = (event: { key: string; }) => {
    if (event.key === 'Enter' && this.state.expertise.trim() !== '') {
      this.setState((prevState) => ({
        expertiseArray: [...prevState.expertiseArray, prevState.expertise.trim()],
        expertise: '',
      }));
    }
  };

  handleDeleteExpertise = (expertiseToDelete: string) => {
    this.setState((prevState) => ({
      expertiseArray: prevState.expertiseArray.filter((item) => item !== expertiseToDelete),
    }));
  };

  handleSaveExpertise = () => {
    const { expertiseArray, authToken, accountId } = this.state;

    this.setState({ expertiseModalOpen: false });

    const formData = new FormData();
    expertiseArray.forEach(expertise => {
      formData.append('account[area_of_expertise][]', expertise);
    });

    const header = {
      "Accept": "application/json",
      token: authToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.freelancerAreaExpertiseUpdateID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/freelancers/${accountId}/update_area_of_expertise`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleEditModalOpenServices = () => {
    const { freelancerCompleteData } = this.state;

    const services = freelancerCompleteData?.data?.attributes?.profile_data?.services;
    if (services && services.length > 0) {
      const selectedServiceNames = services.map((service: any) => service.name);

      this.setState({
        // servicesArray: services, 
        selectedServices: selectedServiceNames,
        editServicesModalOpen: true,
      });
    }
  };

  handleEditModalCloseServices = () => {
    this.setState({ editServicesModalOpen: false });
  };

  handleServiceKeyPress = (event: { key: string; }) => {
    if (event.key === 'Enter' && this.state.service.trim() !== '') {
      this.setState((prevState) => ({
        servicesArray: [...prevState.servicesArray, prevState.service.trim()],
        service: '',
      }));
    }
  };

  handleEstimatedAmountChange = (event: { target: { value: any; }; }) => {
    this.setState({ estimatedAmount: event.target.value });
  }

  handleSkillsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ skills: event.target.value });
  };
  handleDeleteService = (serviceToDelete: string) => {
    this.setState((prevState) => ({
      servicesArray: prevState.servicesArray.filter((item) => item !== serviceToDelete),
    }));
  };

  handleSaveService = () => {
    const { selectedServices, authToken, accountId, servicesArray } = this.state;
    this.setState({ editIndustryFocusModalOpen: false });

    const selectedServicesIds = selectedServices.map((serviceName) =>
      servicesArray.find((service) => service.name === serviceName)?.id
    );
    this.setState({ editServicesModalOpen: false });

    const body = {
      account: {
        services_attributes: [
          {
            service_ids: selectedServicesIds
          }
        ]
      }
    };

    const header = {
      "Content-Type": "application/json",
      token: authToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.freelancerServiceUpdateID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/freelancers/${accountId}/freelancer_profile_services`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleSaveSocialMedia = () => {
    const { socialMediaData, authToken, accountId } = this.state;

    this.setState({ editSocialMediaModalOpen: false });

    const body = {
      account: {
        social_media_and_websites_attributes: socialMediaData.map(media => ({
          id: media.id,
          media_name: media.media_name,
          link: media.link,
          _destroy: false,
        })),
      },
    };
    const header = {
      "Content-Type": "application/json",
      token: authToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.freelancerSocialMediaUpdateID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/account_block/freelancers/${accountId}/freelancer_profile_social_media`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleFileChange = (event: any) => {
    const fileI = event.target.files[0]
    if (fileI) {
      const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg']
      if (allowedTypes.includes(fileI.type)) {
        this.setState({ profileImage: URL.createObjectURL(fileI), selectedFile: fileI, imageUploadError: '' });
      } else {
        const imageUploadError = 'Profile image: You are not allowed to upload "' + fileI.type + '" files, allowed types: jpg, jpeg, png';
        this.setState({ imageUploadError })
      }
    }
  }

  handleUpdateProfileImage = async () => {
    this.setState({ editProfileModalOpen: false });
    const updateProfileImage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      token: this.state.authToken
    };
    const formdata = new FormData();

    formdata.append("[data][attributes][profile_image]", this.state.selectedFile);

    this.updateFreelancerProfileImageId = updateProfileImage.messageId;
    updateProfileImage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/${this.state.accountId}/update_freelancer_profile_image`
    );
    updateProfileImage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    updateProfileImage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    )
    updateProfileImage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.updateUserMethodeType
    );
    runEngine.sendMessage(updateProfileImage.id, updateProfileImage);
  }
  handleEditProfileImage = () => {
    const fileInput = this.state.fileInputRef.current;
    if (fileInput) {
      fileInput.click()
    }
  }


  freelancerCompleteProfileData = () => {
    const header = {
      "Content-Type": "application/json",
      token: this.state.authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.freelancerCompleteDataId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/freelancers/get_profile_freelancer`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  freelancerTitleUpdate = () => {
    const { title } = this.state
    const header = {
      "Content-Type": "application/json",
      "token": this.state.authToken
    };
    let body = {
      "account": {
        "job_title": title
      }
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.freelancerTitleUpdateID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/freelancers/${this.state.accountId}/copy_right_freelancer`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;

  };

  getFreelancerService = () => {
    const header = {
      "Content-Type": "application/json",
      "token": this.state.authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.freelancerServiceID = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/account_block/accounts/services`
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getFreelancerIndustry = () => {
    const header = {
      "Content-Type": "application/json",
      "token": this.state.authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.freelancerIndustryID = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/account_block/accounts/industries`
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  getFreelancerServiceFees = () => {
    const header = {
      "Content-Type": "application/json",
      "token": this.state.authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.serviceFeesID = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/account_block/accounts/get_service_fee`
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  getJobTitle = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.authToken
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.jobTitleID = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/account_block/accounts/get_job_titles_to_admin`
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  // Customizable Area End
}