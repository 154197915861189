import { IBlock } from 'framework/src/IBlock';
import { BlockComponent } from 'framework/src/BlockComponent';
import { runEngine } from 'framework/src/RunEngine';
// Customizable Area Start
import MessageEnum, { getName } from '../../../framework/src/Messages/MessageEnum';
import { Message } from "../../../framework/src/Message";
import storage from 'framework/src/StorageProvider.web';
// Customizable Area End
export const webConfigJSON = require('./config.js');

export interface Props {
    navigation: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    activeButton: string;
    age:  string;
    open: boolean;
    showJobs:boolean;
    date:string;
    activeTab: any
    activeDetailsData: any;
    authToken:string
    value:any;
    uploadedFileName: any
    isOpenModal: boolean
    succesMessage: string
    milestoneDetailsData: any
    milestoneupdate: boolean
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    // Customizable Area End
}

  // Customizable Area Start
  // Customizable Area End

export default class FreelancerActiveContractController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    contractDetailsId: string = ""
    submitContractsId: string = ""
    uploadWorkId: string = ""
    milestoneDetailsId: string = ""
    // Customizable Area End
    constructor(props:Props){
        super(props) ;
        this.receive=this.receive.bind(this);
    this.subScribedMessages=[getName(MessageEnum.RestAPIResponceMessage)];
        // Customizable Area Start

        this.state={
            age: "",
            open: false,
            showJobs:false,
            date:"",
            activeButton: "offers",
            activeTab: 0,
            activeDetailsData:[], 
            authToken:'',
            value: '',
            uploadedFileName: '',
            isOpenModal: false,
            succesMessage: '',
            milestoneDetailsData: [],
            milestoneupdate:false
        };

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area End
    }

    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start
        this.setState({
            authToken: await storage.get('authToken'),
        });
        this.getActiveMilestoneDetails()
        this.getActiveContractDetails()
        // Customizable Area End
    }



    // Customizable Area Start
    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {
          
          // return;
        }
    
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
          const webApiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
          );
      
          let webResponseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
          
         this.handleActiveContractRes(webResponseJson,webApiRequestCallId)
         this.handleSubmitProjectTrackingRes(webResponseJson,webApiRequestCallId)
         this.handleMilestoneContractRes(webResponseJson,webApiRequestCallId)
         this.handleUploadWorkRes(webResponseJson,webApiRequestCallId)
        }
        // Customizable Area End
      }
    // Customizable Area End
    
    // Customizable Area Start

                
    handleSubmitMilestone = (id: any, milestoneData: any) => {

        const submitContracts = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
      
        const header = {
          "token": this.state.authToken
        };
      
        const formData = new FormData();
        formData.append('milestone[description]', milestoneData.description);
        formData.append('milestone[date]', milestoneData.date);
      
        this.submitContractsId = submitContracts.messageId;
        submitContracts.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `bx_block_contract/milestones/${id}/update_milestone_by_freelancer`
        );
        submitContracts.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        submitContracts.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          formData 
        );
        submitContracts.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          'PATCH'
        );
        runEngine.sendMessage(submitContracts.id, submitContracts);
      };

      getActiveContractDetails = async () => {
    
        const header = {
            token: this.state.authToken,
            "Content-Type": webConfigJSON.validationApiContentType,
        };
    
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.contractDetailsId = requestMessage.messageId;
        const paramId = this.props.navigation.getParam("id")
    
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_contract/contracts/contract_details?id=${paramId}`
        );
    
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );
    
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    };

        handleSubmitProjectTrackingRes = (webResponseJson: { data: any; error: 'string',message: 'string' } | undefined,webApiRequestCallId: string | undefined) =>{
        if (webResponseJson) {
        if (webApiRequestCallId === this.submitContractsId) {
                this.setState({
                    isOpenModal: true,
                    succesMessage: webResponseJson?.message
                })
              if(webResponseJson?.data){
                this.setState({
                    isOpenModal: true,
                    succesMessage: 'Milestone has been successfully updated',
                    milestoneupdate: true
                })
              }else{
                this.setState({
                    isOpenModal: true,
                    succesMessage: webResponseJson?.error,
                })
              }
            }
           
          }
      }

      handleMilestoneContractRes = (webResponseJson: { data: any; error: 'string' } | undefined,webApiRequestCallId: string | undefined) =>{
        if (webResponseJson) {
        if (webApiRequestCallId === this.milestoneDetailsId) {
              if(webResponseJson?.data){
                this.setState({ milestoneDetailsData: webResponseJson?.data })
              }
                         }
          }
      }

      handleChanges = (event: any ) => {
        this.setState({date: event.target.value as string
        })
    };

      handleUploadWorkRes = (webResponseJson: { data: any; error: 'string' } | undefined,webApiRequestCallId: string | undefined) =>{
        if (webResponseJson) {
        if (webApiRequestCallId === this.uploadWorkId) {
              if(webResponseJson?.error){
                this.setState({ 
                  succesMessage: webResponseJson?.error[0] ,
                  isOpenModal: true,
                })
              }
                         }
          }
      }
      
    handleActiveContractRes = (webResponseJson: { data: any; error: 'string' } | undefined,webApiRequestCallId: string | undefined) =>{
        if (webResponseJson) {
        if (webApiRequestCallId === this.contractDetailsId) {
              if(webResponseJson?.data){
                this.setState({ activeDetailsData: webResponseJson?.data })
              }
            }
          }
      }


      handleCloseModal = () =>{
        this.setState({isOpenModal : false})
        this.getActiveMilestoneDetails()
      }

      handleInputChange = (index: number, field: string, value: string) => {
        const updatedMilestones = [...this.state.milestoneDetailsData];
        updatedMilestones[index] = {
          ...updatedMilestones[index],
          attributes: {
            ...updatedMilestones[index].attributes,
            [field]: value,
          },
        };
        this.setState({ 
            milestoneDetailsData: updatedMilestones
         });
      };
      
      

    handleOpen = () => {
        this.setState({ 
            open: true
         });
    };

 
      handleInputChangeS = (event: any) => {
        this.setState({ value: event.target.value });
      };
    
      handleFileUpload = (event: any, id: number) => {
        const file = event.target.files?.[0];
        if (file) {
          const updatedMilestoneDetails = [...this.state.milestoneDetailsData];
          const milestoneIndex = updatedMilestoneDetails.findIndex(
            (milestone) => String(milestone.id) === String(id)
          );
      
          if (milestoneIndex !== -1) {
            updatedMilestoneDetails[milestoneIndex].attributes.upload_works = [{ name: file.name, file }];
      
            this.setState({ milestoneDetailsData: updatedMilestoneDetails }, () => {
              const milestoneData = updatedMilestoneDetails[milestoneIndex];
              this.handleUploadWorkApi(id, milestoneData);
            });
          } 
        } 
      };
      
      
      handleUploadWorkApi = (id: number, milestoneData: any) => {
        const uploadWorks = milestoneData?.attributes?.upload_works;
        if (!uploadWorks || !uploadWorks[0]?.file) {
          return;
        }
      
        const file = uploadWorks[0].file;
        const formData = new FormData();
        formData.append('milestone[upload_works][]', file);
      
        const header = { token: this.state.authToken };
      
        const submitContracts = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.uploadWorkId = submitContracts.messageId;
      
        submitContracts.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `bx_block_contract/milestones/${id}/upload_work_attachments`
        );
        submitContracts.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        submitContracts.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);
        submitContracts.addData(getName(MessageEnum.RestAPIRequestMethodMessage), 'PATCH');
      
        runEngine.sendMessage(submitContracts.id, submitContracts);
      };
      
      getActiveMilestoneDetails=async()=>{
        const header={
          "Content-Type": webConfigJSON.validationApiContentType,
            token: this.state.authToken,
        }
    
        const requestMessage=new Message(
             getName(MessageEnum.RestAPIRequestMessage))
    
        const paramId=this.props.navigation.getParam("id")
        this.milestoneDetailsId=requestMessage.messageId
    
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_contract/milestones/get_proposal_milestones?proposal_generation_id=${paramId}`)
  
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET")
    
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header))
    
        runEngine.sendMessage(requestMessage.id, requestMessage)
        return true;
    };
    
      handleTabChange = (event: any, newValue: number) => {
        this.setState({ activeTab: newValue }, () => {
            this.getActiveMilestoneDetails();
            this.getActiveContractDetails()
        });
    };
    

    // Customizable Area End
}

     // Customizable Area Start
    // Customizable Area End  
