import React from "react";

// Customizable Area Start
import { createTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import {
  Box,
  Button,
  Checkbox,
  OutlinedInput,
  Typography,
  InputAdornment,
  IconButton,
  styled
} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { login,ellipse } from "./assets";
import { Horizontalinventohub } from "../../landingpage/src/assets";

const styles = {

  overflowStyle:{
    overflow:"hidden"
  },
  image: {
    width: "50%",
    height: "100%",
    position:"absolute" as "absolute",
    right:0,
    top: 0,
    objectPosition:"top right",
    "@media (max-width: 768px)": {
      display: "none"
    }
  },
  heading: {
    fontSize: "36px",
    fontWeight: 700,
    color: "#000000",
    marginTop: "40px",
    marginBottom: "30px",
    fontFamily: "Rubik"
  },
  subHeading: {
    fontSize: "20px",
    fontWeight: 500,
    marginBottom: "31px",
    color: "#6F6E6E",
    fontFamily: "Rubik",
  },
  headingSign: {
    fontSize: "32px",
    fontWeight: 500,
    color: "#364BA0",
    marginBottom: "31px",
    fontFamily: "Rubik"
  },
  headingSign2: {
    cursor: "pointer",
    fontSize: "16px",
    fontWeight: 700,
    color: "#206FC4",
    fontFamily: "Rubik"
  },
  formStyle2: {
    maxWidth: "432px",
  },

  textField: {
    width: "100%",
    "&.MuiFormLabel-root.Mui-focused ": {
      color: "#6F6E6E !important",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline ": {
      borderColor: "#6F6E6E !important",
    },
  },
  boxstyle: {
    marginBottom: "27px",
  },
  signinButton: {
    marginBottom: "24px",
    height: "55px",
    backgroundColor: "#206FC4",
    color: "#FFFFFF",
    width: "100%",
    fontSize: "16px",
    fontWeight: 700,

  },
  boxstyle2: {
    display: "flex",
    marginTop: "12px",
    width: "100%",
    marginBottom: "36px",
    alignItems: "center",
    justifyContent: "space-between",
    "@media(max-width:600px)": {
      display: "block",
    },
  },
  typography: {
    display: "block",
    color: "#848484",
    fontFamily: "Rubik",
    fontSize: "16px",
    fontWeight: 400,
  },
  labelStyle: {
    marginBottom: "12px",
    fontSize: "20px",
    fontWeight: 500,
    fontFamily: "Rubik",
  },
  maingrid: {
    width:"100%",
    "@media(max-width:600px)": {}
  },
  signUpLink:{
    color: "#364BA0",
    cursor:"pointer",
    fontFamily: "Rubik",
    fontWeight: 700,
    borderBottom: "2px solid",
  },
  formError:{
    color:'red',
    fontSize:14,
    fontFamily: "Rubik",
  },
  ellipseImg:{
    position:'absolute' as 'absolute',
    left:'0',
    width:'240px',
    bottom:'0'
  },
  invento:{
    objectFit:"cover",
     marginBottom: "45px",
     width:"264px",
     height:"48px",
      marginTop: "42px",
     cursor: "pointer"
  },
};

const MainContainer = styled(Box)({
  paddingInline: 20,
  '@media (max-width: 1200px)': {
  '& .password-options': { 
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start !important',
    gap: '8px', 
  },
  '& .forgot-password': {
      marginLeft: "12px !important", // Additional specificity if needed
    }
},
  '@media (max-width: 959px)': {
    paddingInline: 15,
    '& .dots-img': {
      display: 'none',
    },
  },
  '@media (max-width: 800px)': {
  '& .password-options': { 
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start !important',
    gap: '8px', 
  }
},
  '@media (max-width: 600px)': {
    paddingInline: 10,
    margin:"auto",
    '& .horizontalinventohub': {
      marginLeft: "0px !important", // This ensures marginLeft is removed
    },
    '& img.horizontalinventohub': {
      marginLeft: "0px !important", // Additional specificity if needed
    },
    '& .forgot-password': {
      marginLeft: "12px !important", // Additional specificity if needed
    }
  },
});
// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  render() {
      // Customizable Area Start
      const signinButtonStyle = {
        marginBottom: "24px",
        height: "55px",
        backgroundColor: this.state.isHovered ? " #004de6" : "#364BA0",
        color: "#FFFFFF",
        width: "100%",
        fontSize: "16px",
        fontWeight: 700,
        fontFamily:"Rubik",
        borderRadius:"10px",
        textTransform: "none" as "none",
        "::first-letter": {
          textTransform: "uppercase"
        }
      };
  
      // Customizable Area End
    return (
      // Customizable Area Start
      // Required for all blocks
      <MainContainer>
      <Grid container spacing={0} >
        <Grid container item xs={12} md={5} justifyContent="center">
          <Grid item xs={11} sm={9}>
            <img className="horizontalinventohub" src={Horizontalinventohub} style={styles.invento as React.CSSProperties} onClick={this.handleLogoClick} data-test-id="handleLogoClick"/>
              <Typography style={styles.headingSign}>SIGN IN</Typography>
                <Box style={styles.boxstyle}>
                  <Typography style={styles.labelStyle}>Email:<span style={{ fontWeight: 800,
    color: "#FF5E5B",}}>*</span></Typography>
                  <FormControl style={styles.textField} variant="outlined">
                    <OutlinedInput
                      data-test-id="txtInputEmail"
                      id="outlined-adornment-email"
                      placeholder="Enter your email address"
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        "aria-label": "Email",
                        style: {
                          fontSize: "16px",
                          fontWeight: 500,
                          fontFamily: "Rubik",
                          color: "#000000",
                        },
                      }}
                      value={this.state.email} 
                      onChange={(e) => this.handleEmailNameChange(e)}
                    />
                  </FormControl>
                  {this.state.emailErr && (
                    <div style={styles.formError}>{this.state.emailErr}</div>
                  )}
                </Box>
                <Box >
                  <Typography style={styles.labelStyle}>Password:<span style={{ fontWeight: 800,
                     color: "#FF5E5B",}}>*</span></Typography>
                  <FormControl style={styles.textField} variant="outlined">
                  <OutlinedInput
                    data-test-id="txtInputPassword"
                    placeholder="Create your password"
                    id="outlined-adornment-password"
                    type={this.state.showPassword ? "text" : "password"} 
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      "aria-label": "password",
                      style: {
                        fontSize: "16px",
                        fontWeight: 500,
                        fontFamily: "Rubik",
                        color: "#000000",
                      },
                    }}
                    onPaste={(e) => e.preventDefault()} 
                    value={this.state.password} 
                    onChange={(e) => this.handlePasswordChange(e)} 
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={this.togglePasswordVisibility}
                          edge="end"
                        >
                          {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />

                  </FormControl>
                  {this.state.passwordErr && (
                    <div style={styles.formError}>{this.state.passwordErr}</div>
                  )}
                </Box>

                <Box style={styles.boxstyle2} className="password-options">
                  <Typography style={styles.typography}>
                    <Checkbox
                      data-test-id={"btnRememberMe"}
                      color="default"
                      checked={this.state.rememberMe}
                      inputProps={{ "aria-label": "secondary checkbox" }}
                      onClick={(e)=>this.handleRememberMeChange(e)}
                      tabIndex={0}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' || e.key === ' ') {
                          e.preventDefault();
                          this.handleRememberMeChange(e); 
                        }
                      }}
                    
                    />
                    Remember Password
                  </Typography>
                  <Typography
                    data-test-id={"btnForgotPassword"}
                    style={{...styles.typography,cursor:"pointer"}}
                    onClick={this.handleForgotPasswordClick} 
                    tabIndex={0}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' || e.key === ' ') {
                        e.preventDefault();
                        this.handleForgotPasswordClick(); 
                      }
                    }}
                    className="forgot-password"
                  >
                    Forgot Password?
                  </Typography>
                </Box>
                <Button
                  variant="contained"
                  data-test-id={"btnEmailLogIn"}
                  id="emailButton"
                  style={signinButtonStyle}
                  onClick={this.loginAPI}
                  onMouseEnter={() => this.setState({isHovered:true})}
                  onMouseLeave={() => this.setState({isHovered:false})}
                  tabIndex={0}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      e.preventDefault();
                      this.loginAPI()          
                    }
                  }}
                >
                  SIGN IN
                </Button>
                <Typography style={{...styles.subHeading, position: 'relative', zIndex: 9}}>
                  Don't have an account?{" "}
                    <span style={styles.signUpLink} data-test-id="btnSocialLogin" onClick={this.handleGoToSocialLogin} 
                     tabIndex={0}
                     data-testid="SocialLoginTest"
                     onKeyDown={(e) => {
                       if (e.key === 'Enter' || e.key === ' ') {
                         e.preventDefault();
                         this.handleGoToSocialLogin(); 
                       }
                     }}>
                      SIGN UP
                    </span>
                </Typography>
              </Grid>
          </Grid>
          <Grid item xs={12} md={7} className="dots-img">
            <img src={login} style={styles.image} alt="Login" />
          </Grid>
        </Grid>
        <img src={ellipse} alt="ellipse" style={styles.ellipseImg}/>
      </MainContainer>
      // Customizable Area End
    );
  }
}

