import { IBlock } from 'framework/src/IBlock';
import { BlockComponent } from 'framework/src/BlockComponent';
import { runEngine } from 'framework/src/RunEngine';
import MessageEnum, { getName } from '../../../framework/src/Messages/MessageEnum';
import storage from 'framework/src/StorageProvider.web';
// Customizable Area Start
import { Message } from "../../../framework/src/Message";
// Customizable Area End
export const webConfigJSON = require('./config.js');


export interface Props {
    navigation: any;
    // Customizable Area Start
    // Customizable Area End
}
interface S {
    // Customizable Area Start 
    activeTab: number | string,
    activeContractData: any,
    selectedProfessional: string,
    startDate: string,
    endDate: string,
    authToken: string,
    dateError: string,
    FreelancerDetailsData: any,  
    // Customizable Area End
}
interface SS {
    // Customizable Area Start
    // Customizable Area End
}

export default class ActiveContractControllerClient extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    freelancerListId: string = ""
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
        this.state = {
            activeTab: 0,
            selectedProfessional: "All Professionals",
            startDate: new Date().toISOString().split('T')[0],
            dateError: '',
            endDate: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
                .toISOString()
                .split('T')[0],
            authToken: "",
            activeContractData: [],
            FreelancerDetailsData: [],         
        }
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start

        this.setState({
            authToken: await storage.get('authToken'),
        });
        this.getActiveContract();
        this.getProfessionals();
        // Customizable Area End

    }

    // Customizable Area Start
    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {

            // return;
        }

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const webApiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let webResponseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );


            this.handleActiveContractRes(webResponseJson, webApiRequestCallId)
            this.handleGetProfessionalsRes(webResponseJson, webApiRequestCallId)
        }
        // Customizable Area End
    }
    // Customizable Area End

    // Customizable Area Start
    handleStartDateChange = (event: { target: { value: any; }; }) => {
        const startDate = event.target.value;
        this.setState(
            { startDate },
            () => {
                this.validateDates();
                this.getActiveContract();
            }
        )
    }
    handleEndDateChange = (event: { target: { value: any; } }) => {
        const endDate = event.target.value;
        this.setState({ endDate },
            () => {
                this.validateDates();
                this.getActiveContract();
            }
        )
    }
    validateDates = () => {
        const { startDate, endDate } = this.state;
        if (endDate && startDate && new Date(endDate) < new Date(startDate)) {
            this.setState({
                dateError: "End date cannot be earlier than start date.",
            });
        } else {
            this.setState({ dateError: "" });
        }

    };
    handleActiveContractRes = (webResponseJson: { data: any; error: 'string' } | undefined, webApiRequestCallId: string | undefined) => {
        if (webResponseJson) {

            if (webResponseJson?.data) {
                this.setState({ activeContractData: webResponseJson?.data })
            }
        }

    }
    handleGetProfessionalsRes = (webResponseJson: { data: any; error: 'string' } | undefined, webApiRequestCallId: string | undefined) => {
        if (webResponseJson) {

            if (webApiRequestCallId === this.freelancerListId) {
                if (webResponseJson?.data) {
                    this.setState({ FreelancerDetailsData: webResponseJson?.data })
                }
            }
        }
    }
    goToActiveContract = () => {
        this.props.navigation.navigate("ActiveContractDetailsClient");
    }
    handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        this.setState({ activeTab: newValue })
    }
    handleDropdownChange = (event: any) => {
        console.log("Selected value:", event.target.value);
        this.setState({ selectedProfessional: event.target.value },
            () => {
                this.getActiveContract();
            }
        )
    }
    getActiveContract = async () => {
        const header = {
            "Content-Type": webConfigJSON.contentType,
            "token": this.state.authToken
        }

        let endpoint = `bx_block_contract/contracts/client_side_active_contracts`;
        let queryString = "";

        if (this.state.selectedProfessional !== "All Professionals") {
            queryString += `account_id=${this.state.selectedProfessional}&`
        }
        if (this.state.startDate) {
            queryString += `start_date=${this.state.startDate}&`;
        }

        if (this.state.endDate) {
            queryString += `end_date=${this.state.endDate}&`;
        }

        if (queryString) {
            queryString = queryString.slice(0, -1);
            endpoint += `?${queryString}`;
        }

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endpoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;

    }
    getProfessionals = async () => {      
        const header = {
            "Content-Type": webConfigJSON.contentType,
            "token": this.state.authToken
        }

        let endpoint = `bx_block_contract/contracts/get_my_active_contract_freelancers`;
        let queryString = "";

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.freelancerListId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endpoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    }
    // Customizable Area End
}