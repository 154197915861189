// Customizable Area Start
import React, { ChangeEvent, Component, ReactElement } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import MenuItem from '@material-ui/core/MenuItem';
import Popper from '@material-ui/core/Popper';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { Button, Popover, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Menu, styled } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { profile ,messageIcon} from '../assets';
import { clientlogo } from './assets';
import storage from 'framework/src/StorageProvider';
import SettingsIcon from '@material-ui/icons/Settings';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { Message } from 'framework/src/Message';
import MessageEnum, { getName } from 'framework/src/Messages/MessageEnum';
import { runEngine } from 'framework/src/RunEngine';
import { IBlock } from 'framework/src/IBlock';
import MenuIcon from '@material-ui/icons/Menu';

const configJSON = require('../config.js');

const StyledMenuItem = withStyles(() => ({
  root: {
    color: '#000',
    fontSize: '12px',
    fontWeight: 500,
    width: 'inherit',
    height: '32px',
    '&:focus': {
      backgroundColor: 'aliceblue',
      borderLeft: '2px solid blue',
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: 'white'
      }
    },
    '&:hover': {
      backgroundColor: 'aliceblue',
      borderLeft: '2px solid blue'
    }
  }
}))(MenuItem);
// Customizable Area End
// Customizable Area Start

interface Props {
  profileImg?: string;
}
interface OptionItem {
  options: any;
  label: string;
  path?: string;
  profileAnchorEl:null|HTMLDivElement
  currentStatus:number;
  profileImage: any
  accountHolderName:string |null;
  updateOnlineStatusId:string;
  getProfileStatusId:string; 
  isTokenExpired:boolean;
  userType: string

}
// Customizable Area End

class ClientNavBar extends Component<Props> {
  constructor(props: any) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    runEngine.attachBuildingBlock(this as unknown as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  [x: string]: any;
  state = {
    anchorEl: null,
    isFromHomePage: false, 
    searchValue: '',
    profileAnchorEl:null,
    currentStatus:0,
    accountHolderName:"",
    updateOnlineStatusId:"",
    getProfileStatusId:"",
    isTokenExpired:false,
    userType: "",
    profileImage: '',
    anchorElNav: null,

  };
  getColorValue = (statusVal:number)=>{
    const activeBtn =  this.state.currentStatus == statusVal
    let   colorItem = {
      color:"#ffffff",
      backgroundColor:"#2275CD"
    };
    if(!activeBtn){
           
          colorItem= {
              color:"#2275CD",
              backgroundColor:"#ffffff"
            }

    }
      return colorItem;
 }
  
 handleClick = (event: any): void => {
  const { anchorEl } = this.state;
  if (Boolean(anchorEl)) {
    // If the same item is clicked again, close the popover
    this.setState({ anchorEl: null });
  } else {
    this.setState({ anchorEl: event.currentTarget });
  }
};
  updateOnlineStatus = async(currentStatus:number) => {
    const updateOnlineStatusRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      'Content-Type': configJSON.apiContentType,
      token: await storage.get('authToken')
    };
    

    this.state.updateOnlineStatusId = updateOnlineStatusRequest.messageId;
    updateOnlineStatusRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateClientProfileStatusEndPoint +`?online_status=${currentStatus===0?"online":"away"}`
        
    );
    updateOnlineStatusRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    updateOnlineStatusRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchApiMethodType
    );

    runEngine.sendMessage(
      updateOnlineStatusRequest.id,
      updateOnlineStatusRequest
    );
  };

  getProfileStatus =async () => {
    const getProfileStatusRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      'Content-Type': configJSON.apiContentType,
      token: await storage.get('authToken')
    };
    

    this.state.getProfileStatusId = getProfileStatusRequest.messageId;
    getProfileStatusRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getClientProfileStatusEndPoint
        
    );
    getProfileStatusRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    getProfileStatusRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    )

    runEngine.sendMessage(getProfileStatusRequest.id,getProfileStatusRequest
    )
  }

  handleResponse = (responseJson:{errors?:any; exception?: string; current_user: {
    profile_image: any; online_status: string; 
}}) => {
    if(responseJson.exception?.includes("#<JWT") ||responseJson.errors?.some((error: any) => error.toLowerCase().includes("token"))){
      this.setState({isTokenExpired: true})

    }else {
      this.setState({ currentStatus: responseJson.current_user.online_status =="away"?1:0,
        profileImage : responseJson?.current_user.profile_image?.url
       });

    }
  }
  async componentDidMount() {

    let accountHolderName = await storage.get("accountHolderName")
    this.setState({
      accountHolderName:accountHolderName 
    });
    this.setState({
      userType: await storage.get('userType'),
    });
    this.getProfileStatus()
  }
  handleredirection = async () => {
    const first_login = await storage.get("is_first_time_login");
  
    if (first_login !== "null") {
      window.location.pathname = '/postjob';
    } else {
      window.location.pathname = '/JobPosting';
    }
  };
  
  handleRedirect = (path: any) => {
    this.setState({ anchorEl: null });
    if (path === '/postjob') {
      this.handleredirection();
    } else if (path) {
      window.location.pathname = path;
    }
  };
  
  handleOpenNavMenu = (event: { currentTarget: any; }) => {
    console.log(event.currentTarget,"currentTarget")
    this.setState({ anchorElNav: event.currentTarget });
};
  StyledMenuItemFun(){
    return    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' },justifyContent:"end",alignItems:"center" }}>
    <IconButton
                          aria-controls="menu-appbar"
                          aria-haspopup="true"
                          aria-label="open navigation menu"
                          onClick={this.handleOpenNavMenu}
                          style={{
                            padding: 0
                          }}
                          color="inherit" >
                          <MenuIcon style={{color:"#364BA0"}} />
                      </IconButton>
                      <Custom_Menu_design
                          id="menu-appbar"
                          anchorEl={this.state.anchorElNav}
                          getContentAnchorEl={null} 
                          keepMounted
                          anchorOrigin={{
                              vertical: 'bottom',  horizontal: 'right',  }}
                            transformOrigin={{
                              horizontal: 'right',
                              vertical: 'top',
                            }}
                          open={Boolean(this.state.anchorElNav)}
                          onClose={this.handleCloseNavMenu} >
                          <Box onClick={this.handleCloseNavMenu}>
                          <Box className='menu_item_wrapper'  style={styles.boxStyle}>
          {configJSON.navbarMenu.map((item: any, index: number) => (
            <Typography
            variant="h6"
              key={item.label}
              aria-describedby={`popover-${item.label}`}
              onMouseEnter={
                item.options
                  ? event => { this.handlePopoverOpen(event);
                      event.currentTarget.style.color = '#206FC4'
                    }
                  : undefined
              }
              onMouseLeave={
                item.options ? event => {
                  event.currentTarget.style.color = '#4A4A4A'; 
                    this.handlePopoverClose();
                  }
                  : undefined
            }
              onClick={
                item.options
                  ? this.handleClick
                  : () => 
                    this.handleRedirection(item.componentName) 
              }
              className='menu_item'
              data-testid={`navbar-option-${index}`}
            >
                           {item.label}
              {item.options &&
                (Boolean(this.state.anchorEl) && this.state.anchorEl &&
                ((this.state.anchorEl as unknown) as HTMLElement).getAttribute('aria-describedby') ===
                  `popover-${item.label}` ? (
                  <ExpandLessIcon />   ) : (   <ExpandMoreIcon />
                ))}
              {item.options && (
                <Popper
                id={`popover-${item.label}`}
                  key={`popover-${item.label}`}
                  open={
                    Boolean(this.state.anchorEl) &&
                    ((this.state
                      .anchorEl as unknown) as HTMLElement).getAttribute(
                      'aria-describedby'
                    ) === `popover-${item.label}`
                  }
                  // open={
                  //   Boolean(this.state.anchorEl) &&  this.state.anchorEl && ((this.state.anchorEl as unknown) as HTMLElement).getAttribute(
                  //     'aria-describedby'
                  //   ) === `popover-${item.label}`
                  // }
                  anchorEl={this.state.anchorEl}
                  disablePortal={true}
                  placement="bottom"
                  modifiers={{
                    flip: {  enabled: true },
                    preventOverflow: { enabled: true,  boundariesElement: 'scrollParent'   },
                    arrow: {
                      element: this.state.anchorEl,
                      enabled: true,
                    }
                  }}
                  style={{
                    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                    zIndex: 1,
                    padding: '10px 5px',
                    background: '#FFF',
                    width: 'inherit'
                  }}
                >
                  {item.options.map((option: any, index: number) => (
                    <StyledMenuItem
                      key={option.label}
                      onClick={() => this.handleRedirection(option.componentName) }
                      data-test-id="tastItem"
                      style={styles.options}
                    >
                      {option.label}
                           </StyledMenuItem>
                  ))}
                         </Popper>
              )}
            </Typography>
          ))}</Box>
                          </Box>
                      </Custom_Menu_design>
    </Box>
  }

  handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handlePopoverClose = () => {
    this.setState({ anchorEl: null });
  };
  keyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
        const { searchValue } = this.state;
        storage.set('searchedValue', searchValue); // Store search value
        this.handleRedirect('/searchtalent'); // Navigate to search talent page
    }
};

  handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchValue: event.target.value });
  };
  handleClose = () => {
    this.setState({ profileAnchorEl: null });
  };
  handleProfileClick = (event:React.MouseEvent<HTMLImageElement, MouseEvent>)=> {
    this.setState({profileAnchorEl:event.currentTarget});
  };
  handleLogout=(componentName: any)=>{
    storage.remove("authToken");
    storage.remove("accountId");
    storage.remove("accountHolderName");
    storage.remove("userType")
    this.handleRedirect(componentName)
  }
   async receive(_from: string, _message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === _message.id) {
      const requestCallId = _message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if(this.state.getProfileStatusId === requestCallId){
        const responseJson = _message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
       
        if(responseJson){
          this.handleResponse(responseJson);
          storage.set("isTwofactorEnableClient", responseJson?.current_user?.is_twofactor_enable);
          storage.set("clientLastPasswordUpdatedAt", responseJson?.last_password_updated_at);

        } 
      }
    }
  }
  // Customizable Area End
  // Customizable Area Start
  render(): ReactElement {
    const open = Boolean(this.state.profileAnchorEl);    
    return (
      <div style={styles.root}>
        <AppBar position="static">
          <Toolbar style={styles.toolBar}>
            <CustomNavBar style={styles.boxStyle}>
            <img src={clientlogo} className='clientlogo'
             onClick={() => this.handleRedirect('/clienthomepage')} data-test-id='handleRedirect'/>
<Box sx={{ flexGrow: 1, display: {  xs: 'none', md: 'flex' },justifyContent:"end",alignItems:"center" }}>
              <Box style={styles.boxStyle}>
                {configJSON.navbarMenu.map(
                  (item: OptionItem, index: number) => (
                    <Typography
                      key={item.label}
                      variant="h6"
                      aria-describedby={`popover-${item.label}`}
                      onMouseEnter={
                        item.options
                          ? (
                              event: React.MouseEvent<HTMLElement, MouseEvent>
                            ) => {
                              this.handlePopoverOpen(event);
                            }
                          : undefined
                      }
                      onMouseLeave={
                        item.options ? this.handlePopoverClose : undefined
                      }
                      onClick={
                        item.options
                          ? this.handleClick
                          : () => this.handleRedirect(item.path)
                      }
                      style={styles.menuItem}
                      data-testid={`navbar-option-${index}`}
                    >
                      {item.label}
                      {item.options &&
                        (Boolean(this.state.anchorEl) &&
                        ((this.state
                          .anchorEl as unknown) as HTMLElement).getAttribute(
                          'aria-describedby'
                        ) === `popover-${item.label}` ? (
                          <ExpandLessIcon />
                        ) : (
                          <ExpandMoreIcon />
                        ))}

                      {item.options && (
                        <Popper
                          key={`popover-${item.label}`}
                          id={`popover-${item.label}`}
                          open={
                            Boolean(this.state.anchorEl) &&
                            ((this.state
                              .anchorEl as unknown) as HTMLElement).getAttribute(
                              'aria-describedby'
                            ) === `popover-${item.label}`
                          }
                          anchorEl={this.state.anchorEl}
                          placement="bottom"
                          disablePortal={true}
                          modifiers={{
                            flip: {
                              enabled: true
                            },
                            preventOverflow: {
                              enabled: true,
                              boundariesElement: 'scrollParent'
                            },
                            arrow: {
                              enabled: true,
                              element: this.state.anchorEl
                            }
                          }}
                          style={{
                            background: '#FFF',
                            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                            zIndex: 1,
                            padding: '10px 5px',
                            width: 'inherit'
                          }}
                        >
                          {item.options.map(
                            (option: OptionItem, index: number) => (
                              <StyledMenuItem
                                key={option.label}
                                onClick={() => this.handleRedirect(option.path)}
                                style={styles.options}
                                data-testid={`menu-option-${index}`}
                              >
                                {option.label}
                              </StyledMenuItem>
                            )
                          )}
                        </Popper>
                      )}
                    </Typography>
                  )
                )}
              </Box>
              </Box>
            </CustomNavBar>

            <Box style={styles.sectionRight}>
              {!this.state.isFromHomePage && (
                <Avatar
                  alt="image"
                  style={{
                    ...styles.imgAvatar,
                    overflow: 'visible',
                    height: '45px',
                    width: '45px'
                  }}
                  src={messageIcon}
                />
              )}
              {console.log(this.state.profileImage,"this.state.profileImage")}
              <Avatar
                  alt="image"
                  src={this.props.profileImg || this.state.profileImage}
                  data-testid={"profileImage"}
                  style={{
                    ...styles.imgAvatar,
                   
                    height: '45px',
                    width: '45px'
                  }}
                  onClick={this.handleProfileClick} // onClick={() => this.handleRedirect('settings')}
                />
                 <Popover
              data-testid="account_card"
                open={open}
                anchorEl={this.state.profileAnchorEl}
                onClose={this.handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center'
                }}
                
              
              >
               <Box style={{padding: '7px 0',display: 'grid',gap: '7px',minWidth:"245px"}}>
               <Box
               display={"flex"}
               flexDirection={"column"}
               justifyContent={"center"}
               alignItems={"center"}
               style={{paddingTop:"10px"}}>
                
                <Avatar

                alt="image"
                src={this.props.profileImg || this.state.profileImage}
                style={{
                
                  height: '88px',
                  width: '88px',
                  borderRadius: '50%'
                }}
                
              />
              <Typography style={{fontWeight: 500,fontFamily: 'Inter',fontSize:"16px",marginTop:"10px",padding:"0 15px",color:"#181818"}}>{this.state.accountHolderName}</Typography> 
              <Typography style={{fontWeight:400,fontFamily: 'Inter',fontSize:'11px',color:"#676767"}}>{this.state.userType}</Typography>          
              </Box>
              {/* online/offline byttons */}
   <Box
      style={{
        border: "2px solid #d9d9d9",
        borderRadius: "10rem",
        display:"flex",
        justifyContent: "center",
        margin:"0px 10px"
      }}
    >
      <Button
        variant={this.state.currentStatus === 0 ? "contained" : "text"}
        size="medium"
        style={{
          margin: 0,
          padding:"10px",
          borderRadius: "10rem",
          backgroundColor:this. getColorValue(0).backgroundColor,
          fontWeight: 500,
          fontFamily: 'Inter',
          textTransform:"none",
          color: this. getColorValue(0).color,
          flex:0.5,
        }}
        data-testid="prf-online"
        onClick={() => {this.setState({currentStatus:0});this.updateOnlineStatus(0)}}
      >
        Online
      </Button>
      <Button
        variant={this.state.currentStatus === 1 ? "contained" : "text"}
        onClick={() => {this.setState({currentStatus: 1});this.updateOnlineStatus(1)}}
        size="medium"
        style={{
          margin: 0,
          padding:"10px",
          borderRadius: "10rem",
          backgroundColor: this. getColorValue(1).backgroundColor,fontWeight: 500,
          fontFamily: 'Inter',
          textTransform:"none",
          color: this. getColorValue(1).color,
          flex:0.5
        }}
        data-testid="prf-invisible"
            >
          Invisible
        </Button>
        </Box>
              <Box display={"flex"} flexDirection={"column"} style={{gap:"10px",margin:"10px 20px"}}>
              <Button startIcon={<AccountCircleIcon/>}style={styles.actionButton} >
              <Box display={"flex"} flexDirection={"column"} >
              <Typography  style={{...styles.actionButtonContent,textTransform:"none"}}>{this.state.accountHolderName}</Typography>
              <Typography  style={{...styles.actionButtonContent,fontSize:"11px",color:"#676767",textTransform:"none",textAlign:"left"}}>{this.state.userType}</Typography>
              </Box>
              </Button>
              <Button  data-testid="prf-setting" startIcon={<SettingsIcon/>}  onClick={() => this.handleRedirect('settings')} style={styles.actionButton} >
              
                <Typography  style={{...styles.actionButtonContent,textTransform:"none"}}>Settings</Typography>
                </Button>
                <Button   data-testid="prf-logout" startIcon={<ExitToAppIcon/>} onClick={() => this.handleLogout('/landingpage')} style={styles.actionButton}>
               
                <Typography style={{...styles.actionButtonContent,textTransform:"none"}}>Log out</Typography>
                </Button>    
               </Box>
               </Box> 
              </Popover>
            {this.StyledMenuItemFun()}
            </Box>

          </Toolbar>

        </AppBar>
        <div>
    <Dialog
      open={this.state.isTokenExpired}
      data-testid='handleClose'
    
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Session expired"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
        Please log in again as your session has ended due to inactivity.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button data-testid='handleCloseDialog' onClick={()=>this.handleLogout('LandingPage')} color="primary" autoFocus>
         Ok
        </Button>
      </DialogActions>
    </Dialog>
  </div>
      </div>
    );
  }
}

export default ClientNavBar;

// Customizable Area End
// Customizable Area Start

const Custom_Menu_design = styled(Menu)({
  "& .menu_item_wrapper":{
    display: 'flex',
    height: 'inherit',
    cursor: 'pointer',
    flexDirection:"column",
    alignItems: 'center',
    width:"200px",
    "@media (max-width: 768px)": {
      marginTop:"9px",
      justifyContent:"space-between",
    },


    "& .menu_item":{
      marginRight: '10px',
      cursor: 'pointer',
      display: 'flex',
      color: '#4A4A4A',
      fontSize: '20px',
      fontWeight: 500,
      fontFamily: 'Inter',
      marginBottom:"30px",
      alignItems: 'center',
      marginLeft: '2rem',
      "@media (max-width: 992px)": {
        marginLeft: "unset",
      "@media (max-width: 500px)": {
      fontSize: '16px',
      },
      "@media (max-width: 428px)": {
        fontSize: '12px',
        }

       },
    },
  },

})

const CustomNavBar = styled(Box)({
  '& .clientlogo':{

    width:"229px",height:"36px",objectFit:"cover", marginRight:"22px",
    "@media (max-width: 450px)": {
      width:"165px",height:"30px"
    },
    "@media (max-width: 350px)": {
    width:"120px",height:"0px"
    },
  }
})

const styles = {
  root: {},
  sectionRight: {
    display: 'flex',
    alignItems: 'center',
    // flex: 0.75,
    justifyContent: 'end',
    gap: '20px'
  },
  title: {
    color: '#0A0A0A',
    fontFamily: 'poppins',
    fontSize: '26px',
    fontWeight: 275,
    lineHeight: 'normal',
    letterSpacing: '-1.244px',
    paddingRight: '1rem'
  },
  menuItem: {
    marginRight: '10px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    marginLeft: '2rem',
    // textAlign: 'left',
    color: '#4A4A4A',
    fontSize: '20px',
    fontWeight: 500,
    fontFamily: 'Inter'
  },
  options: {
    color: '#000',
    fontSize: '12px',
    fontWeight: 500
  },
  toolBar: {
    backgroundColor: ' #F2F5F7',
    height: '5rem',
    display: 'flex',
    justifyContent: 'space-between'
  },
  imgAvatar: {
    cursor: 'pointer',

    '&.MuiAvatar-img': {
      borderLeft: '2px solid #E6E9EA',
      lineHeight: '5rem',
      paddingRight: '1rem',
      overflow: 'visible',
      width: '45px',
      height: '45px'
    }
  },
  searchContainer: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '2rem',
    borderRadius: '20px !important'
  },
  searchInput: {
    borderRadius: '15rem',
    marginLeft: '1rem',
    width: '25rem'
  },
  imgAvatarBox: {
    display: 'flex',
    gap: '30px',
    paddingLeft: '1rem'
  },
  boxStyle: {
    display: 'flex',
    alignItems: 'center',
    height: 'inherit',
    cursor: 'pointer'
  },
  actionButton:{
    cursor:"pointer",
    justifyContent:"left",
    
  },
  actionButtonContent:{
    marginLeft:"15px",
    fontWeight: 400,
    fontFamily: 'Inter',
    fontSize:"15px",
    color:"#181818",
    
  },
};
// Customizable Area End
