import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,
  Grid,
  InputAdornment,
  TextField,
  Button
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import { eliipse } from "./assets";
export const config = require("./config");
const CustomButton = styled(Button)({
  maxWidth: "161px",
  padding: "1rem",
  borderRadius: "10px",
  marginTop: "2%",
  minWidth: 290,
  background: "#364BA0",
  color: "#FFFFFF",
  fontSize: "16px",
  fontFamily: "Rubik",
  fontWeight: 700,
  cursor: "pointer",
  "font-weight": "600",
  "&:hover": {
    width: "max-content",
    borderRadius: "10px",
    color: "#364BA0",
    border: "1px solid #364BA0",
    backgroundColor: "#fff"
  }
});
const CustomTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    borderRadius:"10px",
    borderColor:"#E6E6E6",
  },
})

const CustomBudgetGrid = styled(Grid)({
  display: "flex",
  justifyContent: "center",
  width: "90%",
  marginTop: "40px",
  marginLeft: "80px",
  marginBottom: "100px",
  padding: "19px",
"@media (max-width: 960px)": {
  display: "flex",
  justifyContent: "center",
  margin:"auto",
  width:"100%",
  padding: "19px"
},
})
// Customizable Area End

import BudgetRangeController, { Props } from "./BudgetRangeController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#206FC4"
    }
  },
  typography: {
    fontFamily: "Inter"
  }
});

export default class BudgetRange extends BudgetRangeController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <>
      <ThemeProvider theme={theme}>
        <Box>
          <CustomBudgetGrid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Box>
                <Typography style={styles.titleStyles as React.CSSProperties}>{config.tellUs}</Typography>
                <Typography style={{...styles.titleStyles,fontSize:"14px",color:"#5C5C5C",fontWeight:400}}>{config.helpUs}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6} style={{display:"flex",justifyContent:"center",flexDirection:"column"}}>
                  <label style={styles.labelText}>
                    Project Budget:
                  </label>
                    <CustomTextField
                      onKeyDown={this.handleKeyDown}
                      variant="outlined"
                      data-testid="txtInputBudget"
                      style={styles.input}
                      id="outlined-adornment-amount"
                      value={this.state.inputValue}
                      onChange={this.handleInputChange}
                      placeholder="0"
                      inputProps={{ style: { textAlign: "start" } }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        )
                      }}
                      error={this.state.error}
                      helperText={this.state.error ? '* Please fill this field' : ''}
                    />
            </Grid>
          </CustomBudgetGrid>
        </Box>
        <img src={eliipse} alt="ellipse" style={styles.ellipseImg} />
      </ThemeProvider>
        <Box style={styles.lowerSectionDiv as React.CSSProperties}>
          <Box style={styles.lowerSectionDiv2}>
            <Typography
              component="button"
              data-testid="backBtn"
              style={{
                ...styles.backBtn,
                ...(this.state.isHovered && styles.lowerSectionItem1TextHovered)
              }}
              onClick={() => this.handleBudgetBack()}
              onMouseEnter={this.handleHover}
              onMouseLeave={this.handleLeave}
            >
              {config.back}
            </Typography>
            <CustomButton
              data-testid="nextBtn"
              onClick={() => this.handleNext()}
            >
              CONTINUE
            </CustomButton>
          </Box>
        </Box>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const styles = {
  invento: {
    padding: "20px 0 0 30px",
    fontSize: "2rem",
    fontWieght: "600"
  },
  averageStyle: {
    fontFamily: "Rubik" as "Rubik",
    color: "#FF7979",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "19.36px",
    textAlign: "left",
    margin: "8px"
  },
  titleStyles: {
    fontSize: "38px",
    fontWeight: 500,
    lineHeight: 2,
    fontFamily: "Rubik"
  },
  ellipseImg: {
    position: "absolute" as "absolute",
    bottom: "0",
    width: "340px",
    left: "0",
    zIndex: 0
  },
  button: {
    width: "max-content",
    padding: "1% 10%",
    borderRadius: "10px",
    marginTop: "2%",
    minWidth: 290
  },
  radioInput: {
    position: "absolute" as "absolute",
    right: "10",
    top: "8",
    color: green[500]
  },
  gridContainer: {
    margin:"93px 218px 118px",
    width: "auto"
  },

  selectBox: {
    marginTop: "2%",
    textAlign: "center" as "center"
  },
  createPostBtn: {
    borderRadius: "20px"
  },
  postBox: {
    display: "flex",
    justifyContent: "center",
    gap: "20px",
    alignItems: "center"
  },
  getStarted: {
    marginTop: "2%"
  },
  labelText: {
    fontFamily: "Rubik",
    fontSize: "19px",
    display:"flex",
    marginBottom:"10px",
    fontWeight:400
  },
  input: {
    width: "88%"
  },
  buttonBox: {
    alignItems: "center",
    justifyContent: "space-between",
    display: "flex",
    padding: "4%",
    position: "fixed" as "fixed",
    bottom: 0,
    width: "100%",
    zIndex: 1000
  },
  nextBtn: {
    textAlign: "end" as "end"
  },
  btnStyle: {
    width: "max-content",
    padding: "1rem",
    borderRadius: "10px",
    marginTop: "2%",
    minWidth: 290,
    background: "#364BA0",
    color: "#FFFFFF",
    fontSize: "16px",
    fontFamily: "Rubik",
    fontWeight: 700,
    cursor: "pointer",
    "font-weight": "600"
  },
  backBtn: {
    border: "none",
    background: "none",
    fontFamily: "Rubik" as "Rubik",
    fontSize: "16px",
    color: "grey",
    lineHeight: "27px",
    cursor: "pointer" as "pointer",
    fontWeight: "bold" as "bold",
    position: "relative" as "relative",
    zIndex: 1
  },
  lowerSectionDiv: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    position: "absolute",
    bottom: 20,
    left: 0,
    width: "100%"
  },
  lowerSectionDiv2: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingInline: 20,
    width: "100%"
  },
  lowerSectionItem1TextHovered: {
    color: "black",
    position: "relative" as "relative",
    zIndex: 1
  },
  cardContainer: {
    marginRight: "12px",
    padding: "15% 0 0",
    position: "relative" as "relative",
    borderRadius: "5px",
    cursor: "pointer",
    textAlign: "start" as "start",
    maxWidth: "100%"
  },
  afterSelect: {
    color: "#fff",
    fontWeight: 600
  },
  beforeSelect: {
    color: "#000",
    fontWeight: 600
  },
  budgetSelect: {
    color: "#fff",
    fontSize: "0.7rem"
  },
  budgetUnselect: {
    color: "#000",
    fontSize: "0.7rem"
  }
};
// Customizable Area End
