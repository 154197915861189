import React from 'react';
// Customizable Area Start
import { Props } from './ViewJobController.web';
import FreelancerNavBar from './FreelancerNavBar.web';
import FooterWeb from './Components/Footer.web';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Pagination from '@material-ui/lab/Pagination';
import CheckIcon from '@material-ui/icons/Check';
import CircularLoader from '../../../components/src/CircularLoader.web';
import {
  Box,
  Chip,
  LinearProgress,
  InputAdornment,
  TextField,
  Typography,
  Button,
  withStyles,
  Avatar,
  Dialog,
  DialogContent,
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
  Divider,
  Menu,
  MenuItem,
  Checkbox,
  FormControl,
  styled,
  createStyles,
  Theme} from '@material-ui/core';
import {
  calendar,
  dot,
  jobProfileAvatar,
  searchJob1,
  verified
} from './assets';
import SearchJobController from './SearchJobController.web';
import SearchIcon from '@material-ui/icons/Search';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Rating from '@material-ui/lab/Rating';
import Slider from '@material-ui/core/Slider';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';

const AutoCompleteSvgIcon = (
  <svg
    width="15"
    height="8"
    viewBox="0 0 15 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
   >
    <path
      id="Path"
      d="M14.56 0.430739C14.42 0.294665 14.26 0.186001 14.07 0.11258C13.89 0.0381791 13.7 0 13.5 0C13.3 0 13.11 0.0381791 12.93 0.11258C12.74 0.186001 12.58 0.294665 12.44 0.430739L7.85 4.91924C7.76 5.01126 7.63 5.06216 7.5 5.06216C7.37 5.06216 7.24 5.01126 7.15 4.91924L2.56 0.430739C2.28 0.155653 1.9 0 1.5 0C1.1 0 0.72 0.154675 0.44 0.42976C0.16 0.705825 0 1.07881 0 1.46843C0 1.85805 0.16 2.23201 0.44 2.5071L5.03 6.99657C5.35 7.31473 5.74 7.5673 6.16 7.7396C6.59 7.91189 7.04 8 7.5 8C7.96 8 8.42 7.91189 8.84 7.7396C9.26 7.5673 9.65 7.31473 9.98 6.99657L14.56 2.5071C14.84 2.23201 15 1.85805 15 1.46843C15 1.07978 14.84 0.705825 14.56 0.430739Z"
      fill="white"
    />
  </svg>
);

// Customizable Area End
export default class SearchJob extends SearchJobController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }
  RadioLabel = (labelText: any) => {
    return (
      <Typography style={{ fontWeight: 600, color: '#000' }}>
        {labelText}
      </Typography>
    );
  };
  profileSections = [
    {
      title: 'Employment history',
      content:
        'Freelancers with complete, quality profiles are 4.5 times more likely to get hired by clients. (30%)'
    },
    {
      title: 'Education',
      content:
        'Freelancers with complete, quality profiles are 4.5 times more likely to get hired by clients. (20%)'
    },
    {
      title: 'Video Introduction',
      content:
        'Freelancers with complete, quality profiles are 4.5 times more likely to get hired by clients. (20%)'
    },
    {
      title: 'Skills',
      content:
        'Freelancers with complete, quality profiles are 4.5 times more likely to get hired by clients. (10%)'
    },
    {
      title: 'Other Experience',
      content:
        'Freelancers with complete, quality profiles are 4.5 times more likely to get hired by clients. (10%)'
    }
  ];

  simplifiedJSX = this.profileSections.map((section, index) => (
    <div key={index}>
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <RadioGroup style={webStyle.RadioGroupBox}>
          <FormControlLabel
            value={section.title}
            control={
              <Radio
                style={{
                  ...webStyle.dialogtext,
                  fontSize: '18px',
                  color: '#979797'
                }}
              />
            }
            label={this.RadioLabel(section.title)}
          />
        </RadioGroup>
        <ChevronRightIcon />
      </Box>
      <Typography style={{ ...webStyle.dialogtext, marginLeft: '40px' }}>
        {section.content}
      </Typography>
      <Divider />
    </div>
  ));

  renderButtons = (item: any) => {
    return(
      <RenderButtonBox className='renderButtonBox'>
      <Button
        className='saveLaterbtn'
        style={{ cursor: 'pointer' , 
                 border: '1px solid #364BA0', 
                 color:'#364BA0',
                 padding: '8px 16px',
                marginRight: '10px',
                borderRadius: '12px',
                fontWeight: 700,
                fontSize: '16px'
                }}
                data-test-d="favourites-job"
                onClick={() => {
                  const jobId = item.attributes.id;
                  if (!this.state.favouritesJobIds.includes(jobId)) {
                    this.handleSaveforLaterJobs(jobId); 
                    this.setState({ jobSavePopup: true }); 
                  }
                }}
      >
        <FavoriteBorderIcon
          style={{  margin: '0px 5px'}}
          data-testid={`favorite-${item.attributes.id}`}
        />  {this.state.favouritesJobIds.includes(
          item.attributes.id
        )
          ? 'SAVED'
          : 'SAVE FOR LATER'}
      </Button>
  
        <Button
          variant="contained"
          style={{ backgroundColor: '#364BA0', color: 'FFF' ,
                fontSize: '16px',
            borderRadius: '12px', fontWeight: 700,padding: '8px 25px',
          }}
          data-testid='sendProposalButton'
          onClick={() => this.handleSendProposalClick(item.attributes.id)}
        >
          send proposal
        </Button>
  
      </RenderButtonBox>
    )
  }

  render() {
    // Customizable Area Start   
    const ToggleIcon = ({ condition }:any) => {
      return condition ? 
      <KeyboardArrowUpIcon data-test-id="leftIcon" style={{ marginLeft: '50px' }} /> :
        <ChevronRightIcon data-test-id="rightIcon" style={{ marginLeft: '50px' }} /> 
        ;
    }

    const isFilterSelected = (filterValue:any) => {
      return this.state.selectedFilters.proposal?.includes(filterValue) || false;
    };
    const isBudgetSelected = (budgetValue:any) => {
      return this.state.selectedFilters.budget?.includes(budgetValue) || false;
    };
    const isDurationSelected = (durationValue:any) => {
      return this.state.selectedFilters.duration?.includes(durationValue) || false;
    };
    
    return (
      //Merge Engine DefaultContainerj
      <>
        <FreelancerNavBar navigation={this.props.navigation} />
        <CustomBox          
        >
          <Box >
          
            <CustomLeftSide>
              <Typography
                style={{
                  ...webStyle.text,
                  marginTop: '54px',
                  fontSize: '36px',
                  fontWeight: 700,
                  fontFamily: 'Rubik',
                  color:'#4A4A4A'
                }}
              >
                Search Job
              </Typography>

              <Box
                display={'grid'}
                gridTemplateColumns={'2fr 0.2fr'}
                gridAutoFlow={'column'}
                gridColumnGap={'0.5rem'}
              >
                <CustomTextField
                  data-testid="search"
                  placeholder="Search"
                  inputProps={{
                    style: {
                      ...webStyle.outlinedInput,
                      width: '100%'
                    }
                  }}
                  InputProps={{
                    style: {
                      borderRadius: '13px',
                      width: '100%',
                      flex: 1
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    )
                  }}
                  variant="outlined"
                  value={this.state.search}
                  name="search"
                  onChange={this.handleChange}
                />
        <Button
          data-testid="Most Recent"
          aria-controls="customized-menu"
          aria-haspopup="true"
          variant="contained"
          color="primary"
          endIcon={AutoCompleteSvgIcon}
          style={{
            borderRadius: '13px',
            width: '170px',
            backgroundColor: '#364BA0',
            color: '#FFF',
            fontSize: '16px',
            fontFamily: 'Rubik',
            fontWeight: 700,
            textTransform: 'none' 
          }}
          onClick={this.handleClick}
        >
          {this.state.selectedOption}
        </Button>
        <Paper >
        <Menu
          id="customized-menu"
          anchorEl={this.state.anchorEl}
          keepMounted
          open={Boolean(this.state.anchorEl)}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        >
          {['Most Recent','Older'].filter((option:string) => option != this.state.selectedOption).map((item:string) => 
             <MenuItem style={{minWidth: 200}} onClick={() => this.handleCloseMenu(item)}>{item}</MenuItem>
            )}        
        </Menu>
        </Paper>
        </Box>
        <Box
          display={'grid'}
          gridAutoFlow={'row'}
          gridRowGap={'3rem'}
          className="jobContentMainBox"
        >
        {this.state.clientJobPostData?.length > 0 ? this.state.clientJobPostData?.map((item) => (
      <Box
        style={{
          borderRadius: '12px',
          backgroundColor: '#E6EBEF',
          padding: '18px',
        }}
        className='jobContentBox'
      >
        <Box className="mainContentBox">
          <Box style={{ marginLeft: '5px', width: '60%' }}>
            <Typography style={{ ...webStyle.text, fontSize: '20px', fontWeight: 700 }}>
              {item.attributes.name}
            </Typography>
            <Box display={'flex'} alignItems={'center'}>
              <LocationOnIcon style={{ marginLeft: '-5px', marginRight: '3px' }} />
              <Typography style={{ ...webStyle.text, marginTop: '10px', display: 'contents' }}>
                {item.attributes.location}
              </Typography>
              <Rating
                name="simple-controlled"
                value={3}
                disabled
                style={{
                  padding: '8px 0px',
                  width: '90px',
                  marginLeft: '7px',
                  marginRight: '15px'
                }}
              />
              <Typography style={{ ...webStyle.text, marginLeft: '30px' }}>
                3/5
              </Typography>
            </Box>
            <Box display={'flex'} style={{ alignItems: 'center', marginTop: '12px' }}>
              <Typography style={{ fontSize: '14px', fontFamily: 'Rubik', marginRight: '5px' }}>
                Est. Time:{' '}
              </Typography>
              <Typography style={{ fontSize: '14px', fontFamily: 'Rubik' }}>
                {item.attributes.project_length}
              </Typography>
            </Box>
            <Box style={{ marginTop: '10px', marginBottom: '24px' }}>
              <Typography style={{ fontSize: '14px', fontFamily: 'Rubik', color: '#6b6969' }}>
                {item.attributes.description}
              </Typography>
            </Box>
            <Box >
              {item.attributes.skills.map((skill: any) => (
                <Chip
                  label={skill}
                  style={webStyle.chiplabel}
                />
              ))}
            </Box>
          </Box>
          <Box>
            <Box className='projectBudget'>
              <Typography style={{ fontSize: '16px', fontFamily: 'Rubik', whiteSpace: 'nowrap', margin: '0px 5px' }}>
                Project Budget: {' '}
              </Typography>
              <Typography style={{ fontSize: '16px', fontFamily: 'Rubik' }}>
                ${item?.attributes?.per_day_charge}
              </Typography>
            </Box>
            <Box  className='projectProposal'>
              <Typography
                style={{
                  fontSize: '14px',
                  fontFamily: 'Rubik',
                  whiteSpace: 'nowrap',
                  margin: '0px 5px',
                  color: '#6b6969'
                }}
              >
                Proposals: {' '}
              </Typography>
              <Typography style={{ fontSize: '16px', fontFamily: 'Rubik', color: '#6b6969' }}>
                {item?.attributes?.proposals_count}
              </Typography>
            </Box>
          </Box>
        </Box>
        <ProposalBox className="proposalButton">
          <Box style={{alignContent: 'end',marginLeft: '5px'}} className="proposalDate">
            <Typography
              style={{
                ...webStyle.text,
                fontWeight: 400,
                fontFamily: 'Rubik',
                alignContent: 'end'
              }}
            >
              {item.attributes.created_at}
            </Typography>
          </Box>

          {this.renderButtons(item)}
        </ProposalBox>
      </Box>
  ))
  : <div>
    {this.state.loading ?
    <CircularLoader loading={this.state.loading} /> :
      <Typography 
        style={{
          textAlign: 'center',
          fontSize: '20px',
          marginTop: '77px',
          fontWeight: 700,
          color: 'gray'
        }}
      >
        No data available
      </Typography>
    }
  </div>
  
}
</Box>
     <Box display={"flex"} justifyContent={"center"} style={{marginTop:'40px'}}>
      {this.state.clientJobPostData?.length > 0 &&
        <Pagination count={this.state.totalPages} 
        page={this.state.currentPage} 
        onChange={this.handlePageChange}
        />
      }
      </Box>
            </CustomLeftSide>
          </Box>
          <CustomRightSide>
            <Box
            className='customCard'
            style={{
              marginTop: '52px',
              borderRadius: '12px',
              padding: '24px',
              marginBottom: '40px',
            }}
            >
                   <Box>
                    <Avatar style={{margin:'0px auto 12px', width: '72px', height: '72px'}} src= {this.state.clientDetails?.current_user?.profile_image?.url} alt="profile"/>
                          <Typography
                            style={{
                              ...webStyle.dialogtext,
                              fontSize: '24px',
                              fontWeight: 700,
                              color: '#364BA0',
                              fontFamily: 'Rubik',
                              textAlign: 'center'
                            }}
                          >
                            {this.state.clientDetails?.current_user?.first_name}  {this.state.clientDetails?.current_user?.last_name}
                          </Typography>
                          <Typography style={{...webStyle.dialogtext, textAlign: 'center'}}>
                          {this.state.clientDetails?.current_user?.job_title}
                          </Typography>
                        </Box>
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: '34px',
                }}
              >

                <Typography
                  style={{
                    ...webStyle.text,
                    marginRight: '55px',
                    fontSize: '18px',
                    fontWeight: 700,
                    color:'#494949',
                    fontFamily: 'Rubik',
                    textDecoration: 'underline'
                  }}
                >
                  Profile Completeness
                </Typography>
                <ChevronRightIcon
                  style={{ cursor: 'pointer' }}
                  onClick={() => this.handleClickOpen()}
                  data-testid="profile-completionStatus-btn"
                />
              </Box>
              
              <Box
                display={'grid'}
                gridAutoFlow={'row'}
                gridRowGap={'2rem'}
                style={{ placeItems: 'end' }}
              >
              <BorderLinearProgress variant="determinate" data-testid={""} value={this.state.clientDetails?.current_user?.completion_percentage} />
                <Typography
                  style={{
                    fontWeight: 700,
                    fontFamily: "Rubik",
                    fontSize: 20,
                    marginTop: '-27px'
                  }}
                >
                  {this.state.clientDetails?.current_user?.completion_percentage} %
                </Typography>
              </Box>
            </Box>
            <Box
              style={{
                borderRadius: '12px',
                padding: '30px',
              }}
              className='customCard'
            >
              <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography
                  style={{
                    ...webStyle.text,
                    marginRight: '117px',
                    fontSize: '18px',
                    fontWeight: 700,
                    color: 'rgb(107, 105, 105)',
                    fontFamily : 'Rubik'
                  }}
                >
                  Filter By
                </Typography>
                <CustomLabel 
                style={{ ...webStyle.text, fontSize: '15px', cursor: 'pointer' }} 
                onClick={this.handleClearFilter}
              >
                Clear All
              </CustomLabel>
              </Box>
            </Box>

            {/* Scope of work */}
            <Box style={{
               borderRadius: '12px',
               padding: '30px',
               marginTop: '34px',
            }}
            className='customCard'
            >
              <Box display={'flex'} justifyContent={'space-between'} onClick={this.handleScopeOfWork}>
                <Typography style={{
                    ...webStyle.text,
                    color: 'rgb(107, 105, 105)',
                    fontSize: '18px',
                    fontWeight: 700,
                    fontFamily : 'Rubik'
                  }}>
                  Scope of work
                </Typography>
                <ToggleIcon condition={this.state.toggelScopeOfWork} />
              </Box>
             {this.state.toggelScopeOfWork && 
             <Box style={{marginTop: '27px'}}>
            <FormControl>
                    <RadioGroup
                      style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}
                    >
                      <FormControlLabel style={webStyle.gapBox}
                        value="1 to 3 months"
                        control={
                          <CustomCheckbox icon={<CheckIcon style={{ visibility: 'hidden' }} />}
                          checkedIcon={<CheckIcon />}
                          checked={isDurationSelected('1 to 3 months')} 
                            onChange={() => this.handleCheckboxChange('duration', '1 to 3 months')}
                          />
                        }
                        label={<CustomLabel>1 to 3 months</CustomLabel>}
                      />
                      <FormControlLabel style={webStyle.gapBox}
                        value="3 to 6 months"
                        control={
                          <CustomCheckbox icon={<CheckIcon style={{ visibility: 'hidden' }} />}
                          checkedIcon={<CheckIcon />}
                          checked={isDurationSelected('3 to 6 months')} 
                            onChange={() => this.handleCheckboxChange('duration', '3 to 6 months')}
                          />
                        }
                        label={<CustomLabel>3 to 6 months</CustomLabel>}
                      />
                      <FormControlLabel style={webStyle.gapBox}
                        value="6 to 12 months"
                        control={
                          <CustomCheckbox  icon={<CheckIcon style={{ visibility: 'hidden' }} />}
                          checkedIcon={<CheckIcon />}
                          checked={isDurationSelected('6 to 12 months')} 
                            onChange={() => this.handleCheckboxChange('duration', '6 to 12 months')}
                          />
                        }
                        label={<CustomLabel>6 to 12 months</CustomLabel>}
                      />
                      <FormControlLabel style={webStyle.gapBox}
                        value="more than 12 months"
                        control={
                          <CustomCheckbox  icon={<CheckIcon style={{ visibility: 'hidden' }} />}
                          checkedIcon={<CheckIcon />}
                          checked={isDurationSelected('more than 12 months')} 
                            onChange={() => this.handleCheckboxChange('duration', 'more than 12 months')}
                          />
                        }
                        label={<CustomLabel>more than 12 months</CustomLabel>}
                      />
                    </RadioGroup>
                  </FormControl>

           </Box>
             } 
            </Box>

             {/* Project Budget */}
            <Box onClick={this.handleProjectBudget} style={{
                borderRadius: '12px',
                padding: '30px',
                marginTop: '34px',
              }}
              className='customCard'
            >
              <Box display={'flex'} justifyContent={'space-between'}>
              <Typography style={{
                    ...webStyle.text,
                    color: 'rgb(107, 105, 105)',
                    fontSize: '18px',
                    fontWeight: 700,
                    fontFamily : 'Rubik'
                  }}>
                  Project Budget
                </Typography>
                <ToggleIcon condition={this.state.toggleProjectBudget} />
              </Box>
              {this.state.toggleProjectBudget &&
                   <Box style={{marginTop: '24px'}}>
                   <FormControl>
                  <Box
                    style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}
                  >
                    <FormControlLabel style={webStyle.gapBox}
                    data-test-id="budget-change"
                      control={<CustomCheckbox icon={<CheckIcon style={{ visibility: 'hidden' }} />}
                      checkedIcon={<CheckIcon />} 
                      checked={isBudgetSelected('0-10')} 
                        onChange={() => this.handleCheckboxChange('budget', '0-10')}
                      />}
                      label={<CustomLabel>$0 - $10</CustomLabel>}
                    />
                    <FormControlLabel style={webStyle.gapBox}
                      control={<CustomCheckbox icon={<CheckIcon style={{ visibility: 'hidden' }} />}
                      checkedIcon={<CheckIcon />} 
                      checked={isBudgetSelected('10-20')} 
                        onChange={() => this.handleCheckboxChange('budget', '10-20')}
                      />}
                      label={<CustomLabel>$10 - $20</CustomLabel>}
                    />
                    <FormControlLabel style={webStyle.gapBox}
                      control={<CustomCheckbox icon={<CheckIcon style={{ visibility: 'hidden' }} />}
                      checkedIcon={<CheckIcon />} 
                      checked={isBudgetSelected('20-30')} 
                        onChange={() => this.handleCheckboxChange('budget', '20-30')}
                      />}
                      label={<CustomLabel>$20 - $30</CustomLabel>}
                    />
                    <FormControlLabel style={webStyle.gapBox}
                    
                      control={<CustomCheckbox icon={<CheckIcon style={{ visibility: 'hidden' }} />}
                      checkedIcon={<CheckIcon />}
                      checked={isBudgetSelected('30-2000')} 
                        onChange={() => this.handleCheckboxChange('budget', '30-2000')}
                      />}
                      label={<CustomLabel>$30+</CustomLabel>}
                    />
     

                  </Box>
                   </FormControl>
                 </Box>
               }
         
            </Box>

            {/* Number of Proposals */}
            <Box
             className='customCard'
              style={{
                borderRadius: '12px',
                padding: '30px',
                marginTop: '34px',
              }}
            >
              <Box style={{ display: 'flex', justifyContent: 'space-between' }}
              onClick={this.handleProposals}>
                <Typography
                  style={{
                    ...webStyle.text,
                    color: 'rgb(107, 105, 105)',
                    fontSize: '18px',
                    fontWeight: 700,
                    fontFamily : 'Rubik'
                  }}
                >
                  Number of Proposals
                </Typography>
    
                <ToggleIcon condition={this.state.toggleProposals} />
              </Box>
              {this.state.toggleProposals &&
              <Box style={{marginTop: '27px'}}>
               <FormControl>
                  <RadioGroup
                    style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}
                  >
                    <FormControlLabel style={webStyle.gapBox} value={"true"} control={
                        <CustomCheckbox
                        checked={isFilterSelected('0-5')}
                        onChange={() => this.handleCheckboxChange('proposal', '0-5')}
                        icon={<CheckIcon style={{ visibility: 'hidden' }} />}
                        checkedIcon={<CheckIcon />}
                      />} label={<CustomLabel>Less than 5</CustomLabel>} />
                    <FormControlLabel style={webStyle.gapBox} value={"true"} control={<CustomCheckbox icon={<CheckIcon style={{ visibility: 'hidden' }} />}
                        checkedIcon={<CheckIcon />} 
                        checked={isFilterSelected('5-10')}
                    onChange={() => this.handleCheckboxChange('proposal', '5-10')}/>} label={<CustomLabel>5 to 10</CustomLabel>} />
                    <FormControlLabel style={webStyle.gapBox} value={"true"} control={<CustomCheckbox icon={<CheckIcon style={{ visibility: 'hidden' }} />}
                        checkedIcon={<CheckIcon />} 
                        checked={isFilterSelected('10-20')}
                    onChange={() => this.handleCheckboxChange('proposal', '10-20')} />} label={<CustomLabel>10 to 20</CustomLabel>} />
                    <FormControlLabel style={webStyle.gapBox} value={"true"} control={<CustomCheckbox icon={<CheckIcon style={{ visibility: 'hidden' }} />}
                        checkedIcon={<CheckIcon />} 
                        checked={isFilterSelected('20-30')}
                    onChange={() => this.handleCheckboxChange('proposal', '20-30')}/>} label={<CustomLabel>20 to 30</CustomLabel>} />
                    <FormControlLabel style={webStyle.gapBox} value={"true"} control={<CustomCheckbox   icon={<CheckIcon style={{ visibility: 'hidden' }} />}
                        checkedIcon={<CheckIcon />} 
                        checked={isFilterSelected('30-40')}
                    onChange={() => this.handleCheckboxChange('proposal', '30-40')}/>} label={<CustomLabel>30 to 40</CustomLabel>} />
                    <FormControlLabel  style={webStyle.gapBox} value={"true"} control={<CustomCheckbox icon={<CheckIcon style={{ visibility: 'hidden' }} />}
                        checkedIcon={<CheckIcon />} 
                        checked={isFilterSelected('40-50')}
                    onChange={() => this.handleCheckboxChange('proposal', '40-50')} />} label={<CustomLabel>40 to 50</CustomLabel>} />
                    <FormControlLabel style={webStyle.gapBox} value={"true"} control={<CustomCheckbox icon={<CheckIcon style={{ visibility: 'hidden' }} />}
                        checkedIcon={<CheckIcon />} 
                        checked={isFilterSelected('50-5000')}
                    onChange={() => this.handleCheckboxChange('proposal', '50-5000')} />} label={<CustomLabel>50+</CustomLabel>} />

                  </RadioGroup>
                </FormControl>
              </Box>
              }
            </Box>

            {/* Country */}
            <Box className='customCard'
            style={{
              borderRadius: '12px',
              padding: '30px',
              marginTop: '34px',
            }}
            >
              <Box display={'flex'} justifyContent={'space-between'} 
                  onClick={this.handleOtherLanguage}
                  >
              <Typography
                  style={{
                    ...webStyle.text,
                    color: 'rgb(107, 105, 105)',
                    fontSize: '18px',
                    fontWeight: 700,
                    fontFamily : 'Rubik'
                  }}
                >
                  Country of Expertise
                </Typography>
                <ToggleIcon condition={this.state.toggleOtherLanguage} />
              </Box>
              {this.state.toggleOtherLanguage && 
              <Box style={{ maxHeight: '200px', overflowY: 'auto', marginTop: '27px'}}>
                <FormControl>
                  <RadioGroup
                    style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}
                  >
                  {this.state.countryList?.map((item, index) => (
                      <FormControlLabel  style={{...webStyle.gapBox, marginLeft: '0px'}}
                        key={index} 
                        value={item} 
                        control={
                          <CustomCheckbox icon={<CheckIcon style={{ visibility: 'hidden' }} />}
                          checkedIcon={<CheckIcon />}
                            checked={this.state.selectedFilters.country.includes(item)} 
                            onChange={() => this.handleCheckboxChange('country', item)} 
                          />
                        } 
                        label={<CustomLabel>{item}</CustomLabel>} 
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </Box>
              }
            </Box>

          </CustomRightSide></CustomBox>
                    <FooterWeb />
{this.state.jobSavePopup && <Dialog  PaperProps={{style: { borderRadius: '24px' },}} open={this.state.jobSavePopup} onClose={this.handleSavePopup}>
       <div style={{ backgroundColor: 'white', padding: 30, }}>
    <DialogContent>
          <Typography 
          style={{...webStyle.modalText, marginBottom:'10px' }}>
            Congratulations!!
            </Typography>
          <Typography style={webStyle.modalText}>
            You have successfully saved the job for later.
            </Typography></DialogContent>
        <Button 
        onClick={this.handleSavePopup} 
        style={{
          display: 'flex',
            color: 'white',
            width: '50%',
            marginTop: '40px',
            borderRadius: '12px',
            fontSize: '16px',
            fontWeight: 700,
            margin: 'auto',
            marginBottom: '30px',
            background:'#364BA0'}}>OK</Button>
      </div></Dialog>
      }
      </>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  gapBox: {
    gap: '12px',
    marginBottom: '12px',
    marginLeft: '0px'
  },
  chiplabel: {
    fontWeight: 700,
    marginRight: '10px',
    color: '#364BA0',
    backgroundColor: '#E4F5FF',
    fontSize: '16px', 
    marginBottom:'5px',
    padding: '12px 15px',
    '& .MuiChip-label': {
      textTransform: 'uppercase',
    fontWeight: 700,
    fontSize: '16px', 
    }
  },
  text: {
    color: '#0A0A0A',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontSize: '14px',
    fontWeight: 300
  },
  rating: {},
  modalText: {
    fontFamily: 'Inter',
    fontWeight: 500,
    color: '#515354',
    fontSize: '18px',
    "@media(max-width:800px)": {
      background: 'red'
      },
  },
  proposalNumber: {
    display: 'flex',
    alignItems: 'center',
    color: 'rgb(107,105,105'
  },
  description: {
    color: '#0A0A0A',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontSize: '18px',
    fontWeight: 400,
    marginBottom: '2rem',
    opacity: 0.6
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column'
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '2rem'
  },
  infoCard: {
    borderRadius: '12px',
    background: '#206FC4',
    color: '#fff',
    height: '434px',
    flex: 0.25
  },
  avatar: {
    background: '#FFF',
    width: '100px',
    height: '100px',
    marginBottom: '1.5rem',
    color: '#0A0A0A',

    fontSize: '28px',

    fontWeight: 100
  },
  GridColumn: {
    display: 'grid',
    gridAutoFlow: 'column',
    marginBottom: '2rem'
  },
  dialogtext: {
    color: '#2B2B2B',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 400
  },
  RadioGroupBox: {
    display: 'grid',
    gridColumnGap: '30px', // Column gap of 30px
    width: '100%', // Take up all available width,
    marginLeft: '10px'
  },
  inputField: {
    maxHeight: '56px',
    borderRadius: '4rem',
    marginTop: '5px',

    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: '10px'
    },
    '& .MuiOutlinedInput-input': {
      width: '432px'
    }
  },
  outlinedInput: {
    borderRadius: '10px',
    '&::placeholder': {
      color: '#000000',
      fontSize: '18px',
      lineHeight: '30px'
    }
  }
};

const CustomCheckbox = styled(Checkbox)(({ theme }) => ({
  width: 5,
  height: 7,
  borderRadius: 6,
  backgroundColor: 'rgb(197, 192, 192)', 
  '&.Mui-checked': {
    color: '#ffffff', 
    backgroundColor: 'rgb(54, 75, 160)',
  },
  '&:hover': {
    backgroundColor: 'inherit', 
  },
  '& .MuiSvgIcon-root': {
    fontSize: 23,
  },
}));

export const PrettoSlider = withStyles({
  root: {
    color: '#206FC4',
    height: 8
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit'
    }
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)'
  },
  track: {
    height: 8,
    borderRadius: 4
  },
  rail: {
    height: 8,
    borderRadius: 4
  }
})(Slider);

export const BorderLinearProgress = withStyles((theme: Theme) =>
  createStyles({
      root: {
          height: 10,
          borderRadius: 5,
          width: '100%',
          marginTop: '3%'
      },
      colorPrimary: {
          backgroundColor: '#c1c1c1'
      },
      bar: {
          borderRadius: 5,
          backgroundColor: "#364BA0"
      },

  })
)(LinearProgress);

const CustomLeftSide = styled(Box)({
  display: 'grid',
  gridAutoFlow:'row',
  gridRowGap:'2rem',
  marginLeft:'96px',
  // marginRight:'32px',
  "@media (max-width: 1000px)": {
    marginLeft:'50px',
    // marginRight:'15px',
  },
  "@media (max-width: 950px)": {
    marginLeft:'20px',
  },
  "& .jobContentBox": {
    width:'100%',
    "@media (max-width: 870px)": {
      width:'auto',
    },
  },
  "& .jobContentMainBox" : {
    marginRight: '32px',
    "@media (max-width: 1000px)": {
      marginRight:'15px',
    },
  },
  "& .mainContentBox": {
    display:'flex',
    justifyContent: 'space-between',
    "@media (max-width: 870px)": {
      display:'block',
    },
  },
  "& .projectBudget": {
    display:'flex',
    "@media (max-width: 870px)": {
      marginTop:'12px',
    },
  },
  "& .projectProposal": {
    display: 'flex',
    marginTop: '10px', 
    justifyContent: 'end' ,
    "@media (max-width: 870px)": {
      justifyContent: 'start' ,
    },
  }
  
});

const CustomRightSide = styled(Box)({
  marginRight: '100px',
  "@media (max-width: 1100px)": {
    marginRight:'50px',
  },
  "@media (max-width: 950px)": {
    marginRight:'20px',
  },
  "& .customCard": {
    width: '342px',
    backgroundColor: '#E6EBEF',
    "@media (max-width: 1000px)": {
      width: '270',
    },
    "@media (max-width: 870px)": {
      width: 'auto',
      marginLeft: '20px'
    },
  },

});

const ProposalBox = styled(Box)({
  display:'flex',
  marginTop:'10px',
  justifyContent:'space-between',
  "@media (max-width: 600px)": {
  display: 'block'
  },
  "& .proposalDate": {
    alignContent: 'end', 
    marginLeft: '12px' ,
    display: 'flex',
    "@media (max-width: 870px)": {
      marginBottom: '12px'
      },
  }
});

const RenderButtonBox = styled(Box)({
  display: 'flex',
  "@media (max-width: 400px)": {
    margin: '10px',
    display: 'grid',
  },
  "& .saveLaterbtn": {
    "@media (max-width: 400px)": {
      marginTop: '10px',
      marginBottom: '10px',
    },
  },
});

const CustomBox = styled(Box)({
  display: 'grid',
  gridAutoFlow:'column',
  gridColumnGap:'3rem',
  gridTemplateColumns: '1fr 0.3fr',
  "@media (max-width: 870px)": {
    display: 'block',
  },
});

const CustomLabel = styled('span')(({ theme }) => ({
  fontSize: '16px', 
  color: 'rgb(107, 105, 105)', 
  fontFamily: 'Regular, Rubik'
}));

const CustomTextField = styled(TextField)({
  '& .MuiInputBase-input::placeholder': {
    color: 'rgb(107, 105, 105)',
    fontSize: '18px',
    fontFamily: 'Rubik',
    fontWeight: 700
  },
});

// Customizable Area End